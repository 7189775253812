<template>
  <modal-info
      :modelValue="modelValue"
      @update:modelValue="modelValue = $event"
      :modal-params="{
        active: $root.globalAlertData.modalAlertMeasureLeave.active,
        isBlackColor: $root.globalAlertData.modalAlertMeasureLeave.isBlackColor,
        buttonsReverse: $root.globalAlertData.modalAlertMeasureLeave.buttonsReverse,
        isFonClose: true,
        fonCloseFunc: modalAlertMeasureLeaveCloseCancel,
        icon: $root.globalAlertData.modalAlertMeasureLeave.icon,
        title: $root.globalAlertData.modalAlertMeasureLeave.title,
        text: $root.globalAlertData.modalAlertMeasureLeave.text,
        buttons: [
            {
              text: $root.globalAlertData.modalAlertMeasureLeave.buttonStayText,
              lightType: $root.globalAlertData.modalAlertMeasureLeave.buttonStayLightType,
              icon: $root.globalAlertData.modalAlertMeasureLeave.buttonStayIcon,
              func: modalAlertMeasureLeaveCloseCancel,
            },
            {
              text: $root.globalAlertData.modalAlertMeasureLeave.buttonLeaveText,
              lightType: $root.globalAlertData.modalAlertMeasureLeave.buttonLeaveLightType,
              icon: $root.globalAlertData.modalAlertMeasureLeave.buttonLeaveIcon,
              func: modalAlertMeasureLeaveCloseApply,
            },
        ],
      }"
  />
</template>

<script>
import ModalInfo from "@/components/modalInfo.vue";
import ClModal from "@/components/library/ClModal.vue";

export default {
  name: 'modalAlertMeasureLeave',
  components: {ClModal, ModalInfo},
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    modalAlertMeasureLeaveCloseCancel() {
      this.$emit('modalAlertMeasureLeaveCloseCancel', true);
    },
    modalAlertMeasureLeaveCloseApply() {
      this.$emit('modalAlertMeasureLeaveCloseApply', true);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>