<template>
  <div class="map" :class="{'is-auto-height': heightByContainer}">
    <slot/>
    <div class="map_cont" :class="{ loading: mapReload || (!mapData?.records.length && !mapReload && !mapPoint), 'panel_show': currentProperties || currentClusterProperties}">
      <not-info
          v-if="!isMapPoint && !mapData?.records.length && !mapReload"
          :title="`Ничего не нашлось`"
          :text="`Нет результатов, удовлетворяющих критериям поиска. Попробуйте сбросить фильтры`"
      >
      </not-info>
      <template v-else>
        <slot name="inner_map"></slot>
        <div class="map_btn">
          <div
              :class="{'is-disabled': zoom > 20}"
              class="map_btnPlus"
              @click="mapIncrease">
          </div>
          <div
              :class="{'is-disabled': zoom < 2}"
              class="map_btnMinus"
              @click="mapDecrease">
          </div>
        </div>
        <loader
            v-if="mapReload"
            :animation-duration="3000"
            :size="60"
            :color="'#ED850A'"
        ></loader>
        <yandex-map
            v-if="(mapData || mapPoint) && !mapReload"
            @map-was-initialized="mapLoaded"
            :settings="settings"
            :coords="coords"
            :zoom="zoom"
            :controls="controls"
            :options="options"
            :scroll-zoom="false"
            :cluster-options="clusterOptions"
            :cluster-callbacks="{
                        points: {
                            click: clusterClick
                        }
                    }"
        >
          <ymap-marker
              v-if="isMapPoint"
              :coords="[mapPoint.lat, mapPoint.lon]"
              :icon="markerIcon"
              :marker-id="mapPoint.id"
              :options="markerOptions"
          >
          </ymap-marker>
          <template v-else>
            <ymap-marker
                v-if="!markerType"
                v-for="item in mapData?.records"
                :key="item.guid ?? item.id"
                :coords="[item.lat, item.lon]"
                cluster-name="points"
                :icon="markerIcon"
                :marker-id="item.guid ?? item.id"
                :options="markerOptions"
                @balloonopen="balloonOpen"
                @balloonclose="balloonClose"
                :properties="{'title': item.name, 'logo': item.logo, 'area': item.area, 'address': item.address, 'guid': item.guid, lat: item.lat, lon: item.lon, isThreeD: item.isThreeD, linkPanorama: item.linkPanorama, isThreeModel: item.isThreeModel}"
                :balloon-template="balloonTemplate"
            >
            </ymap-marker>
          </template>
        </yandex-map>
        <div class="map_panorama" v-if="panoramaShow || panoramaShow3">
          <div v-if="isMobile" class="close_panorama" @click="closePanorama"><img src="/img/ico/close.svg" alt=""></div>
          <app-map></app-map>
<!--          {{panoramaCoords}}-->
        </div>
        <div class="map_panorama" v-if="panoramaShow2">
          <div v-if="isMobile" class="close_panorama" @click="closePanorama"><img src="/img/ico/close.svg" alt=""></div>
          <iframe :src="currentProperties.linkPanorama" v-if="currentProperties && currentProperties.linkPanorama"/>
          <iframe :src="currentClusterProperties[0].linkPanorama" v-else-if="currentClusterProperties && currentClusterProperties[0].linkPanorama"/>
        </div>
        <div class="pam_panorama_btns">
          <div class="map_panorama_btn" v-if="(currentProperties && currentProperties.isThreeModel) || (currentClusterProperties && currentClusterProperties[0].isThreeModel) " @click="panoramaShowFun(currentProperties.isThreeModel)">
              <span v-if="!panoramaShow3" class="invert0"><img src="/img/ico/3d-view.svg" alt=""></span>
              <span class="invert0" v-else><img src="/img/ico/map_click.svg" alt=""></span>
          </div>
          <div class="map_panorama_btn" v-if="(currentProperties && currentProperties.linkPanorama) || (currentClusterProperties && currentClusterProperties[0].linkPanorama)" @click="panoramaShowFun2">

              <span v-if="!panoramaShow2">360&deg;</span>
              <span class="invert0" v-else><img src="/img/ico/map_click.svg" alt=""></span>
          </div>
          <div class="map_panorama_btn"  v-if="(currentProperties && currentProperties.isThreeD) || (currentClusterProperties && currentClusterProperties[0].isThreeD)" @click="panoramaShowFun()">
              <span v-if="!panoramaShow"><img src="/img/ico/rotate-3d.svg" alt="">3D</span>
              <span class="invert0" v-else><img src="/img/ico/map_click.svg" alt=""></span>
          </div>
        </div>
        <transition name="fade-balloon">
          <div class="map_list not_scroll"
               v-if="currentProperties"
          >
            <div class="close_list" v-if="isMobile" @click="closeListPanorama"><img src="/img/ico/close.svg" alt=""></div>
            <card card-type="rent"
                  @click="openRent(currentProperties.guid)"
                  class="card-wh">
              <div class="div_img div_img_prop" :class="{'not_img': !currentProperties.logo}"><div class="div_img_wrapp"><img v-if="currentProperties.logo" :src="currentProperties.logo.imageUrl" alt=""></div></div>
              <div class="div_tit fs4n" v-if="currentProperties.title">{{ currentProperties.title }}</div>
              <div class="div_info" v-if="currentProperties.area">
                <div class="sp1">Общая площадь:</div>
                <div class="sp2">{{ currentProperties.area }} м<sup>2</sup></div>
              </div>
              <div class="div_adress" v-if="currentProperties.address"><img src="/img/ico/maping1.svg" alt=""><div class="sp_text">{{ currentProperties.address }}</div></div>
            </card>
            <!--
            <card
                slot="balloon"
                card-type="balloon"
                :class="markerType"
            >
              <div class="cross" @click="hideBalloon"></div>
              <template v-if="!markerType">
                <div class="title">
                  <h4>{{ currentProperties.title }}</h4>
                </div>
                <div class="address" v-if="currentProperties.address">
                  <div class="street" v-if="currentProperties.address?.points">
                    {{ currentProperties.address?.points }}
                  </div>
                  <div class="street" v-else-if="currentProperties.address?.address">
                    {{ currentProperties.address?.address }}
                  </div>
                  <div class="metro" v-if="currentProperties.address?.metro?.length > 0">
                    <div id="metroTime">
                      <div class="loader"></div>
                    </div>
                    <div class="span_metro">
                          <span
                              v-for="(item, index) in currentProperties.address.metro"
                              :key="item.id"
                              :id="index == 0 ? 'metroName' : ''"
                          >{{ item.name }}<template
                              v-if="index + 1 != currentProperties.address.metro.length">,</template></span>
                    </div>
                  </div>
                </div>
                <div class="text">
                  <perfect-scrollbar>
                    <div class="textScroll" v-html="currentProperties.text"></div>
                  </perfect-scrollbar>
                </div>
                <div class="div_btn" v-if="currentProperties.button">
                  <cl-button :href="currentProperties.button" link>подробнее</cl-button>
                </div>
                <cl-button
                    v-else
                    @click="$router.push(`/infrastructure/${currentProperties.markerId}`)">смотреть подробнее
                </cl-button>
              </template>
            </card>
            -->
          </div>

        </transition>
        <transition name="fade-balloon">
          <div class="map_list"
               v-if="currentClusterProperties"
          >
<!--            <div class="cross" @click="currentClusterProperties = null"></div>-->
            <div class="close_list" v-if="isMobile" @click="closeListPanorama"><img src="/img/ico/close.svg" alt=""></div>
            <perfect-scrollbar>
              <card card-type="rent"
                    @click="openRent(item.guid)"
                    class="card-wh"
                    v-for="item in currentClusterProperties"
                    :key="item.guid"
              >
                <div class="div_img div_img_prop" :class="{'not_img': !item.logo}"><div class="div_img_wrapp"><img v-if="item.logo" :src="item.logo.imageUrl" alt=""></div></div>
                <div class="div_tit fs4n" v-if="item.title">{{ item.title }}</div>
                <div class="div_info" v-if="item.area">
                  <div class="sp1">Общая площадь:</div>
                  <div class="sp2">{{ item.area }} м<sup>2</sup></div>
                </div>
                <div class="div_adress" v-if="item.address"><img src="/img/ico/maping1.svg" alt=""><div class="sp_text">{{ item.address }}</div></div>
              </card>
<!--              <card-->
<!--                  slot="balloon"-->
<!--                  card-type="balloon"-->
<!--                  v-for="item in currentClusterProperties"-->
<!--              >-->
<!--                  <template v-if="!markerType">-->
<!--                    <div class="title">-->
<!--                      <h5>{{ item.title }}</h5>-->
<!--                    </div>-->
<!--                    <div class="address" v-if="item.address && item.address.address">-->
<!--                      <div class="street">-->
<!--                        {{ item.address.address }}-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <cl-button @click="$router.push(`/infrastructure/${item.markerId}`)">смотреть подробнее</cl-button>-->
<!--                  </template>-->
<!--              </card>-->
            </perfect-scrollbar>
          </div>

        </transition>
      </template>

    </div>
  </div>
</template>

<script>
/* isMapPoint - если нужна тока точка на карте
* markerType - вид карточки
* balloonOpenCoords - координаты точки что надо открыть
 */
import {yandexMap, ymapMarker, loadYmap} from 'vue-yandex-maps'
import ClButton from "@/components/library/ClButton.vue";

let mapInstance

export default {
  name: "Map",
  components: {ClButton, yandexMap, ymapMarker},
  computed: {
    balloonTemplate() {
      return `
            <div class="card card-balloon">

            </div>
            `
    }
  },
  props: {
    mapData: {
      type: Array,
      required: true
    },
    isMapPoint: {
      type: Boolean,
      default: false
    },
    mapReload: {
      type: Boolean,
      default: false
    },
    mapPoint: {
      type: Object
    },
    heightByContainer: {
      type: Boolean,
      default: false
    },
    markerType: {
      type: String
    },
    balloonOpenCoords: {
      type: Object
    }
  },
  data() {
    return {
      coords: [
        55.75692599166862, 37.61779536976927
      ],
      mapDataCoords: [],
      zoom: 14,
      controls: [],
      options: {
        suppressMapOpenBlock: true,
        yandexMapDisablePoiInteractivity: true
      },
      markerIcon: {
        layout: 'default#imageWithContent',
        imageHref: '/img/ico/marker.svg',
        imageSize: [32, 32],
        imageOffset: [-32, -32],
        contentOffset: [-32, -32],
      },
      markerOptions: {
        balloonPanelMaxMapArea: Infinity,
      },
      clusterOptions: {
        points: {
          clusterIconColor: '#ED850A',
          clusterOpenBalloonOnClick: false
        }
      },
      settings: {
        apiKey: 'c99b23cc-9919-4a5f-b713-5653ee3e0cd5',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1'
      },
      currentProperties: null,
      currentClusterProperties: null,
      scrollbarSettings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false
      },

      panoramaShow: false,
      panoramaShow2: false,
      panoramaShow3: false,
      panoramaCoords: {},
      isMobile: false,
    }
  },
  methods: {
    clusterClick(e) {
      if (e._defaultPrevented !== true && e.get('target').properties.get('geoObjects')) {
        this.currentClusterProperties = e.get('target').properties.get('geoObjects').map((item) => {
          return item.properties.getAll()
        })
      }
    },
    balloonOpen(e) {
      this.currentClusterProperties = null;
      this.currentProperties = e.get("currentTarget").properties._data;
      // console.log(e.get("currentTarget").properties._data);
      e.get('target').options.set({
        iconImageHref: '/img/ico/markerActive.svg',
        iconImageSize: [32, 32],
        iconImageOffset: [-32, -32],
        iconContentOffset: [-32, -32],
      });
      if (document.getElementById('metroTime')) {
        document.getElementById('metroTime').innerHTML = '<div className="loader"></div>';
      }
      setTimeout(() => {
        if (document.getElementById('metroName')) {
          let metroNameText = 'Москва, метро ' + document.getElementById('metroName').innerText,
              coordsPoint = e.get("currentTarget").geometry._coordinates;
          this.addMetroData(metroNameText, coordsPoint);
        }
      }, 500)

      if(document.getElementById('contentMapLeft')) {
        document.getElementById('contentMapLeft').classList.add('d-none')
      }

    },
    balloonClose(e) {
      e.get('target').options.set({
        iconImageHref: '/img/ico/marker.svg',
        iconImageSize: [32, 32],
        iconImageOffset: [-32, -32],
        iconContentOffset: [-32, -32],
      })
      this.currentProperties = null;

      if(document.getElementById('contentMapLeft')) {
        document.getElementById('contentMapLeft').classList.remove('d-none')
      }
    },
    mapLoaded(myMap) {
      mapInstance = myMap;
      if (this.mapDataCoords.length) {
        if (this.mapDataCoords.length > 1) {
          myMap.setBounds(ymaps.util.bounds.fromPoints(this.mapDataCoords));
        } else {

          this.zoom = 14;
          this.coords = this.mapDataCoords[0];
        }
      }

    },
    hideBalloon: function () {
      mapInstance.balloon.close();
    },
    mapIncrease() {
      this.currentClusterProperties = null;
      if (this.zoom === mapInstance.getZoom()) {
        this.zoom++
      } else {
        this.zoom = mapInstance.getZoom() + 1;
      }
    },
    mapDecrease() {
      this.currentClusterProperties = null;
      if (this.zoom === mapInstance.getZoom()) {
        this.zoom--
      } else {
        this.zoom = mapInstance.getZoom() - 1;
        mapInstance.setZoom(mapInstance.getZoom() - 1);
      }
    },
    setCoordsOnMap(data) {
      if (data) {
        data?.records.forEach((item) => {
          this.mapDataCoords.push([item.lat, item.lon]);
        })
      }
    },
    addMetroData(undergroundName, map_centr = [55.759, 37.5], appendId = 'metroTime') { // подсчет расстояни по названию

      let geocoder = ymaps.geocode(undergroundName, {kind: 'metro'});
      geocoder.then(
          function (res) {
            let undergorundPoint = res.geoObjects.get(0).geometry.getCoordinates(),
                route = ymaps.route([map_centr, undergorundPoint]),
                /**
                 * Создаем мультимаршрут.
                 * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/multiRouter.MultiRoute.xml
                 */
                multiRoute = new ymaps.multiRouter.MultiRoute({
                  referencePoints: [
                    map_centr,
                    undergorundPoint
                  ],
                  params: {
                    //Тип маршрутизации - пешеходная маршрутизация.
                    routingMode: 'pedestrian'
                  }
                }, {
                  // Автоматически устанавливать границы карты так, чтобы маршрут был виден целиком.
                  boundsAutoApply: true
                });
            let pedestrianPathTime = 0;
            // Подписка на событие обновления данных маршрута.
            multiRoute.model.events.add('requestsuccess', function () {
              // Получение ссылки на активный маршрут.
              // В примере используется автомобильный маршрут,
              // поэтому метод getActiveRoute() вернет объект multiRouter.driving.Route.
              let activeRoute = multiRoute.getActiveRoute();
              if (!activeRoute) {
                return false;
              }
              // Вывод информации о маршруте.
              //console.log("Длина: " + activeRoute.properties.get("distance").text);
              //console.log("Время прохождения: " + activeRoute.properties.get("duration").text);
              // Для автомобильных маршрутов можно вывести
              // информацию о перекрытых участках.
              /*if (activeRoute.properties.get("blocked")) {
                  console.log("На маршруте имеются участки с перекрытыми дорогами.");
              }*/

              pedestrianPathTime = activeRoute.properties.get("duration").text;

              route.then(function (route_data) {
                let time = route_data.properties.get('RouterRouteMetaData').Time,
                    path = route_data.properties.get('RouterRouteMetaData').Length,
                    newText = '<div class="dop_info_metro"><div class="metro_el"> Расстояние до станции: <b>' + path.text + '</b>, время в пути: <b>' + time.text + '</b> на машине, <b>' + pedestrianPathTime + '</b>пешком</div></div>';
                document.getElementById('metroTime').innerText = pedestrianPathTime;
              });
            });

          },
          function (err) {
            //alert('Ошибка');
          }
      );

    },
    openRent(guid){
      //this.$router.push({ path: `/arenda_kinopomecheniy/pavilony/item/${guid}`})
      window.open(this.$router.resolve({ path: `/arenda_kinopomecheniy/pavilony/item/${guid}`}).href);
    },
    //открытие/закрытие понарамы
    panoramaShowFun(model = false){
      if(model){
        this.panoramaShow = false;
        this.panoramaShow2 = false;
        this.panoramaShow3 = !this.panoramaShow3;
      } else {
        this.panoramaShow = !this.panoramaShow;
        this.panoramaShow2 = false;
        this.panoramaShow3 = false;
      }
      if(this.panoramaShow || this.panoramaShow3){
        //3d панарама(сюда js)


        // console.log('открыто');
        let point = this.currentProperties ? this.currentProperties : this.currentClusterProperties[0];
        this.panoramaCoords.lat = point.lat;
        this.panoramaCoords.lon = point.lon;
        // console.log(this.panoramaCoords.lat);
        // console.log(this.panoramaCoords.lon);
        const interval = setInterval(()=>{
          if (document.querySelector('app-map')){
            const embedded_map = document.querySelector('app-map');
            const positionsArray =
                {
                  coordinates: [this.panoramaCoords.lon, this.panoramaCoords.lat],
                  name: point.name,
                  address: point.address
                }
            if (model === true){
              positionsArray.model = point.guid;
              positionsArray.orientation = {};
            }

            embedded_map.position = positionsArray;
            clearInterval(interval);
          }
        },1000)
        if(this.isMobile){
          document.body.style.overflow = "hidden";
        }
      }
    },
    // открытие и закрытие панорамы
    panoramaShowFun2(){
      this.panoramaShow2 = !this.panoramaShow2;
      this.panoramaShow = false;
      this.panoramaShow3 = false;
      if(this.isMobile){
        document.body.style.overflow = "hidden";
      }
    },
    closeListPanorama(){
      this.panoramaShow = false;
      this.panoramaShow2 = false;
      this.currentProperties = null;
      this.currentClusterProperties = null;
    },
    closePanorama() {
      this.panoramaShow = false;
      this.panoramaShow2 = false;
      this.panoramaShow3 = false;
      document.body.style.overflow = "auto";
    }
  },
  watch: {
    mapData: {
      deep: true,
      handler(data) {
        this.mapDataCoords = [];
        this.$nextTick(() => {
          this.setCoordsOnMap(data);
        })
      }
    },
    balloonOpenCoords: {
      deep: true,
      handler(data){
        //mapInstance.balloon.open([data.lat, data.lon], this.balloonTemplate);
        mapInstance.setCenter([data.lat, data.lon]);
        setTimeout(() => {
          document.getElementById(data.guid).click();
        }, 200);
      },
    }
  },
  async mounted() {
    let map = document.createElement('script')
    map.setAttribute('src', 'https://smart.mos.ru/geodata/3dtiles/cinema_cluster/js/embedded_map.js')
    document.head.appendChild(map)
    let cesium = document.createElement('script')
    cesium.setAttribute('src', 'https://smart.mos.ru/geodata/3dtiles/cinema_cluster/js/cesium/Cesium.js')
    document.head.appendChild(cesium)

    await loadYmap({...this.settings});
    //loadYmap({...this.mapSettings}).then(()=> { var geolocation = window.ymaps.geolocation ); }) } )
    if (document.getElementById('metroName')) {
      let metroNameText = 'Москва, метро ' + document.getElementById('metroName').innerText,
          coordsPoint = [this.mapPoint.lat, this.mapPoint.lon];
      this.addMetroData(metroNameText, coordsPoint);
    }
    if (this.isMapPoint) {
      this.coords = [
        this.mapPoint.lat, this.mapPoint.lon
      ];
    }

    if(window.innerWidth < 768){
      this.isMobile = true;
    }
    window.addEventListener("resize", () => {
      if(window.innerWidth < 768){
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  }
}
</script>

<!--<style src="vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css"/>-->
<style lang="scss">

.fade-balloon-enter-active, .fade-balloon-leave-active {
  transition: .4s ease-in-out;
}

.fade-balloon-enter-active, .fade-balloon-leave-to {
  opacity: 0;
  transform: translateY(-20%);
}

.fade-balloon-enter-to {
  opacity: 1;
  transform: translateY(0%);
}

.ymaps-2-1-79-ground-pane {
  filter: grayscale(1);
  -ms-filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
}

//.ymaps-2-1-79-inner-panes {
//  &::before {
//    content: '';
//    left: 0;
//    right: 0;
//    top: 0;
//    bottom: 0;
//    position: absolute;
//    mix-blend-mode: difference;
//    pointer-events: none;
//    background-color: #fff;
//    z-index: 999;
//  }
//}
//
.ymaps-2-1-79-panel-pane {
  display: none !important;
}

.ymap-container {
  //filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.25));
  overflow: hidden;
  //border-radius: 7px;
}

.map_cont.loading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 650px;
  @media (max-width: 992px) and (min-width: 480px) {
    height: 688px;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    //background-color: rgba(0, 0, 0, .25);
  }
}

.map {
  &_btn {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    margin: -5px 0;
    z-index: 2;

    &Plus {
      &::after {
        content: '';
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    > * {
      cursor: pointer;
      width: 48px;
      height: 48px;
      background: #FFF;
      border: 2px solid #ECECEC;
      border-radius: 4px;
      text-align: center;
      margin: 16px 0;
      transition: .2s linear;
      position: relative;

      &::before, &::after {
        position: absolute;
        display: block;
        height: 2px;
        width: 20px;
        background-color: #000106;
      }

      &::before {
        content: '';
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &.is-disabled {
        opacity: .5;
        pointer-events: none;
      }

      &:hover {
        opacity: .5;
      }
    }

    //@media (max-width: 479px) {
    //  transform: none;
    //  right: 0;
    //  top: 20px;
    //}
  }

  &_cont {
    position: relative;

    > section {
      height: 901px;
      max-height: 70vh;
      min-height: 700px;
      @media (max-width: 1400px) {
        height: 691px;
      }
      @media (max-width: 991px) {
        height: 70vh;
        min-height: auto;
        max-height: 70vh;
      }
    }
  }

  &.is-auto-height {
    max-height: 100%;
    height: 100%;

    .map_cont {
      height: 100%;

      > section {
        height: 100% !important;
      }
    }

    .map_btn {
      @media (max-width: 479px) {
        right: 20px;
      }
    }
  }

  .filter_tabs {
    margin-bottom: 40px;
  }

  &.map_filter {
    @media (min-width: 992px) {
      h2 {
        margin-bottom: 50px;
      }
    }
  }
}





.map_list {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 462px;
  padding: 0 49px 0 24px;
  background: #FFF;
  z-index: 10;
  .card {
    height: auto;
    margin-bottom: 24px;
    max-width: 400px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.not_scroll {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .ps {
    height: 100%;
    padding: 24px 20px 24px 12px;
    .ps__rail-y {
      right: 0;
    }
  }
  @media (max-width: 991px) {
    width: 330px;
    padding: 0 24px;
  }
  @media (max-width: 767px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.panel_show .map_btn {
  right: 482px;
  @media (max-width: 991px) {
    right: 340px;
  }
  @media (max-width: 767px) {
    display: none;
  }
}


.map_panorama {
  position: absolute;
  z-index: 20;
  left: 0;
  right: 462px;
  top: 0;
  bottom: 0;
  iframe{
    width: 100%;
    height: 100%;
  }
  @media (max-width: 991px) {
    right: 330px;
  }
  @media (max-width: 767px) {
    right: 0;
    position: fixed;
    z-index: 10000;
  }
}
.pam_panorama_btns{
  position: absolute;
  right: 470px;
  bottom: 20px;
  z-index: 20;
  display: flex;
  gap: 16px;
  font-size: 18px;
  @media (max-width: 991px) {
    right: 345px;
  }
  @media (max-width: 767px) {
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    transform: translateY(120%);
    width: 100%;
    height: 48px;
    padding: 0 16px;
  }
}
.map_panorama_btn {
  cursor: pointer;
  min-width: 48px;
  height: 48px;
  padding-right: 24px;
  padding-left: 24px;
  background: #FFF;
  border: 2px solid #ECECEC;
  border-radius: 4px;
  text-align: center;
  margin: 0;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
  color: #000000;
  img{
    width: 24px;
    height: 24px;
    object-fit: contain;
    filter: invert(100%);
  }
  .invert0{
    img {
      filter: invert(0);
    }
  }
}

.close_list{
  position: absolute;
  right: 16px;
  top: -15px;
  transform: translateY(-100%);
}

.close_panorama{
  position: fixed;
  top: 10px;
  right: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  background-color: white;

  border-radius: 4px;
  border: 1px solid #ECECEC;
  z-index: 10001;
}
</style>