<template>
  <div class="collapse_el"
       ref="collapseWrap"
       :class="disabled ? 'disabled' : null"
  >
    <div
        class="collapse_btn"
        :class="visible ? 'collapsed' : null"
        @click="toggleCollapse"
    >
      <span class="collapse_text"><slot name="name"></slot></span>
    </div>
    <div
        v-if="!disabled"
        class="collapse"
        :class="visible ? 'collapsed' : null"
        :style="{height}"
        ref="collapse"
    >
      <div class="collapse_card">
        <slot name="card"></slot>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * groupName {props} - азвание группы, чтоб открывался тока 1 элемент
 */
export default {
  name: "ClCollapse",
  props: {
    disabled:  {
      type: Boolean,
      default: false
    },
    visibleStart: {
      type: Boolean,
      default: false
    },
    groupName: {
      type: String
    }
  },
  data() {
    return {
      visible: false,
      height: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.showCollapseVisibleStart();
    })
  },
  methods: {
    toggleCollapse(e) {
      this.visible = !this.visible;
      this.$nextTick(() => {
        this.renderHeight();
      })
      this.$emit("collapsed", this.visible);
      if(this.groupName){
        this.collapseGroupHide();
      }
    },
    renderHeight() {
      if (this.visible) {
        this.height = this.$refs.collapse.scrollHeight + 'px'
      } else {
        this.height = 0;
      }
    },
    showCollapseVisibleStart(){
      if(this.visibleStart && !this.disabled){
        this.visible = true;
        setTimeout(() => {
          this.height = this.$refs.collapse.scrollHeight + 'px'
        }, 100);
        this.$emit("collapsed", this.visible);
      }
    },
    /**
     * скрытие всех других колапсов на одном уровне
     */
    collapseGroupHide(){
      const elClick = this.$refs.collapseWrap;
      elClick.classList.add('collapse_el_multi');
      elClick.parentElement.querySelectorAll('.collapse_el').forEach(child => {
        if(!child.querySelector('.collapse_btn').classList.contains('collapse_el_multi') && child.querySelector('.collapse_btn').classList.contains('collapsed') ) {
          child.querySelector('.collapse_btn').click();
        }
        elClick.classList.remove('collapse_el_multi');
      });
    }
  }
}
</script>

<style lang="scss">
.collapse_el {
  border-bottom: 1px solid rgba(255, 255, 255, 1);

  &:last-child {
    border-bottom: none;
  }

  &.disabled {
    opacity: .5;
    cursor: default;
    pointer-events: none;
  }

  .collapse_btn {
    padding: 24px 0 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:after {
      content: "";
      flex: none;
      margin: 0 12px 0 20px;
      width: 14px;
      height: 14px;
      border-top: 2px solid #ED850A;
      border-right: 2px solid #ED850A;
      transform: rotate(135deg);
      transition: transform .3s ease;
    }
  }

  .collapse {
    height: 0;
    overflow: hidden;
    transition: 0.3s ease-in-out;
  }

  .collapse_card {
    padding: 0 0 30px 0;

  }

  .collapsed {
    &.collapse_btn {
      &:after {
        transform: rotate(-45deg);
      }
    }

    &.collapse {

    }
  }
}


.collapse_point {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: none;

  .collapse_text {
    display: flex;
    align-items: center;
  }

  .collapse_card {
    padding-left: 32px;
  }

  .div_point {
    width: 20px;
    height: 20px;
    flex: none;
    display: inline-block;
    margin-right: 12px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.2);

    &.is-active {
      background-color: #ED850A;
      border-color: #ED850A;
    }

    &.is-completed {
      background-color: #ED850A;
      border-color: #ED850A;
      background-image: url('@/assets/img/ico/check-white.svg');
      background-position: center;
      background-size: 10px;
      background-repeat: no-repeat;
    }
  }
}

.collapse_MT {
  .collapse_btn {
    height: 21.6rem;

    &:after {
      content: unset;
    }

    .collapse_text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }

    .dt2 {
      width: 5.6rem;
      min-width: 5.6rem;
      height: 5.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #FFF;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.1);
      margin-left: 3.2rem;

      &:after {
        content: "";
        flex: none;
        width: 14px;
        height: 14px;
        border-top: 2px solid #FFF;
        border-right: 2px solid #FFF;
        translate: 0 -3px;
        transform: rotate(135deg);
        transition: all 0.3s ease;
      }
    }
  }

  .collapse_card {
    padding: 0;
  }

  .collapsed.collapse_btn .dt2:after {
    transform: rotate(-45deg);
    translate: 0 3px;
  }

  @media (max-width: 991px) {
    .collapse_btn {
      height: unset;
      padding: 0;

      .dt2 {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        min-width: 40px;
        height: 40px;

        &:after {
          width: 12px;
          height: 12px;
        }
      }
    }
    .collapse_card {
      padding-top: 20px;
    }
  }
}

.collapse_schedule {
  .collapse_btn {
    justify-content: unset;
    padding: 0;
    &:after {
      width: 10px;
      height: 10px;
      border-color: #FFFFFF;
    }
  }
  .collapse_card {
    padding-bottom: 0;
    padding-top: 4px;
  }
}
.collapse_map {
  width: 100%;
  border-bottom: none;
  .collapse_text {
    color: #ED850A;
  }
  .collapse_btn {
    justify-content: unset;
    padding: 9px 0;
    &:after {
      margin: 0 12px 0 12px;
      width: 7px;
      height: 7px;
    }
  }
  .collapse_card {
    padding: 0;
  }

  .collapsed.collapse_btn .dt2:after {
    transform: rotate(-45deg);
    translate: 0 3px;
  }
  @media (max-width: 991px) {
    .collapse_btn {
      height: unset;
      padding: 0;
      .dt2 {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        min-width: 40px;
        height: 40px;
        &:after {
          width: 12px;
          height: 12px;
        }
      }
    }
    .collapse_card {
      padding-top: 20px;
    }
  }

}
</style>