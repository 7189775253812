<template>
  <div class="block_wrap block_wh">
    <Map :mapData="mapGeoObjects"
         :mapReload="mapReload"
    >
      <container>
        <h2 v-if="dataInfo.title">{{ dataInfo.title?.value }}</h2>
        <div class="pos_rel">
          <div class="open_is_mob" @click="mobOpen = !mobOpen" :class="mobOpen ? 'active' : ''"></div>
          <div class="map_info_left" v-if="quantity && quantity.length > 0" :class="mobOpen ? 'active' : ''">
            <template
                v-for="item in quantity"
                :key="item.id"
            >
              <div class="block_el"
                   v-if="item.total > 0"
                   :class="{'active': this.activeCategoryList.indexOf(item.id) != -1}"
                   @click="changeCategory(item.id)"
              >
                <div class="div_num fs2">{{ item.total }}</div>
                <div class="div_text" v-if="item.caseROne && item.caseIOne && item.caseRMany">
                  {{ $filters.numWord(item.total, [item.caseIOne, item.caseROne, item.caseRMany]) }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </container>
    </Map>
  </div>
</template>

<script>
import Map from "@/components/map.vue";

export default {
  name: "mapBlock",
  components: {Map},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      quantity: false,
      mapGeoObjects: null,
      mapParams: {
        all: 1
      },
      mapReload: true,
      controller: null,
      activeCategoryList: [],
      mobOpen: false,
    }
  },
  methods: {
    // карта
    getMapMembersData() {
      return this.axios
          .get('/api/data/pins-on-map', {signal: this.controller.signal})
          .then(response => {
            this.mapGeoObjects = {};
            this.mapGeoObjects.records = response.data.records.filter(item => {
              return item.lat && item.lon
            });
          })
          .catch(error => {
            this.showError(error);
          })
          .finally(() => {
            this.mapReload = false;
          });
    },
    // количество
    quantityData() {
      return this.axios
          .get('/api/data/category-count', {signal: this.controller.signal})
          .then(response => {
            this.quantity = response.data.category;
          })
          .catch(error => {
            this.showError(error);
          })
          .finally(() => {
          });
    },
    changeCategory(id){
      if (this.activeCategoryList.indexOf(id) != -1)  {
        this.activeCategoryList = [...this.activeCategoryList].filter(item => item != id);
      } else {
        this.activeCategoryList = [...this.activeCategoryList, id];
      }
      if(this.activeCategoryList.length > 0){
        this.mapParams.categories = this.activeCategoryList.join();
      } else {
        delete this.mapParams.categories;
      }
      // if(id == "isThreeD"){
      //     this.mapParams = {all: 1, isThreeD: true};
      // }
      this.mapReload = true;
      this.$nextTick(() => {
        this.getMapMembersData();
      });
    }
  },
  created() {
    this.controller = new AbortController();
  },
  mounted() {
    this.getMapMembersData();
    this.quantityData();

    const element = document.querySelector(".block_wrap.block_wh");
    var ts;
    var tsX
    if(element){
      element.addEventListener('touchstart', (e) => {
        ts = e.touches[0].clientY;
        tsX = e.touches[0].clientX;
      });

      element.addEventListener('touchend', (e) => {
        var te = e.changedTouches[0].clientY;
        var teX = e.changedTouches[0].clientX;

        if((tsX > teX-40) && (Math.abs(teX - tsX) > Math.abs(te - ts))) {
          if(this.mobOpen){
            this.mobOpen = false;
          }
        }
      });

      setInterval(() => {
      }, 200)
    }
  },
  beforeUnmount() {
    // отмена запросов
    this.controller.abort();
    this.controller = null;
  }
}
</script>

<style lang="scss" scoped>
.block_wh {
  padding: 70px 0;
}

.map_info_left {
  position: absolute;
  top: 145px;
  left: 0;
  width: 311px;
  z-index: 10;

  .block_el {
    padding: 8px;
    border: 2px solid #ffffff;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 20, 67, 0.12);
    margin-bottom: 12px;
    cursor: pointer;

    &.active {
      border-color: #ED850A;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .div_num {
    color: #ED850A;
  }

  .div_tit {
    color: #ED850A;
  }

  .div_text {
    padding: 8px 0px;
    border-top: 1px solid #B2B5BB;
  }
}

.open_is_mob{
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #ECECEC;
  z-index: 1;
  &:after{
    content: '';
    width: 15px;
    height: 15px;
    border-right: 3px solid #000106;
    border-top: 3px solid #000106;
    transform: rotate(45deg);
    margin-right: 20%;
    transition: all 0.2s ease;
  }
  &.active{
    &:after{
      transform: rotate(225deg);
      border-color: #ED850A;
      margin-right: -20%;
    }
  }
}

@media (max-width: 991px) {
  .open_is_mob{
    display: flex;
  }
  .map_info_left{
    width: auto;
    min-width: 160px;
    transition: all 0.2s ease;
    top: 10vh;
    left: -100%;
    &.active{
      left: 0;
    }
    .fs2{
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 133.333%;
      letter-spacing: 0.24px;
    }
    .div_text{
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 133.333%;
      letter-spacing: 0.12px;
    }
  }
}
</style>