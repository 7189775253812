<template>
  <div class="rent_item">

    <loader
        v-if="isLoadingModal"
        :animation-duration="3000"
        :size="60"
        type="circleFix"
    ></loader>

    <loader
        v-if="isLoading"
        :animation-duration="3000"
        :size="60"
    ></loader>
    <container v-else-if='data'>
      <div class='titleBlock'>
        <h1 class='hasSubtit'>{{ data.name }}</h1>
        <h4 class='subtit'>{{ data.address }}</h4>
      </div>
      <div class=''>
        <div class=''>
          <div v-if='(data.photos && data.photos.length > 0) || (data.video && data.video.length > 0)' class='sliderThumbs'>
            <swiper
                ref="mySwiperRent"
                :spaceBetween="10"
                :navigation="true"
                :thumbs="{ swiper: thumbsSwiper }"
                :threshold='10'
                :modules="modules"
                :loop="data.photos.length > 1 ? true : false"
                class="sliderThumbsMain"
                @slideChange="videoPause"
                @activeIndexChange="updateActiveIndex"
            >
              <template v-if="data.photos && data.photos.length > 0">
                <swiper-slide v-for="photo of data.photos" :key="photo.guid">
                  <div class="img_wrap_rent">
                    <img :src="photo.imageUrl" alt=""/>
                  </div>
                </swiper-slide>
              </template>
              <template v-if="data.video && data.video.length > 0">
                <swiper-slide v-for="itemVideo of data.video" :key="itemVideo" class="swiper-video-slide">
                  <loader
                      v-if="isLoadingVideo"
                      :animation-duration="3000"
                      :size="60"
                  ></loader>
                  <div class="swiper-video" v-else-if="itemVideo.iframe" v-html="itemVideo.iframe"></div>
                </swiper-slide>
              </template>
            </swiper>
            <swiper
                ref="thumbsSwiper"
                @swiper="setThumbsSwiper"
                :spaceBetween="24"
                :slidesPerView="'auto'"
                :freeMode="true"
                :watchSlidesProgress="true"
                :threshold='10'
                :modules="modules"
                class="sliderThumbsThumbs"
                :initialSlide="activeIndex"
                @slideChange="updateMainSwiper"
            >
              <template v-if="data.photos && data.photos.length > 0">
                <swiper-slide v-for="(photo, index) of data.photos" :key="photo.guid" :class="{ slide_new: index === realIndex }">
                  <img :src="photo.imageUrl" alt=""/>
                </swiper-slide>
              </template>
              <template v-if="data.video && data.video.length > 0">
                <swiper-slide v-for="(itemVideo, index) of data.video" :key="itemVideo" class="swiper-thumb-video" :class="{ slide_new: index === realIndex }">
                  <loader
                      v-if="isLoadingVideo"
                      :animation-duration="3000"
                      :size="60"
                  ></loader>
                  <img v-else-if="itemVideo.preview" :src="itemVideo.preview" alt=""/>
                </swiper-slide>
              </template>
            </swiper>
          </div>

          <cl-modal v-model="modalShow" class="modalTour rentModalTour" id="modalTour">
            <template v-slot:body>
              <app-map></app-map>
              <!--              карта {{ panoramaCoords }}-->
            </template>
            <template v-slot:footer>
              <div class="button_botton">
                <div class="share button_ico" @click="copyLink('скопировал')">
                  <img src="/img/ico/share-2.svg" alt="">
                </div>
                <div class="fullScreen button_ico" @click="fullScreen" id="fullScreen">
                  <img src="/img/ico/maximize.svg" alt="">
                </div>
                <div class="exitfullScreen button_ico d-none" @click="exitFullScreen" id="exitfullScreen">
                  <img src="/img/ico/minimize.svg" alt="">
                </div>
              </div>
            </template>
          </cl-modal>

          <div class='titRow'>
            <h3>Описание</h3>

            <teleport to="#site_header_container">
              <div class="fixPanel_block" :class="{'active': fixPanelShow || formPeriodDate}" v-if="data?.measure && showFormInint">
                <container>
                  <div class="dt1">{{ data.name }}</div>
                  <div v-if="!data.isNotPossibleBook" class="dt2">
                    <div class="imitation_cal" @click="scrollCalendar">
                      <template v-if="formPeriodDate">
                        {{$filters.ruDate5(new Date(formPeriodDate?.start)) +  '-' + $filters.ruDate5(new Date(formPeriodDate?.end))}}
                        <div class="imitation_cal_close img_wh" @click="clearCalendar"><img src="/img/ico/close.svg"></div>
                      </template>
                      <span v-else class="op05">Выберите дату бронирования</span>
                    </div>
                  </div>
                  <div class="dt3">
                    <template v-if="formIf && ((!data.isNotPossibleBook && !typeIf('1315826')) || typeIf('1315826'))">
                      <cl-button type="light" class="btn-middle pointer-events-a" @click="showFormIf" :class="{'btn-disabled': !formPeriodDate && !typeIf('1315826')}">{{ btnText }}</cl-button>
                    </template>
                    <template v-else-if="!formIf && !data.isNotPossibleBook">
                      <cl-button type="light" class="btn-middle pointer-events-a" @click="modalBeforeForm = !modalBeforeForm">{{ btnText }}</cl-button>
                    </template>
                    <cl-modal v-model="modalBeforeForm" modalType="black" :class="{'modal-center': !modalBeforeFormStudents}" v-bind="modalAlertMeasureLeaveTopModalAttr(dataInfo?.formStudents?.value && getIsAuthorization && formShowParams)" :close="modalBeforeFormClose">
                      <template v-slot:header>
                        <template v-if="!getIsAuthorization">Для подачи заявки по выбранной локации и доступа к мерам поддержки, пожалуйста авторизуйтесь на Киноплатформе!</template>
                        <template v-else-if="!modalBeforeFormStudents">Данная опция доступна на Киноплатформе только компаниям (юридическим лицам)</template>
                        <template v-else-if="formTitle"><span class="color-or">{{ formTitle }}</span></template>
                      </template>
                      <template v-slot:body v-if="getIsAuthorization">
                        <div class="info_students" v-if="!modalBeforeFormStudents">
                          <div class="div_tit">Авторизуйте компанию в личном кабинете Киноплатформы.</div>
                          <div class="div_btn"><cl-button routerLink="/lk">Перейти в личный кабинет</cl-button></div>
                          <div class="div_bot">Либо, воспользуйтесь специальной мерой поддержки <a class="a-or" @click="modalBeforeFormStudents = true">для студентов</a>.</div>
                        </div>
                        <template v-else-if="dataInfo.formStudents?.value">
                          <measure-form-component
                              :form-type="1"
                              @formTitle="formTitleFun"
                              v-if="formShowParams"
                              :measureGuid="dataInfo.formStudents?.value"
                              :showMode="showMode"
                              :formParams="formParams"
                              :successText="successText"
                              :buttonText="buttonText"
                              :buttonLink="buttonLink"
                              @loaderHide="loaderHide"

                              v-bind="modalAlertMeasureLeaveAttr()"
                              v-on="modalAlertMeasureLeaveEmits()"
                          />
                        </template>
                      </template>
                      <template v-slot:footer v-if="!getIsAuthorization">
                        <cl-button :link="true" href="/connect/mosru">Авторизоваться</cl-button>
                        <cl-button @click="modalBeforeForm = !modalBeforeForm" type="light">Закрыть</cl-button>
                      </template>
                    </cl-modal>
                  </div>
                </container>
              </div>
            </teleport>

            <div class="titRow-buttons" ref="fixPanelTriger">
              <template v-if="data?.measure && showFormInint">
                <template v-if="formIf && ((!data.isNotPossibleBook && !typeIf('1315826')) || typeIf('1315826'))">
                  <cl-button type="light" class="btn-middle pointer-events-a" @click="showFormIf" :class="{'btn-disabled': !formPeriodDate && !typeIf('1315826')}">{{ btnText }}</cl-button>
                </template>
                <template v-else-if="!formIf && !data.isNotPossibleBook">
                  <cl-button type="light" class="btn-middle pointer-events-a" @click="modalBeforeForm = !modalBeforeForm">{{ btnText }}</cl-button>
                </template>
              </template>
              <cl-modal v-model="showForm" :closeFonActive="false" class="modal_form" v-bind="modalAlertMeasureLeaveTopModalAttr(formShowParams)">
                <h4 class="modal-header" v-if="formTitle">
                  {{ formTitle }}
                </h4>
                <measure-form-component
                    :form-type="1"
                    @formTitle="formTitleFun"
                    v-if="formShowParams"

                    :measureGuid="typeIf('1315826') ? 'a90c494eb61344de9d81dd6b6dcdfd37' : data.measure"
                    :showMode="showMode"
                    :formParams="formParams"
                    :successText="successText"
                    :buttonText="buttonText"
                    :buttonLink="buttonLink"
                    @loaderHide="loaderHide"

                    v-bind="modalAlertMeasureLeaveAttr()"
                    v-on="modalAlertMeasureLeaveEmits()"
                />
              </cl-modal>



              <cl-button type="light" v-if="data.isThreeModel" @click="panoramaShowFun(data.isThreeModel)" class="btn-ico ico_3d" img="/img/ico/3d-view.svg"></cl-button>
              <cl-button type="light" v-if="data.linkPanorama" @click="modalPanoram = !modalPanoram">360&deg;</cl-button>
              <cl-modal v-model="modalPanoram" class="modalTour" v-if="data.linkPanorama">
                <template v-slot:body>
                  <iframe :src="data.linkPanorama"></iframe>
                </template>
                <template v-slot:footer>
                  <div class="button_botton">
                    <div class="fullScreen button_ico" @click="fullScreen" id="fullScreen">
                      <img src="/img/ico/maximize.svg" alt="">
                    </div>
                    <div class="exitfullScreen button_ico d-none" @click="exitFullScreen" id="exitfullScreen">
                      <img src="/img/ico/minimize.svg" alt="">
                    </div>
                  </div>
                </template>
              </cl-modal>
              <cl-button type="light" v-if="data.isThreeD" @click="panoramaShowFun()" class="btn-ico" img="/img/ico/rotate-3d-or.svg">3D</cl-button>
              <cl-button type="light" v-if="data.plans && isPdf" link :href="data.plans[0]?.url">Скачать схему</cl-button>
              <cl-button type="light" v-else-if="data.plans && !isPdf" @click="modalShowImage = !modalShowImage">Открыть схему</cl-button>
              <cl-modal v-model="modalShowImage" class="modalTour" v-if="data.plans && !isPdf">
                <template v-slot:body>
                  <div class="image_modal">
                    <img :src="data.plans[0]?.url" alt="">
                  </div>
                </template>
                <template v-slot:footer>
                  <div class="button_botton">
                    <div class="fullScreen button_ico" @click="fullScreen" id="fullScreen">
                      <img src="/img/ico/maximize.svg" alt="">
                    </div>
                    <div class="exitfullScreen button_ico d-none" @click="exitFullScreen" id="exitfullScreen">
                      <img src="/img/ico/minimize.svg" alt="">
                    </div>
                  </div>
                </template>
              </cl-modal>
            </div>
          </div>
          <div class='content_block' v-if="data.comment" v-html="data.comment"></div>

          <div class='content_block'
               v-if='data.area || data.length || data.ceilingHeight || data.width || data.ceilingHeightTrusses || data.powerElectricity || (data.technical && Object.keys(data.technical) > 0)'>
            <div class='row characters'>
              <div class='col-md-6 col-sm-12' v-if='data.area'>
                <div class='character'><span>Площадь, м2</span><span>{{ data.area }} м<sup>2</sup></span></div>
              </div>
              <div class='col-md-6 col-sm-12' v-if='data.length'>
                <div class='character'><span>Длина павильона</span><span>{{ data.length + ' м' }}</span></div>
              </div>
              <div class='col-md-6 col-sm-12' v-if='data.ceilingHeight'>
                <div class='character'><span>Высота потолков (общая)</span><span>{{ data.ceilingHeight + ' м' }}</span>
                </div>
              </div>
              <div class='col-md-6 col-sm-12' v-if='data.width'>
                <div class='character'><span>Ширина павильона</span><span>{{ data.width + ' м' }}</span></div>
              </div>
              <div class='col-md-6 col-sm-12' v-if='data.ceilingHeightTrusses'>
                <div class='character'>
                  <span>Высота потолков до ферм</span><span>{{ data.ceilingHeightTrusses + ' м' }}</span></div>
              </div>
              <div class='col-md-6 col-sm-12' v-if='data.powerElectricity'>
                <div class='character'>
                  <span>Мощность электричества</span><span>{{ data.powerElectricity + ' кВТ' }}</span></div>
              </div>
              <div class='col-md-6 col-sm-12' v-if='data.heightVentilation'>
                <div class='character'>
                  <span>Высота до вентиляции</span><span>{{ data.heightVentilation  + ' м' }}</span></div>
              </div>
            </div>
            <div class='row row_marker_dot'>
              <template v-for='(character, key) in data.technical'>
                <div
                    class='col-md-6 col-sm-12'
                    v-if='character'
                >
                  <div class='marker_dot'>{{ translateWord(key) }}</div>
                </div>
              </template>
            </div>
          </div>
          <div class='content_block' v-if="data.comfort && Object.keys(data.comfort).length > 0 && getBoolObj(data.comfort)">
            <h4>Удобства</h4>
            <div class='row row_marker_dot'>
              <template v-for='(character, key) in data.comfort'>
                <div
                    v-if='character'
                    class='col-md-6 col-sm-12'>
                  <div class='marker_dot'>{{ translateWord(key) }}</div>
                </div>
              </template>
            </div>
          </div>
          <div class='content_block' v-if="data.service && Object.keys(data.service).length > 0 && getBoolObj(data.service)">
            <h4>Дополнительные услуги</h4>
            <div class='row row_marker_dot'>
              <template v-for='(character, key) in data.service'>
                <div
                    class='col-md-6 col-sm-12'
                    v-if='character'
                >
                  <div class='marker_dot'>{{ translateWord(key) }}</div>
                </div>
              </template>
            </div>
          </div>

          <div class='company_block' v-if="data.company?.name || data.company?.description">
            <div class='text'>
              <h3 v-if="data.company?.name && data.category?.name == 'Павильоны'">Данный павильон предоставляет компания<br> {{ data.company.name }}</h3>
              <h3 v-else-if="data.company?.name">Данный объект предоставляет компания<br> {{ data.company.name }}</h3>
              <div class="text_m" v-if="data.company?.description" v-html="data.company.description"></div>
              <cl-button
                  v-if="data.company?.phone"
                  @click="phoneShowClick"
                  class="color-wh"
              >
                <template v-if="!phoneShow">Показать телефон</template>
                <a v-else :href="`tel:${data.company?.phone}`">{{ data.company?.phone }}</a>
              </cl-button>
              <div ref="telCopy" class="copy_text_none">{{ data.company?.phone }}</div>
            </div>
            <div class='icon' v-if="data.company?.logo">
              <img :src="data.company?.logo.originalUrl" alt=""/>
            </div>
          </div>

          <div class="datepicker_form_bot" ref="datepickerFormBot" v-if="!typeIf('1315826') && !data.isNotPossibleBook">
            <h4>Календарь бронирования</h4>
            <div ref="calendarEl">
              <cl-calendar-two
                  v-model="formPeriodDate"
                  :attributes="calendarPoints"
                  :disabledDates="disabledDates"
                  :min-date="minDate"
                  @fromPageCalendar="fromPageCalendar"
                  typeCalendar="reservationPavilony"
              />
            </div>
          </div>
        </div>
      </div>
    </container>

    <how-to-get-new

        :dataInfo="dataInfo"
        v-if="data && data.type?.id && data.type?.id == '1315822'"
    ></how-to-get-new>

  </div>
</template>

<script>
import translates from '@/assets/js/resources/translate/rent';
import {mapGetters} from "vuex";
import {Swiper, SwiperSlide} from 'swiper/vue';
import {FreeMode, Navigation, Thumbs} from 'swiper';
import 'swiper/css';

import 'swiper/css/free-mode'
import 'swiper/css/thumbs'
import MeasureFormComponent from "@/components/measureForm.vue";
import ClCalendarTwo from "@/components/library/CalendarTwo.vue";
import ClButton from "@/components/library/ClButton.vue";
import HowToGet from "@/components/howToGet.vue";
import mixinModalAlertMeasureLeave from "@/mixin/mixinModalAlertMeasureLeave";
import howToGetNew from "@/components/howToGetNew";
import {updateMetaTags} from "@/assets/js/updateMetaTags";

export default {
  name: 'rentItem',
  components: {
    HowToGet,
    ClButton,
    ClCalendarTwo,
    MeasureFormComponent,
    Swiper,
    SwiperSlide,
    howToGetNew,
  },
  mixins: [mixinModalAlertMeasureLeave],
  data() {
    return {
      activeIndex: 0,
      realIndex: 0,
      thumbsSwiper: null,
      data: null,
      isLoading: false,
      formParams: {
        'appendCss': '/override/pavilon.css',
        preSetParams: {},
        forcePreset: 1
      },
      showMode: 'full',
      phoneShow: false,
      formTitle: null,
      modalBeforeForm: false,
      modalBeforeFormStudents: false,
      showForm: false,
      formShowParams: true,
      showFormInint: false,
      modalShow: false,
      modalShowImage: false,
      modalPanoram: false,
      panoramaCoords: {},
      successText: "Ваша заявка успешно отправлена",
      buttonText: "Вернуться в каталог",
      buttonLink: "",
      isPdf: false,

      controller: null,

      isLoadingModal: false,
      fixPanelShow: false,
      draftLifeTime: false,
      formPeriodDate: null,
      minDate: new Date(),
      getParamsCalendar: {},
      reservationPoints: false,
      /**
       * массив неактивных дат
       * @type {Array}
       * @default false
       */
      reservationPointsDop: [],

      isLoadingVideo: true,

      routeQueryGuid: this.$route.path.split("/").at(-1),

      btnText: 'Подать заявку',
    }
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  methods: {
    updateActiveIndex(swiperInstance) {
      this.realIndex = swiperInstance.realIndex;
      this.activeIndex = swiperInstance.activeIndex;
    },
    updateMainSwiper(swiperInstance) {
      if (this.$refs.mySwiperRent && this.$refs.mySwiperRent.swiper) {
        this.$refs.mySwiperRent.swiper.slideTo(swiperInstance.realIndex);
      }
    },
    setThumbsSwiper(swiperInstance) {
      this.thumbsSwiper = swiperInstance;
    },
    translateWord(word) {
      return translates[word] ? translates[word] : 'Значение не найдено';
    },
    getData(id) {
      this.isLoading = true;
      return this.axios
          .get(`/api/data/one-platform?id=${id}`)
          .then(response => {
            this.data = response.data;

            updateMetaTags(this.$store.dataMeta, this.data.name)

            if (this.data.lat && this.data.lon) {
              this.panoramaCoords.lat = this.data.lat;
              this.panoramaCoords.lon = this.data.lon;
            }

            if(this.data?.plans && this.data?.plans[0]?.url?.indexOf(".pdf") != -1){
              this.isPdf = true;
            }

            if(this.data.video){
              this.getVideos();
            }
            if(this.typeIf('1315826')){
              this.btnText = 'Получить консультацию';
            }
          })
          .finally(() => {
            this.isLoading = false;
            this.$nextTick(() => {
              this.scrollFixPanel();
            });
          })
          .catch(error => {
            this.showError(error);
          });
    },
    phoneShowClick() {
      this.phoneShow = true;
      this.$nextTick(() => {
        // объект, который надо выделить
        var element = this.$refs.telCopy;
        // создание выделения
        var range = document.createRange();
        range.selectNode(element);
        // удаление текущего выделения на странице
        window.getSelection().removeAllRanges();
        // выделение объекта
        window.getSelection().addRange(range);
        document.execCommand('copy');
      });
    },
    panoramaShowFun(model = false) {
      this.modalShow = true;
      //3d панарама(сюда js)
      const interval = setInterval(()=>{
        if (document.querySelector('app-map')){
          const embedded_map = document.querySelector('app-map');
          const positionsArray =
              {
                coordinates: [this.panoramaCoords.lon, this.panoramaCoords.lat],
                name: this.data.name,
                address: this.data.address
              }
          if (model === true){
            positionsArray.model = this.data.guid;
            positionsArray.orientation = {};
          } else {
            positionsArray.model = null;
            positionsArray.orientation = null;
          }

          embedded_map.position = positionsArray;
          clearInterval(interval);
        }
      },1000)
    },
    // для модалки взял из тура
    fullScreen() {
      document.querySelector(".modalTour").classList.add("fullscreen");
      document.querySelector("#fullScreen").classList.add("d-none");
      document.querySelector("#exitfullScreen").classList.remove("d-none");
    },
    exitFullScreen() {
      document.querySelector(".modalTour").classList.remove("fullscreen");
      document.querySelector("#fullScreen").classList.remove("d-none");
      document.querySelector("#exitfullScreen").classList.add("d-none");
    },
    copyLink() {
      navigator.clipboard.writeText(location.origin + this.$route.fullPath);
      this.showSuccess('', 'ссылка скопирована');
    },
    closeMs() {
      const closeEl = document.getElementById("msBlock");
      if (closeEl.style.display == "block") {
        closeEl.style.display = "none";
      }
    },
    getBoolObj(obj){
      for (var item in obj){
        if(obj[item]){
          return true;
        }
      }
      return false;
    },
    getPersonDataFun(){
      this.showFormInint = false;
      this.formParams.preSetParams["film_location"] = this.routeQueryGuid;
      this.formParams.preSetParams["film_url"] = window.location.href;
      if(this.getIsAuthorization && this.getPersonData){
        this.formParams.preSetParams["UserFLGuid"] = this.getPersonData.guid;
      }
      this.showFormInint = true;

      if(this.getCompany){
        this.formParams.preSetParams.OrgName = this.getCompany.name;
      }

    },
    // для проверки type 1315826 - скоро на платформе
    typeIf(id){
      return id == this.data?.type?.id;
    },
    // открытие формы
    showFormFun(){

      if(this.typeIf('1315826')){

        this.showForm = !this.showForm;

      } else {

        const measureGuid = this.data.measure;

        this.formParams.preSetParams.begin_date = this.formPeriodDate.start.toLocaleString('ru-RU', { year: 'numeric', month: 'numeric', day: 'numeric' }).split(', ').join(', ');
        this.formParams.preSetParams.end_date = this.formPeriodDate.end.toLocaleString('ru-RU', { year: 'numeric', month: 'numeric', day: 'numeric' }).split(', ').join(', ');
        this.formParams.preSetParams.location_name = this.data.name;
        this.formParams.preSetParams.locationAddress = this.data.address;

        this.isLoadingModal = true;

        // проверка заняты или нет эти даты
        return this.axios
            .get(`/api/data/filmplatformbookings`, {params: {End: this.formParams.preSetParams.begin_date, Begin: this.formParams.preSetParams.end_date, measureGuid: measureGuid, filmLocation: this.routeQueryGuid}, signal: this.controller.signal})
            .then(response => {
              if(response.data.records !== undefined && response.data.records.length > 0){
                this.showError(false,`Ваши даты уже заняты`, 'Выберите другие в календаре бронирования');
                this.fromPageCalendar(false, true);
              } else {
                this.showInfo(false,`Ваш черновик будет удален через ${this.draftLifeTime} мин.`, 'Пожалуйста отправьте заявку для подтверждения бронирования.', 'center_mode');
                this.showForm = !this.showForm;
              }
            })
            .catch(error => {
              this.showError(error);
              this.isLoadingModal = false;
            })
            .finally(() => {
            });

      }
    },
    loaderHide(){
      setTimeout(()=>{
        this.isLoadingModal = false;
      }, 300);
    },
    //возврашаем заголовок формы
    formTitleFun(name) {
      this.formTitle = name;
    },
    // полоска с кнопкой при скроле
    scrollFixPanel() {
      if(this.$refs?.fixPanelTriger){
        window.addEventListener("scroll", () => {
          this.pageYOffset = window.pageYOffset
          this.fixPanelShow = window.pageYOffset > this.$refs.fixPanelTriger?.offsetTop;
          if(this.fixPanelShow || this.formPeriodDate){
            document.querySelector('#site_header').classList.add('not_menu');
          } else {
            document.querySelector('#site_header').classList.remove('not_menu');
          }
        })
      }
    },
    // сброс календаря
    clearCalendar(){
      this.formPeriodDate = null;
    },
    // скрол до календаря
    scrollCalendar(){
      window.scrollTo({top: this.$refs.datepickerFormBot.getBoundingClientRect().top + window.scrollY, behavior: 'smooth'});
    },
    // загрузка данных календаря
    fromPageCalendar(item, updateDate = false) {
      if(!this.data?.type?.id || !this.typeIf('1315826')){
        if(this.$refs.calendarEl){
          this.$refs.calendarEl.classList.add("loader_calendar");
        }
        if(!updateDate){
          this.getParamsCalendar.End = this.$filters.ruDate5(new Date(item[0].year, item[0].month - 1, 1));
          this.getParamsCalendar.Begin = this.$filters.ruDate5(new Date(item[0].year, item[0].month + 1, 0));
        }
        this.getParamsCalendar.filmLocation = this.routeQueryGuid;
        return this.axios
            .get(`/api/data/filmplatformbookings`, {params: this.getParamsCalendar, signal: this.controller.signal})
            .then(response => {
              this.draftLifeTime = response.data.draftLifeTime;
              this.reservationPoints = response.data.records;
            })
            .finally(() => {
              this.$refs.calendarEl.classList.remove("loader_calendar");
            })
            .catch(error => {
              this.showError(error);
            });
      }
    },
    /**
     * получаем неактивные даты типа выходнх и передаем их в reservationPointsDop
     * @returns {Promise<void>}
     */
    getCalendarDayDisabled(){
      return this.axios
          .get(`/api/data/rent-locations/${this.$route.fullPath.split('/').at(-1)}/working-calendars`, {params: this.getParamsCalendar, signal: this.controller.signal})
          .then(response => {
            this.reservationPointsDop = response.data.records.map(event => {
              return {
                begin: event.dateFrom,
                end: event.dateTo,
                rentStatus: {
                  Name:	event.status?.name
                },
              }
            });
          })
          .catch(error => {
            this.showError(error);
          });
    },
    // получение должности и передача в форму
    getUserPosition(){
      if(this.getUser){
        return this.axios
            .get(`/api/get/trustee/persons/${this.getUser.iasGuid}`, {signal: this.controller.signal})
            .then(response => {
              if(response.data.position){
                this.formShowParams = false;
                this.formParams.preSetParams.position = response.data.position;
                this.$nextTick(() => {
                  this.formShowParams = true;
                });
              }

            })
            .catch(error => {
              this.showError(error);
            })
            .finally(() => {
            });
      }
    },
    modalBeforeFormClose(){
      this.modalBeforeFormStudents = false;
    },
    // данные о фидео
    getVideos(){
      this.isLoadingVideo = true;
      let promises = [];
      this.data.video.forEach((item, index) => {
        const url = item,
            videoCode = url.match(/\/video\/([^/?]+)/)[1];
        if(videoCode){
          const promis = this.axios.get("/api/data/rutube", {
            params:{
              id: videoCode,
            }
          })
              .then(response => {
                this.data.video[index] = {};
                this.data.video[index].preview = response.data.thumbnailUrl;
                this.data.video[index].iframe = response.data.html;
                promises.push(promis);
              })
        }
      });
      Promise.all(promises)
          .then(() => {
            this.isLoadingVideo = false;
          });
    },
    videoPause(swiper){
      const videoIframe = document.querySelector('.swiper-slide-active .swiper-video iframe');
      if(videoIframe){
        videoIframe.contentWindow.postMessage(JSON.stringify({
          type:'player:pause',
          data:{}
        }), '*');
      }
      if(swiper.slides[swiper.activeIndex].classList.contains('swiper-video-slide')){
        swiper.el.classList.add('swiper-video-arrows');
      } else {
        swiper.el.classList.remove('swiper-video-arrows');
      }
    },
    showFormIf(){
      if(this.typeIf('1315826')){
        this.showFormFun();
      } else {
        this.formPeriodDate ? this.showFormFun() : this.scrollCalendar()
      }
    }
  },
  setup() {
    return {
      modules: [FreeMode, Navigation, Thumbs]
    }
  },
  mounted() {

    this.getCalendarDayDisabled();

    if (this.$refs.thumbsSwiper) {
      this.thumbsSwiper = this.$refs.thumbsSwiper.swiper;
    }
    let map = document.createElement('script')
    map.setAttribute('src', 'https://smart.mos.ru/geodata/3dtiles/cinema_cluster/js/embedded_map.js')
    document.head.appendChild(map)
    let cesium = document.createElement('script')
    cesium.setAttribute('src', 'https://smart.mos.ru/geodata/3dtiles/cinema_cluster/js/cesium/Cesium.js')
    document.head.appendChild(cesium)

    // document.querySelector('body').classList.add('fon2');
    const pathArray = this.$route.path.split("/");
    this.getData(pathArray[pathArray.length - 1]);
    document.querySelector('.breadcrumbs').classList.add('breadc_rent_item')
    const pathArr = window.location.pathname.split("/");
    const pathArrLength = pathArr.length - 1;
    var str = "";
    for (var i = 0; i < pathArrLength; i++){
      if(pathArr[i] != ""){
        str += `/${pathArr[i]}`;
      }
    }
    this.buttonLink = str;

    this.getPersonDataFun();
    this.getUserPosition();

    this.$nextTick(() => {
      if(document.querySelector('.content_form_area.loader_ab.emailForm')){
        document.querySelector('.content_form_area.loader_ab.emailForm').remove();
      }

      // заплатка на удаление лишних блоков
      if(document.querySelectorAll('.block_wrap').length > 0){
        Array.from(document.querySelectorAll('.block_wrap')).map( item => {
          item.remove();
        })
      }

    });
  },
  computed: {
    ...mapGetters([
      'getIsAuthorization',
      'getPersonData',
      'getCompany',
      'getUser',
      'widthSite',
    ]),
    disabledDates(){
      if (this.reservationPoints && this.reservationPoints.length > 0) {
        return [
          ...this.reservationPoints.map(event => {
            if(this.getUser?.iasGuid && this.getUser?.iasGuid == event.createUser?.Guid && event.measureService?.Status?.ID && event.measureService?.Status?.ID == "6" && this.formIf){
            } else {
              return ({
                start: new Date(event.begin),
                end: new Date(event.end)
              })
            }
          }),
        ];
      }
    },
    calendarPoints() {
      if (this.reservationPoints && this.reservationPoints.length > 0) {
        return [
          {
            key: 'today',
            highlight: true,
            dates: new Date(),
          },
          ...this.reservationPoints.filter(event => event.rentStatus?.ID == '1316001').map(event => ({
            dates: {start: new Date(event.begin), end: new Date(event.end)},
            dot: {
              color: this.getUser?.iasGuid && this.getUser?.iasGuid == event.createUser?.Guid && event.measureService?.Status?.ID == "6" ?  'orange' : 'gray',
            },
            popover: {
              label: event.rentStatus.Name,
            },
            customData: event,
          })),
          ...this.reservationPointsDop.map(event => ({
            dates: {start: new Date(event.begin), end: new Date(event.end)},
            customData: event,
            content: 'highlight-content-color-red',
          })),
        ];
      } else {
        return [
          {
            key: 'today',
            highlight: true,
            dates: new Date(),
          },
          ...this.reservationPointsDop.map(event => ({
            dates: {start: new Date(event.begin), end: new Date(event.end)},
            customData: event,
            content: 'highlight-content-color-red',
          })),
        ];
      }
    },
    formIf(){
      return this.getIsAuthorization && this.getCompany
    },
  },
  watch: {
    getPersonData(val) {
      this.getPersonDataFun();
    },
    showForm(){
      if(!this.showForm){
        this.formPeriodDate = null;
        this.fromPageCalendar(false, true);
      }
    },
    formPeriodDate(){
      if (this.formIf) {
        if([...this.reservationPoints].filter(event => this.getUser?.iasGuid && event.createUser?.Guid == this.getUser?.iasGuid && new Date(this.formPeriodDate?.start).setHours(0, 0, 0, 0) == new Date(event.begin).setHours(0, 0, 0, 0) && new Date(this.formPeriodDate?.end).setHours(0, 0, 0, 0) == new Date(event.end).setHours(0, 0, 0, 0) && event.measureService?.Status?.ID == "6").length > 0){
          this.formParams.preSetParams.begin_date = this.formPeriodDate.start.toLocaleString('ru-RU', { year: 'numeric', month: 'numeric', day: 'numeric' }).split(', ').join(', ');
          this.formParams.preSetParams.end_date = this.formPeriodDate.end.toLocaleString('ru-RU', { year: 'numeric', month: 'numeric', day: 'numeric' }).split(', ').join(', ');
          this.showForm = !this.showForm;
        }
      }
    },
    getUser(){
      this.getUserPosition();
    },
  },
  created() {
    this.controller = new AbortController();
  },
  beforeUnmount() {
    // document.querySelector('body').classList.remove('fon2');
    document.querySelector('#site_header').classList.remove('not_menu');

    this.controller.abort();
    this.controller = null;
  }
}
</script>

<style lang="scss" scoped>

.sliderThumbs {
  &Thumbs {

    .swiper-slide {

      &.swiper-slide-thumb-active img {
        border: none;
      }

      &.slide_new img {
        border: 2px solid #ED850A;
      }
    }
  }
}
// заплатка на удаление лишних блоков
.block_wrap, .block_wrap.big_tabs {
  display: block !important;
}
@import '@/assets/scss/variables.scss';

.rent_item {
  padding-top: 150px;
}

.slider {
  margin-bottom: 70px;
}

.titleBlock {
  margin-bottom: 20px;
  .hasSubtit {
    margin-bottom: 12px;
  }
}

.titRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;

  h3 {
    margin-right: 20px;
    margin-bottom: 0;
  }
}

.characters {
  margin-bottom: 20px;
}

.row_marker_dot {
  gap: 20px 0;
}

/*.checkboxes {
  margin-bottom: -20px;

  .character {
    margin-bottom: 20px;
  }
}*/

.character {
  display: flex;
  margin-bottom: 16px;
  padding-right: 24px;

  span:first-child {
    margin-right: auto;
    padding-right: 24px;
  }

  span:last-child {
    min-width: 25%;
    text-align: left;
  }
}

.company_block {
  border: 2px solid $color-grey;
  padding: 32px;
  border-radius: 12px;
  display: flex;

  h3 {
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .text_m {
    margin-bottom: 24px;
  }

  .icon {
    max-width: 182px;
    margin-left: 24px;
    flex: none;
  }
  @media (max-width: 991px) {
    flex-direction: column;
    .icon{
      order: 1;
      margin-left: 0;
      margin-bottom: 12px;
    }
    .text{
      order: 2;
    }
  }
}

.formst {
  padding: 24px 32px;
  @media (max-width: 1400px) {
    padding: 24px;
  }
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.20);

  color: #ED850A;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: 0.64px;
  text-transform: uppercase;

  .title {
    margin-bottom: 24px;
  }
}

.sliderThumbs {
  margin-bottom: 60px;
}

.sliderThumbsMain {
  .swiper-slide img {
    min-height: 101%;
  }
}

.copy_text_none {
  opacity: 0;
  height: 0;
  overflow: hidden;
  z-index: -9999;
}
.image_modal{
  width: 100%;
  height: 100%;
  background-color: #000106;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.img_wrap_rent{
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  img{
    margin: -2px !important;
  }
}
@media (max-width: 991px) {
  .rent_item{
    padding-top: 100px;
    .col-md-item{
      max-width: 100%;
      flex: 0 0 100%;
    }
    .titRow{
      align-items: start;
      justify-content: start;
      flex-direction: column;
      gap: 12px;
    }
  }
}
@media (max-width: 767px) {
  .rent_item{
    padding-top: 70px;
  }
}

.fixPanel_block {
  background-color: #000106;
  padding: 16px 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  transform: translateY(100%);

  &.active {
    //transform: translateY(0);
    display: block;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    white-space: nowrap;
  }

  .dt1 {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .dt2 {
    width: 100%;
    max-width: 43%;
    margin-left: auto;
  }
  @media (max-width: 991px) {
    .dt1 {
      display: none;
    }
    .dt2 {
      margin: 0;
      max-width: 100%;
    }
  }
  @media (max-width: 767px) {
    //display: none !important;
    .container {
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }
  }
}
.company_block :deep(.color-wh a){
  color: white !important;
}
</style>

<style lang="scss">
.modal.rentModalTour {
  background-color: #000;
}
.titRow-buttons{
  display: flex;
  gap: 24px;
  @media (max-width: 767px) {
    overflow-x: auto;
    overflow: scroll;
    white-space: nowrap;
    width: 100vw;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px;
    > * {
      flex: none;
    }
  }
}
.ico_3d{
  .sp_img{
    height: 34px;
    img{
      height: 100%;
      filter: invert(100%);
    }
  }
}

.imitation_cal {
  display: flex;
  padding: 12px 40px 12px 46px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: url(@/assets/img/ico/calendar.svg) no-repeat 16px center rgba(255, 255, 255, 0.10);
  position: relative;
  .imitation_cal_close {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.datepicker_form_bot {
  padding-top: 20px;
  h4 {
    margin-bottom: 52px;
  }
}
.modal-black {
  color: red;
  .info_students{
    padding-top: .8rem;
    .div_tit {
      margin-bottom: 1.6rem;
    }
    .div_btn {
      margin-bottom: 3.2rem;
      padding-bottom: 3.2rem;
      border-bottom: 1px solid #6B7280
    }
  }
}

.howToGet {
  padding: 0;
  overflow: hidden;
}

@media(min-width: 991px){
  .card_change .tag .text{
    font-size: 1.1rem;
  }
}

</style>
