<template>
  <cl-collapse @collapsed="vis => visible = vis" :visible-start="true">
    <template v-slot:name>
      <span class="div_tit">{{ title }}</span>
      <span class="div_tit_collapse_btn">
        <template v-if="visible">
          Свернуть
        </template>
        <template v-else>
          Развернуть
        </template>
      </span>
    </template>
    <template v-slot:card>
      <div class="div_struct">
        {{ collapseText }}
      </div>
    </template>
  </cl-collapse>
</template>

<script lang="ts" setup>
import ClCollapse from "@/components/library/ClCollapse.vue";
import {Ref, ref} from "vue";

const props = defineProps<{
  title?: string,
  collapseText?: string,
}>();

let visible: Ref<boolean> = ref(false);

</script>

<style lang="scss" scoped>

</style>