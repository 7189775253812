<template>
  <container>
    <row class="trusted_persone_detail">
      <column md="12">
        <router-link to="/lk/filmProjects" class="return-src">
          <img src="/img/ico/move-left-2.svg" height="19" width="19">
          Все проекты
        </router-link>
      </column>
      <column md="12">
        <card card-type="trusted2" class="trusted2_sec" v-if="this.projectInfo !== null">
          <div class="card_mobile d-block d-md-none">
            <div class="div_tag">
              <span class="sp_all"><span class="sp1">Номер проекта {{this.projectInfo?.id}}</span></span>
            </div>
            <h3 v-if="this.projectInfo?.name">{{ this.projectInfo?.name }}</h3>
            <div class="div_tag">
              <span class="sp_all">
                  <span class="sp1">Дата изменения
                    <template v-if="this.projectInfo?.updated !== null">{{ $filters.ruDate6(new Date(this.projectInfo?.updated)) }}</template>
                    <template v-else>{{ $filters.ruDate6(new Date(this.projectInfo?.created)) }}</template>
                  </span>
                </span>
            </div>
          </div>
          <div class="td1 td-mob" v-if="this.projectInfo">
            <template v-if="this.projectInfo?.logo !== null && this.imageUrl == ''">
              <div class="div_img" @click="openFileInput">
                <img :src="this.projectInfo.logo.imageUrl" alt="">
              </div>
            </template>
            <template v-else-if="this.imageUrl !== ''">
              <div class="div_img" @click="openFileInput">
                <img :src="this.imageUrl" alt="">
              </div>
            </template>
            <template v-else>
              <div class="div_img br_wh" @click="openFileInput">
                <div class="div_empty_img">
                  <img src="/img/project_empty_img.jpg" alt="">
                </div>
                <div class="not_img" alt="Добавить фото"> <div class="text">Добавить фото</div></div>
              </div>
            </template>
            <input type="file" @change="handleFileUpload" ref="fileInput" style="display: none;">
            <div class="div_btn_save">
              <cl-button @click="uploadImage" class="btn-save">Сохранить</cl-button>
            </div>
            <div class="div_btn_cancel">
              <cl-button type="light" class="btn-cancel" @click="this.imageUrl = ''">Отменить</cl-button>
            </div>
          </div>
          <div class="td2">
            <div v-if="this.projectInfo?.id || (this.projectInfo?.updated || this.projectInfo?.created)" class="div_tag_wrapper d-none d-md-block">
              <div class="div_tag">
                <span v-if="this.projectInfo?.id" class="sp_all">
                  <span class="sp1">Номер проекта</span>
                  <span class="sp1">{{this.projectInfo.id}}</span>
                </span>
                <span v-if="this.projectInfo?.updated || this.projectInfo?.created" class="sp_all">
                  <span class="sp1">Дата изменения</span>
                  <span class="sp1">
                    <template v-if="this.projectInfo?.updated">{{ $filters.ruDate6(new Date(this.projectInfo.updated)) }}</template>
                    <template v-else>{{ $filters.ruDate6(new Date(this.projectInfo.created)) }}</template>
                  </span>
                </span>
              </div>
            </div>
            <div v-if="this.projectInfo?.name" class="div_title_wrapper d-none d-md-block">
              <h1>{{ this.projectInfo.name }}</h1>
            </div>
            <div class="div_type">
              <template v-if="this.projectInfo?.typeAudio?.name || this.projectInfo?.typeAudio?.comment">
                <template v-if="this.projectInfo?.typeAudio?.comment">
                  {{ this.projectInfo.typeAudio.comment }}
                </template>
                <template v-else>
                  {{ this.projectInfo.typeAudio.name }}
                </template>
              </template>
              <template v-if="(this.projectInfo?.typeAudio?.name || this.projectInfo?.typeAudio?.comment) && (this.projectInfo?.formatAudio?.name || this.projectInfo?.formatAudio?.comment)">&nbsp;/&nbsp;</template>
              <template v-if="this.projectInfo?.formatAudio?.name || this.projectInfo?.formatAudio?.comment">
                <template v-if="this.projectInfo?.formatAudio?.comment">
                  {{ this.projectInfo.formatAudio.comment }}
                </template>
                <template v-else>
                  {{ this.projectInfo.formatAudio.name }}
                </template>
              </template>
            </div>
            <div class="div_type div_application_length">
              <span class="sp_all">
                <span class="sp1">Кол-во заявок:</span>
                <router-link :to="{path: `/lk/filmProjects/project/${this.projectInfo.guid}`, query: {scrollTo: 'applications'}}" @click="(event) => event.stopPropagation()" v-if="this.totalApplications" class="sp_link">
                  {{ this.totalApplications }}
                </router-link>
                <span v-else>...</span>
              </span>
            </div>
            <div class="div_type div_files_state">
              <div class="sp_all">
                <span class="sp1">УНФ</span>
                <span v-if="this.projectInfo?.nationalFilm">{{ this.projectInfo.nationalFilm }}</span>
                <template v-if="this.projectInfo?.nationalFilm && this.projectInfo?.natFilmCertificate?.[0]?.url">
                  <svg class="svg-icon" width="24" height="24" stroke="#10B981">
                    <use xlink:href="/svg/icons.svg#check"></use>
                  </svg>
                </template>
                <template v-else>
                  <svg class="svg-icon" width="24" height="24" stroke="#F43F5E" >
                    <use xlink:href="/svg/icons.svg#close"></use>
                  </svg>
                </template>
              </div>
              <div class="sp_all">
                <span class="sp1">ФСО</span>
                <template v-if="this.projectInfo?.isFso && this.projectInfo?.fileFso?.[0]?.url">
                  <span>{{ this.projectInfo.fileFso[0].name }}</span>
                  <svg class="svg-icon" width="24" height="24" stroke="#10B981">
                    <use xlink:href="/svg/icons.svg#check"></use>
                  </svg>
                </template>
                <template v-else>
                  <svg class="svg-icon" width="24" height="24" stroke="#F43F5E" >
                    <use xlink:href="/svg/icons.svg#close"></use>
                  </svg>
                </template>
              </div>
              <div class="sp_all">
                <span class="sp1">Гарантийное письмо</span>
                <template v-if="this.projectInfo?.letters?.[0]?.url">
                  <span>{{this.projectInfo.letters[0].name}}</span>
                  <svg class="svg-icon" width="24" height="24" stroke="#10B981">
                    <use xlink:href="/svg/icons.svg#check"></use>
                  </svg>
                </template>
                <template v-else>
                  <svg class="svg-icon" width="24" height="24" stroke="#F43F5E" >
                    <use xlink:href="/svg/icons.svg#close"></use>
                  </svg>
                </template>
              </div>
            </div>
          </div>
        </card>
      </column>
    </row>
    <ms-form class="formEditProjects" :urlData="`/api/update/film/projects/${$route.params.post_id3}`" :submitName="'Сохранить'" :btn-submit-modal="true" :btnCloseModal="false" @closeModal="modalProjectsEditShow = false" @ok="okProjects"/>
    <cl-modal v-model="modalProjectsOk" modal-type="center" :close-fon-active="false" :close-none="true">
      <template v-slot:header>
        <div class="success_block">
          <img src="/img/ico/check-circle-2.svg" alt="">
          <div>{{ `Проект ${modalProjectsName} успешно сохранен!`}}</div>
        </div>
      </template>
      <template v-slot:footer>
        <a href="/lk/filmProjects" class="btn btn-default m-0-auto">К списку проектов</a>
      </template>
    </cl-modal>
  </container>
</template>

<script>

import Card from "@/components/library/Card.vue";
import MsForm from "@/components/forms/MsForm.vue";
import {mapGetters} from "vuex";
import ClModal from "@/components/library/ClModal.vue";
import ClButton from "@/components/library/ClButton.vue";
import {Base64} from "js-base64";

export default {
  name: "filmProjects",
  components: {ClButton, ClModal, MsForm, Card},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data(){
    return{
      projects: null,
      modalProjectsAddShow: false,
      modalProjectsEditShow: false,
      modalProjectsDeleteShow: false,
      activeProjects: null,
      loaderProjects: true,
      modalProjectsOk: false,
      modalProjectsName: null,
      projectInfo: null,
      id: this.$route.params.post_id3,
      image: null,
      selectedFile: null,
      imageUrl: '',

      showLoader: true,
      data: false,
      getParams: {
        sortExpression:"created DESC",
        filmProjectGuids: this.$route.params.post_id3,
        pageSize : 1,
        page: 1
      },
      applications: {},
      totalApplications: null,
      isLoadingMore: false,
    }
  },
  computed: {
    ...mapGetters([
      'getUser'
    ]),
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result.split(",")[1];
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(file);
      this.getProjectInfo(this.id);
    },

    openFileInput() {
      this.$refs.fileInput.click();
    },

    uploadImage() {
      let data = new FormData();
      data.append("FileName", 'Имя файла');
      data.append("Logo", this.image);
      this.axios
          .post(`/api/add/film-projects/${this.id}/logo`, data)
          .then(response => {
            this.showInfo(false,`Логотип проекта успешно обновлен`, '');
          })
          .catch(error => {
            console.log('error')
          })
          .finally(() => {
            console.log('finally')
          });
    },

    getProjectInfo(id){
      this.loaderProjects = true;
      this.axios
          .get(`/api/get/one/film/project/${id}`)
          .then(response => {
            this.projectInfo = response.data;
          })
          .catch(error => {
            this.showError(error);
          })
          .finally(() => {
            this.loaderProjects = false;
          });
    },

    okProjects(){
      this.modalProjectsName = document.getElementById('film_projects_name').value;
      this.modalProjectsOk = true;
    },

    /**
     * Функция для получения данных о заявках проекта
     */
    getApplications(isLoadingMore) {
      this.showLoader = true;
      this.axios
          .get(`/api/get/measure-services`, {params: this.getParams})
          .then(response => {
            if (isLoadingMore) {
              this.applications = [...this.applications, ...response.data.records];
              this.totalApplications = response.data.total;
            } else {
              this.data = response.data;
              this.applications = response.data.records;
              this.totalApplications = response.data.total;
              this.showLoader = false;

            }
            this.isLoadingMore = false;
          })
          .catch(error => {
            this.showError(error);
          });
    },
  },
  mounted(){
    this.getProjectInfo(this.id);
    this.getApplications(this.isLoadingMore);
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/forms/form_edit_project";

.trusted_persone_detail {
  margin-bottom: 3.2rem;

  .return-src {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    cursor: pointer;

    img {
      width: 1.9rem;
      height: 1.9rem;
    }
  }
}

.card-trusted2 {
  border-radius: 0.6rem;
  border: 0.2rem solid rgba(255, 255, 255, 0.02);
  padding: 1.6rem;
  position: relative;
  cursor: auto;

  :deep(.card-holder) {
    flex-direction: row;

    .td1 {
      width: auto;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 0;

      .div_img {
        height: 20.2rem;
        aspect-ratio: 3 / 4;
        border-radius: 0.8rem;
        overflow: hidden;
        cursor: pointer;
        min-width: auto;
        display: flex;
        flex-direction: column;
        margin-bottom: 1.2rem;

        &.br_wh {
          border: 0.1rem solid white;
        }

        .div_empty_img {
          max-height: calc(100% - 3.6rem);
        }

        img, .not_img {
          width: 100%;
        }

        .not_img {
          flex: none;
          background: white;
          color: black;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1.125;
          letter-spacing: 0.02em;
          text-align: center;
          padding: 0.9rem;
        }
      }

      .div_btn_save, .div_btn_cancel {
        width: 100%;

        .btn {
          width: 100%;
          min-height: 3.6rem;
          padding: 0.9rem;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1.125;
          letter-spacing: 0.02em;
        }
      }

      .div_btn_save {
        margin-bottom: 0.8rem;
      }

      .div_btn_cancel {
        .btn {
          border: 1px solid transparent;
        }
      }
    }

    .td2, .card_mobile {
      .div_tag {
        font-size: 1.2rem;
        padding-right: 0;
        gap: 1.6rem;
        margin-bottom: 0.4rem;
        display: flex;

        .sp_all {
          display: flex;
          gap: 0.8rem;
        }

        .sp1 {
          margin: 0;
        }
      }

      .div_title_wrapper {
        h1 {
          font-size: 3.2rem;
          font-weight: 500;
          line-height: 1.5;
          letter-spacing: 0.02em;
          margin-bottom: 0.8rem;
        }
      }

      .div_application_length {
        a {
          text-decoration: underline;
          text-decoration-color: rgba(237, 133, 10, 1);
        }
      }

      .div_files_state {
        min-height: 5.6rem;

        .sp_all .svg-icon {
          height: 2.4rem;
          width: 2.4rem;
        }
      }
    }
  }
  
  @media (max-width: 767px) {
    :deep(.card-holder) {
      flex-direction: column;
      gap: 1.6rem;

      .td1 {
        width: fit-content;
        .div_img {
          height: 24rem;
          min-width: 14.6rem;
        }
      }

      .td2 {
        .div_files_state {
          flex-direction: column;
        }
      }
    }
  }
}
</style>