<template>
  <div v-if="dataInfo && Object.keys(dataInfo).length > 0" class="block-zima-wrapper">
    <div class="block-zima">
      <div class="backgr_image">
        <div v-if="(dataInfo.imgDesktop && widthSite > 991) || (dataInfo.imgTablet && widthSite <= 991 && widthSite > 767) || (dataInfo.imgMobile && widthSite <= 767)" class="images">
          <img v-if="dataInfo.imgDesktop && widthSite > 991" class="desktop_img" :src="getImage(dataInfo.imgDesktop, 'banner')" alt="">
          <img v-if="dataInfo.imgTablet && widthSite <= 991 && widthSite > 767" class="tablet_img" :src="getImage(dataInfo.imgTablet, 'banner')" alt="">
          <img v-if="dataInfo.imgMobile && widthSite <= 767" class="mobile_img" :src="getImage(dataInfo.imgMobile, 'banner')" alt="">
        </div>
      </div>
      <div class="block_cont">
        <container>
          <div v-if="dataInfo.blockTitle" class="h1_text" v-html="dataInfo.blockTitle.value"></div>
          <div v-if="dataInfo.blockDesc" class="desc_text" v-html="dataInfo.blockDesc.value"></div>
          <div v-if="dataInfo.btnName && (dataInfo.btnSrc || dataInfo.btnModalLink)" class="div_btn">
            <cl-button v-if="dataInfo.btnSrc" link :href="dataInfo.btnSrc.value" @click="v_ym(dataInfo.yandexMetrikaTarget?.value)" class="btn-middle">
              {{dataInfo.btnName.value}}
            </cl-button>
            <cl-button v-else-if="dataInfo.btnModalLink" @click="v_ym(dataInfo.yandexMetrikaTarget?.value); showModalIframe(dataInfo.btnModalLink.value);" class="btn-middle">
              {{dataInfo.btnName.value}}
            </cl-button>
          </div>
        </container>
      </div>
      <div v-if="dataInfo.imgFigures" class="float_figures">
        <container>
          <img :src="getImage(dataInfo.imgFigures, 'fullHd')" alt="">
        </container>
      </div>
    </div>

    <cl-modal modal-type="iframe-excursions" v-model="isShowModalIframe">
      <loader
          :animation-duration="3000"
          :size="60"
          :color="'#ED850A'"
          v-if="isShowModalIframeLoader"
      ></loader>
      <iframe v-show="!isShowModalIframeLoader" class="iframe-excursions" ref="iframeModal" :src="iframeModalLink"/>
    </cl-modal>
  </div>
</template>

<script setup lang="ts">
import {IDataInfoProps} from "@/interface/props/IDataInfoProps";
import ClButton from "@/components/library/ClButton.vue";
import {computed, ComputedRef, nextTick, ref, Ref} from "vue";
import store from "@/store";
import Loader from "@/components/library/Loader.vue";
import ClModal from "@/components/library/ClModal.vue";

/**
 * Пропы
 */
const props = defineProps<IDataInfoProps>();

/**
 * Состояние открытой/закрытой модалки с iframe
 */
let isShowModalIframe: Ref<boolean> = ref(false);

/**
 * Состояние лоадера в модалке
 */
let isShowModalIframeLoader: Ref<boolean> = ref(true);

/**
 * Модалка с iframe
 */
const iframeModal: Ref<HTMLElement | null> = ref(null);

/**
 * Ссылка на виджет для iframe
 */
let iframeModalLink: Ref<string | undefined> = ref(undefined);

/**
 * Ширина экрана
 */
const widthSite: ComputedRef<any> = computed(() => {return store.getters.widthSite});

/**
 * Функция открытия модалки с iframe
 * @param {string} iframeLink - ссылка на виджет для iframe
 */
function showModalIframe(iframeLink?: string): void {
  iframeModalLink.value = iframeLink;
  isShowModalIframe.value = true;
  nextTick(()=>{
    if(iframeModal.value){
      iframeModal.value.addEventListener('load', () => {
        isShowModalIframeLoader.value = false;
      });
    } else {
      isShowModalIframeLoader.value = false;
    }
  });
}

</script>

<style lang="scss" scoped>
  .block-zima-wrapper {
    padding-bottom: 6rem;
  }

  .block-zima{
    margin-top: 5.2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    height: 93.6rem;
    position: relative;
    overflow: hidden;
    @media(max-width: 1200px){
      height: 768px;
    }
    @media(max-width: 991px){
      height: 960px;
    }
    @media(max-width: 767px){
      height: calc(100vh - 5.2rem);
      min-height: 60rem;
    }
    .images{
      position: absolute;
      top: 0;
      left: 0;
      object-fit: contain;
      width: 100%;
      border-radius: 0 0 5rem 5rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      img{
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      .desktop_img{
        @media(max-width: 992px){
          display: none;
        }
      }
      .tablet_img{
        display: none;
        @media(max-width: 991px) and (min-width: 768px){
          display: block;
        }
      }
      .mobile_img{
        display: none;
        @media(max-width: 767px){
          display: block;
        }
      }
      @media(max-width: 767px){
        border-radius: 0 0 3.2rem 3.2rem;
      }
    }
    .block_cont{
      z-index: 10;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      .container{
        @media(max-width: 991px){
          padding-bottom: 180px;
        }
        @media (max-width: 767px) {
          text-align: center;
        }
      }
      .h1_text{
        font-family: Playfair Display;
        font-size: 14rem;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0.01em;
        margin-bottom: 1.6rem;
        @media(max-width: 1520px){
          font-size: 120px;
        }
        @media(max-width: 1200px){
          font-size: 100px;
        }
        @media(max-width: 991px){
          font-size: 80px;
        }
        @media(max-width: 767px){
          font-size: 4.3rem;
          width: calc(100vw);
          margin-left: -1.6rem;
        }
        > span{
          white-space: unset !important;
        }
      }
      .desc_text{
        max-width: 59.5rem;
        font-size: 24px;
        line-height: 150%;
        margin-bottom: 2.4rem;

        :deep(p) {
          margin-bottom: 0.8rem;

          &:last-child {
            margin-bottom: 0;
          }
        }

        @media(max-width: 1200px){
          max-width: 480px;
        }
        @media(max-width: 767px){
          font-size: 1.8rem;
          margin: 0 auto 2.4rem auto;
        }
      }
    }
    .float_figures{
      position: absolute;
      z-index: 4;
      bottom: 0;
      width: 100%;
      .container{
        position: relative;
        max-width: 1600px;
        @media(max-width: 767px){
          display: flex;
          justify-content: center;
        }
      }
      img{
        position: absolute;
        right: -12rem;
        bottom: 0;
        max-width: 1080px;
        @media(max-width: 1520px){
          max-width: 930px;
        }
        @media(max-width: 1350px){
          max-width: 730px;
        }
        @media(max-width: 991px){
          right: unset;
        }
        @media(max-width: 767px){
          max-width: 560px;
          margin-right: 5rem;
        }
      }
    }
  }
</style>