<template>
  <teleport to="#site_header_td2">
    <nav v-if="dataInfo && dataInfo.menuLevel1 && dataInfo.menuLevel1.length" class="menu_cat">
      <template v-for="(menuLevel1, index) in dataInfo.menuLevel1">
        <div
            class="sp_el"
            @click="menuLevel1Click(index)"
            :class="{'active': (getCategoryPeople == 'tabCategoryPeople' + index && $route.fullPath === '/') || (index === activeLevelMenu && $route.fullPath !== '/')}"
        >
          <div class="sp_el_a"
               :class="{'cur_poin': (getCategoryPeople != 'tabCategoryPeople' + index && $route.fullPath === '/') }"
          >{{ menuLevel1.title?.value }}</div>
          <div class="dop_menu_wrap" v-if="menuLevel1.menuLevel2 && menuLevel1.menuLevel2.length">
            <div class="dop_menu">
              <template v-for="menuLevel2 of menuLevel1.menuLevel2">
                <div class="dop_menu_level">
                  <div class="dop_menu_level_name">
                    <router-link
                        v-if="menuLevel2.url"
                        :to="menuLevel2.url?.value"
                    >{{ menuLevel2.title?.value }}
                    </router-link>
                    <template v-else>{{ menuLevel2.title?.value }}</template>
                  </div>
                  <div v-if="menuLevel2.menuLevel3 && menuLevel2.menuLevel3.length" class="dop_menu_level2">
                    <template v-for="menuLevel3 of menuLevel2.menuLevel3">
                      <div>
                        <router-link
                            v-if="menuLevel3.url"
                            :to="menuLevel3.url?.value"
                        >{{ menuLevel3.title?.value }}
                        </router-link>
                        <template v-else>{{ menuLevel3.title?.value }}</template>
                      </div>
                    </template>
                  </div>
                </div>

              </template>
            </div>
          </div>
        </div>
      </template>

    </nav>
  </teleport>
  <teleport to="#site_header_td3">
    <div class="menu_btn_mob" @click="menuToggle">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </teleport>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'headerMenuCategory',
  data() {
    return {
      activeLevelMenu: 0
    }
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    }
  },
  computed: {
    ...mapGetters([
      'getCategoryPeople',
      'widthSite',
      'getIsAuthorization',
      'getIsCompany'
    ])
  },
  methods: {
    /**
     * Функция, которая возвращает индекс одного из элементов меню первого уровня (который необходимо выделить) в зависимости от подменю и авторизации пользователя
     * @param {object} data - данные из админки
     * @param {string} targetUrl - ссылка на текущую страницу
     * @return {number} - индекс элемента который необходимо выделить
     */
    findMenuLevel1IndexWithUrl(data, targetUrl) {
      // Итерируем по каждому элементу в menuLevel1
      for (let i = 0; i < data.menuLevel1.length; i++) {
        let menuLevel1Item = data.menuLevel1[i];

        // Проверяем наличие menuLevel2 и итерируем по нему
        if (menuLevel1Item.menuLevel2) {
          for (let menuLevel2Item of menuLevel1Item.menuLevel2) {

            // Проверяем наличие и итерируем по menuLevel3
            if (menuLevel2Item.menuLevel3) {
              for (let menuLevel3Item of menuLevel2Item.menuLevel3) {
                if (menuLevel3Item.url && menuLevel3Item.url.value.indexOf(targetUrl) != -1) {
                  return i; // Возвращаем индекс, если найдено соответствие
                }
              }
            }

            // Проверяем текущий уровень menuLevel2
            if (menuLevel2Item.url && menuLevel2Item.url.value.indexOf(targetUrl) != -1){
              return i; // Возвращаем индекс, если найдено соответствие
            }
          }
        }

        // Проверяем текущий уровень menuLevel1
        if (menuLevel1Item.url && menuLevel1Item.url.value.indexOf(targetUrl) != -1) {
          return i; // Возвращаем индекс, если найдено соответствие
        }
      }

      // Проверяем что пользователь авторизован
      if(this.getIsAuthorization) {
        // Проверяем что пользователь авторизован под компанией или под физиком
        if(this.getIsCompany) {
          // Проверяем что переданный индекс компании является целым числом больше 0 и меньше или равно количества элементов в шапке
          const compIndexNum = Number(data.companyIndex?.value);
          if(Number.isInteger(compIndexNum) && compIndexNum > 0 && compIndexNum <= data.menuLevel1.length) {
            return compIndexNum - 1;
          }
        } else {
          // Проверяем что переданный индекс физика является целым числом больше 0 и меньше или равно количества элементов в шапке
          const physIndexNum = Number(data.physicalIndex?.value);
          if(Number.isInteger(physIndexNum) && physIndexNum > 0 && physIndexNum <= data.menuLevel1.length) {
            return physIndexNum - 1;
          }
        }
      }

      return 0; // Возвращаем 0, если соответствий не найдено
    },
    /**
     * Функция переключения меню в шапке сайта
     * @param {number} indexMenu - индекс меню
     */
    menuLevel1Click(indexMenu){
      if(this.$route.fullPath === '/'){
        this.$store.commit('updateCategoryPeople', 'tabCategoryPeople' + indexMenu)
      }
      if(this.widthSite <= 767){
        this.activeLevelMenu = indexMenu;
      }
    },
    menuToggle(){
      document.body.classList.toggle("menu_mob_open");
      if(document.querySelector('.menu_cat')){
        document.querySelector('.menu_cat').classList.toggle("active_menu");
      }
    }
  },
  mounted() {
    //document.body.classList.add("body_container");
    this.activeLevelMenu = this.findMenuLevel1IndexWithUrl(this.dataInfo, this.$route.fullPath);
  },
  beforeUnmount() {
    //document.body.classList.remove("body_container");

    document.body.classList.remove("menu_mob_open");
    if(document.querySelector('.menu_cat')){
      document.querySelector('.menu_cat').classList.remove("active_menu");
    }

  }
}
</script>

<style lang="scss" scoped>
.menu_cat {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2.4rem;
  font-weight: 500;

  @media (min-width: 992px) {
    font-size: 2.1rem;
  }

  .sp_el {
    .sp_el_a {
      display: flex;
      align-items: center;
      gap: .8rem;
      padding: .1rem .8rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      font-size: 31px;
      &.cur_poin {
        cursor: pointer;
      }
      @media (max-width: 1080px) {
        font-size: 24px;
      }
      @media (max-width: 991px) {
        font-size: 18px;
      }
    }
    &:hover {
      @media (min-width: 768px) {
        .sp_el_a {
          background: rgba(244, 63, 94, 0.1);
          border-color: #F43F5E;
        }
        .dop_menu_wrap {
          display: block;
        }
      }
    }
  }

  .active {
    .sp_el_a {
      color: #F43F5E;
      transition: color 0.5s ease;
      &:before {
        content: "";
        width: .8rem;
        height: .8rem;
        border-radius: 100%;
        display: block;
        background: #F43F5E;
        animation: scaleAnimation 1.4s infinite alternate;
      }

      @keyframes scaleAnimation {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(1.8);
        }
      }
    }

  }

  .dop_menu_wrap {
    display: none;
    position: absolute;
    top: 2.5rem;
    left: 0;
    min-width: 55.6rem;
    max-width: 90rem;
    padding-top: 3rem;
    cursor: default;
    z-index: 1;

    opacity: 1;
    @media (min-width: 768px) {
      transition: opacity 0.3s ease-out, display 0.3s ease-out allow-discrete;
      @starting-style {
        opacity: 0;
      }
      &:hover {
        display: block;
      }
    }
  }

  .dop_menu {
    padding: 3.2rem 3.2rem 7.4rem 3.2rem;
    gap: 2.4rem;
    border-radius: 1.6rem;
    //display: flex;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(3,auto);
    background: #0C0C0ECC;
    backdrop-filter: blur(100px);
    .dop_menu_level {
      display: flex;
      flex-direction: column;
      gap: .8rem;
      //min-width: 25rem;

      .dop_menu_level_name {
        color: #6B7280;

        a {
          color: #6B7280;

          &:hover {
            color: #6B7280;
          }
        }
      }
    }

    .dop_menu_level2 {
      display: flex;
      flex-direction: column;
      gap: .8rem
    }
  }
  @media (max-width: 991px) {
    gap: .4rem;
    .dop_menu {
      grid-template-columns: repeat(2,auto);
    }
  }
  @media (max-width: 767px) {
    display: none;
    &.active_menu {
      position: fixed;
      top: 50px;
      left: 0;
      right: 0;
      padding: 40px 16px;
      z-index: 99999;
      display: flex;
      bottom: 0;
      min-height: calc(100vh - 50px);
      background: rgba(12, 12, 14, 1);
      //backdrop-filter: blur(100px);
      flex-wrap: nowrap;
      gap: 14px;
      align-items: flex-start;

      .sp_el {
        .sp_el_a {
          background: none;
          border: none;
          padding: 0;
        }
        &.active {
          .dop_menu_wrap {
            top: 70px;
            overflow-y: auto;
            opacity: 1;
            display: block;
            height: calc(100vh - 125px);
            min-width: 0px;
            padding: 16px;
            .dop_menu {
              padding: 0;
              gap: 2.4rem;
              border-radius: 0;
              display: flex;
              flex-wrap: wrap;
              background: none;
              backdrop-filter: none;
            }
          }
        }
      }

    }

  }
}

</style>
<style lang="scss">
body {
  .menu_btn_mob {
    display: none;
    order: 100;
    height: 24px;
    width: 18px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    > div {
      width: 18px;
      height: 2px;
      background: #ffffff;
      border-radius: 1px;
      margin-bottom: 0.5rem;
      transition: transform 0.3s ease;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @media (max-width: 767px) {
    .menu_btn_mob {
      display: flex;
    }
    &.menu_mob_open {
      > .container-wrapper {
        overflow: hidden;
        height: 100vh;
      }
      .menu_btn_mob {
        opacity: 1;
        > div {
          width: 18px;
          position: absolute;
          top: 40%;
          left: 0%;
        }
        > div:first-child {
          transform: rotate(-135deg);
        }
        > div:nth-child(2) {
          display: none;
        }
        > div:last-child {
          transform: rotate(135deg);
        }
      }
    }
  }
}
</style>