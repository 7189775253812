<template>
  <div ref="refComponent">
    <container>
      <row class="trusted_persone_detail">
        <column md="12">
          <router-link to="/lk/filmProjects" class="return-src">
            <img src="/img/ico/move-left-2.svg" height="19" width="19">
            Все проекты
          </router-link>
        </column>
        <template v-if="this.projectInfo && !loaderProjects && !showLoader">
          <column md="12">
            <card card-type="trusted2" class="trusted2_sec">
              <div class="card_mobile d-block d-md-none">
                <div class="div_tag">
                  <span class="sp_all"><span class="sp1">Номер проекта {{this.projectInfo?.id}}</span></span>
                </div>
                <h1 v-if="this.projectInfo?.name">{{ this.projectInfo?.name }}</h1>
                <div class="div_tag">
              <span class="sp_all">
                  <span class="sp1">Дата изменения
                    <template v-if="this.projectInfo?.updated !== null">{{ $filters.ruDate6(new Date(this.projectInfo?.updated)) }}</template>
                    <template v-else>{{ $filters.ruDate6(new Date(this.projectInfo?.created)) }}</template>
                  </span>
                </span>
                </div>
              </div>
              <div class="td1 not_img">
                <template v-if="this.projectInfo?.logo !== null && this.imageUrl !== ''">
                  <img :src="this.projectInfo.logo.imageUrl" alt="">
                </template>
                <template v-else>
                  <img class="not_img" src="/img/project_empty_img.jpg" alt=""/>
                </template>
              </div>
              <div class="td2">
                <div class="div_tag d-none d-md-block">
                  <span class="sp_all"><span class="sp1">Номер проекта {{this.projectInfo?.id}}</span></span>
                  <span class="sp_all">
                  <span class="sp1">Дата изменения
                    <template v-if="this.projectInfo?.updated !== null">{{ $filters.ruDate6(new Date(this.projectInfo?.updated)) }}</template>
                    <template v-else>{{ $filters.ruDate6(new Date(this.projectInfo?.created)) }}</template>
                  </span>
                </span>
                </div>
                <h1 class="d-none d-md-block" v-if="this.projectInfo?.name">{{ this.projectInfo?.name }}</h1>
                <div class="div_type">
                  <template v-if="this.projectInfo?.typeAudio?.name || this.projectInfo?.typeAudio?.comment">
                    <template v-if="this.projectInfo?.typeAudio?.comment">
                      {{ this.projectInfo.typeAudio.comment }}
                    </template>
                    <template v-else>
                      {{ this.projectInfo.typeAudio.name }}
                    </template>
                  </template>
                  <template v-if="(this.projectInfo?.typeAudio?.name || this.projectInfo?.typeAudio?.comment) && (this.projectInfo?.formatAudio?.name || this.projectInfo?.formatAudio?.comment)">&nbsp;/&nbsp;</template>
                  <template v-if="this.projectInfo?.formatAudio?.name || this.projectInfo?.formatAudio?.comment">
                    <template v-if="this.projectInfo?.formatAudio?.comment">
                      {{ this.projectInfo.formatAudio.comment }}
                    </template>
                    <template v-else>
                      {{ this.projectInfo.formatAudio.name }}
                    </template>
                  </template>
                </div>
                <div class="div_type" v-if="this.totalApplications !== null">
                <span class="sp_all">
                  <span class="sp1">Кол-во заявок:</span>
                  <span class="sp_link" @click="scrollToApplications()">{{ this.totalApplications }}</span>
                </span>
                </div>
                <div class="div_type div_type_md_flex_column">
                  <div class="sp_all" :class="{'sp_all_md_flex_column': this.projectInfo?.nationalFilm}">
                    <span class="sp1">УНФ</span>
                    <div v-if="this.projectInfo?.nationalFilm" class="div_type_isFile">
                      <span>{{ this.projectInfo.nationalFilm }}</span>
                      <template v-if="this.projectInfo?.natFilmCertificate?.[0]?.url">
                        <svg class="svg-icon" width="24" height="24" stroke="#10B981">
                          <use xlink:href="/svg/icons.svg#check"></use>
                        </svg>
                        <a :download="this.projectInfo.natFilmCertificate[0].name ? this.projectInfo.natFilmCertificate[0].name : 'file'" :href="this.projectInfo.natFilmCertificate[0].url">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="#ED850A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7 10L12 15L17 10" stroke="#ED850A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 15V3" stroke="#ED850A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </a>
                      </template>
                      <template v-else>
                        <svg class="svg-icon" width="24" height="24" stroke="#F43F5E" >
                          <use xlink:href="/svg/icons.svg#close"></use>
                        </svg>
                      </template>
                    </div>
                    <template v-else>
                      <svg class="svg-icon" width="24" height="24" stroke="#F43F5E" >
                        <use xlink:href="/svg/icons.svg#close"></use>
                      </svg>
                    </template>
                  </div>
                  <div class="sp_all" :class="{'sp_all_md_flex_column': this.projectInfo?.isFso && this.projectInfo?.fileFso?.[0]?.url}">
                    <span class="sp1">ФСО</span>
                    <div v-if="this.projectInfo?.isFso && this.projectInfo?.fileFso?.[0]?.url" class="div_type_isFile">
                      <span>{{ this.projectInfo.fileFso[0].name }}</span>
                      <svg class="svg-icon" width="24" height="24" stroke="#10B981">
                        <use xlink:href="/svg/icons.svg#check"></use>
                      </svg>
                      <a :download="this.projectInfo.fileFso[0].name ? this.projectInfo.fileFso[0].name : 'file'" :href="this.projectInfo.fileFso[0].url">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="#ED850A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M7 10L12 15L17 10" stroke="#ED850A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M12 15V3" stroke="#ED850A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </a>
                    </div>
                    <template v-else>
                      <svg class="svg-icon" width="24" height="24" stroke="#F43F5E" >
                        <use xlink:href="/svg/icons.svg#close"></use>
                      </svg>
                    </template>
                  </div>
                  <div class="sp_all" :class="{'sp_all_md_flex_column': this.projectInfo?.letters?.[0]?.url}">
                    <span class="sp1">Гарантийное письмо</span>
                    <div v-if="this.projectInfo?.letters?.[0]?.url" class="div_type_isFile">
                      <span>{{projectInfo.letters[0].name}}</span>
                      <svg class="svg-icon" width="24" height="24" stroke="#10B981">
                        <use xlink:href="/svg/icons.svg#check"></use>
                      </svg>
                      <a :download="this.projectInfo.letters[0].name ? this.projectInfo.letters[0].name : 'file'" :href="this.projectInfo.letters[0].url">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="#ED850A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M7 10L12 15L17 10" stroke="#ED850A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M12 15V3" stroke="#ED850A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </a>
                    </div>
                    <template v-else>
                      <svg class="svg-icon" width="24" height="24" stroke="#F43F5E" >
                        <use xlink:href="/svg/icons.svg#close"></use>
                      </svg>
                    </template>
                  </div>
                </div>
                <Popper
                    class="div_buttons"
                >
                  <div class="div_buttons_ev">
                    <svg class="svg-icon" width="24" height="24" stroke="#ffffff">
                      <use xlink:href="/svg/icons.svg#button"></use>
                    </svg>
                  </div>
                  <template #content>
                    <div class="div_buttons_mod">
                      <div>
                        <cl-button class="btn-op"  @click="$router.push(`/lk/filmProjects/project_print/${this.$route.params.post_id3}`)">
                          <template v-slot:before>
                            <svg class="svg-icon" width="24" height="24" stroke="#ffffff">
                              <use xlink:href="/svg/icons.svg#file-text"></use>
                            </svg>
                          </template>
                          Скачать PDF
                        </cl-button>
                      </div>
                      <div>
                        <cl-button class="btn-op" @click="$router.push(`/lk/filmProjects/editProject/${this.$route.params.post_id3}`)">
                          <template v-slot:before>
                            <svg class="svg-icon" width="24" height="24" stroke="#ffffff">
                              <use xlink:href="/svg/icons.svg#pensil"></use>
                            </svg>
                          </template>
                          Редактировать</cl-button>
                      </div>
                      <div>
                        <cl-button class="btn-op" @click="modalProjectsDeleteShow = !modalProjectsDeleteShow; activeProjects = {'guid': this.projectInfo.guid, 'name': this.projectInfo.name}">
                          <template v-slot:before>
                            <svg class="svg-icon" width="24" height="24" stroke="#ffffff" >
                              <use xlink:href="/svg/icons.svg#trash"></use>
                            </svg>
                          </template>
                          Удалить</cl-button>
                      </div>
                    </div>
                  </template>
                </Popper>
              </div>
            </card>
          </column>
          <column md="12">
            <card card-type="trusted2" class="trusted2_sec">
              <div class="td2">
                <div class="div_tit">Данные проекта</div>
                <div class="div_data">
                  <div class="div_item" v-if="this.projectInfo !== null && this.projectInfo?.estReleaseDate">
                    <div class="div_title">
                      Предположительная дата релиза
                    </div>
                    <div class="div_content">
                      {{ $filters.ruDate5(new Date(this.projectInfo?.estReleaseDate)) }}
                    </div>
                  </div>
                  <div class="div_item" v-if="this.projectInfo !== null && this.projectInfo?.country">
                    <div class="div_title">
                      Страна - производитель проекта
                    </div>
                    <div class="div_content">
                      {{ this.projectInfo?.country }}
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </column>
          <column md="12">
            <card card-type="trusted2" class="trusted2_sec">
              <div class="td2">
                <div class="div_tit">Параметры проекта</div>
                <div class="div_data">
                  <div class="div_item" v-if="this.projectInfo !== null && this.projectInfo?.typeRental">
                    <div class="div_title">
                      Вид проката / показа*
                    </div>
                    <div class="div_content">
                      <template v-if="this.projectInfo?.typeRental?.comment">
                        {{ this.projectInfo?.typeRental.comment }}
                      </template>
                      <template v-else>
                        {{ this.projectInfo?.typeRental.name }}
                      </template>
                    </div>
                  </div>
                  <div class="div_item" v-if="this.projectInfo !== null && this.projectInfo?.fondiSupports">
                    <div class="div_title">
                      Федеральная или международная поддержка*
                    </div>
                    <div class="div_content">
                      {{ this.projectInfo?.fondiSupports.map(item => {
                      if (item.name == "Другое") { return item.comment}
                      else {
                        return item.name;
                      }
                    }).join(', ') }}
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </column>
          <column md="12" v-if="this.projectInfo?.fioDirector">
            <card card-type="trusted2" class="trusted2_sec">
              <div class="td2">
                <div class="div_tit">ФИО режиссера</div>
                <div class="div_struct dec_underline">
                  {{ this.projectInfo.fioDirector }}
                </div>
              </div>
            </card>
          </column>
          <column md="12" v-if="this.projectInfo?.fioActors">
            <card card-type="trusted2" class="trusted2_sec">
              <div class="td2">
                <project-detail-collapse-card class="collapse_cont or_letter" title="ФИО главных актеров" :collapse-text="this.projectInfo.fioDirector"/>
              </div>
            </card>
          </column>
          <column md="12" v-if="this.projectInfo?.synopsis">
            <card card-type="trusted2" class="trusted2_sec">
              <div class="td2">
                <project-detail-collapse-card class="collapse_cont" title="Синопсис" :collapse-text="this.projectInfo.synopsis"/>
              </div>
            </card>
          </column>
          <column md="12" v-if="this.projectInfo?.comment">
            <card card-type="trusted2" class="trusted2_sec">
              <div class="td2">
                <project-detail-collapse-card class="collapse_cont or_letter dec_underline" title="Ссылки с информацией на ранее снятые компанией фильма" :collapse-text="this.projectInfo.comment"/>
              </div>
            </card>
          </column>
        </template>
        <column v-if="isBlockProjExist" id="projectDetailApplications">
          <div class="h1_lk">
            <h2 class="hasSubtit hasSubtitProf" v-if="this.projectInfo">Заявки по проекту: <span>{{ this.totalApplications }}</span></h2>
            <div ref="blockProj" class="btn-holder" id="block_proj" :class="{ 'visible-none': !this.projectInfo}">
              <!--          Сюда подцепляется кнопка из блока добавления новых заявок к проекту-->
            </div>
          </div>
          <card-support-measure-array
              v-if="!isLoading && applications && applications.length > 0"
              :applications="applications"
              :card-options="dataInfo.cardOptions"
              :data="data"
              :is-loading-more="isLoadingMore"
              @load-more-emit="loadMore"
              @open-modal-application-delete-emit="openModalApplicationDelete"
          />
        </column>
      </row>
      <loader
          v-if="!this.projectInfo || this.totalApplications == null || loaderProjects || showLoader"
          :animation-duration="3000"
          :size="60"
          :color="'#ED850A'"
      ></loader>
      <cl-modal v-model="modalProjectsDeleteShow" modal-type="center" class="modal-trusted" :closeFonActive="false" :closeNone="true">
        <div class="delete_person">
          <div class="delete_person_img">
            <img src="/img/ico/trashOrg.svg" alt="">
          </div>
          <div class="delete_person_tit">Вы точно хотите удалить проект "{{ activeProjects.name }}"?</div>
          <div class="delete_person_buttons">
            <cl-button class="btn-light btn-middle sp_img btn-ico" img="/img/ico/cancel.svg" @click="modalProjectsDeleteShow = false">Отменить</cl-button>
            <cl-button class="btn-middle  sp_img btn-ico" img="/img/ico/checkWhite.svg" @click="removeProjects">Подтвердить</cl-button>
          </div>
        </div>
      </cl-modal>

    </container>
  </div>
</template>

<script>
import Container from "@/components/library/Container.vue";
import Card from "@/components/library/Card.vue";
import ClButton from "@/components/library/ClButton.vue";
import cardSupportMeasure from "@/components/CardSupportMeasure.vue";
import MyApplications from "@/views/personal/MyApplications.vue";
import Column from "@/components/library/Column.vue";
import {mapActions, mapGetters} from "vuex";
import ClCollapse from "@/components/library/ClCollapse.vue";
import ProjectDetailCollapseCard from "@/components/projectDetailCollapseCard.vue";
import CardSupportMeasureArray from "@/components/cardSupportMeasureArray.vue";

export default {
  name: 'ProjectDetail',
  components: {CardSupportMeasureArray, ProjectDetailCollapseCard, ClCollapse, Column, MyApplications, cardSupportMeasure, ClButton, Card, Container},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      projects: null,
      projectInfo: null,
      applications: {},
      isLoadingMore: false,
      id: this.$route.params.post_id3,
      loaderProjects: true,
      showLoader: true,
      data: false,
      getParams: {
        sortExpression:"created DESC",
        filmProjectGuids: this.$route.params.post_id3,
        pageSize : 3,
        page: 1
      },
      activeProjects: null,
      modalProjectsDeleteShow: false,
      totalApplications: null,
      alreadyScrollToApplications: false,
    };
  },
  methods: {
    // Добавление ключа в список для инициализации компонент-телепортов
    ...mapActions([
      'changeTeleportComponents'
    ]),

    /**
     * Функция для получения данных о проекте
     * @param id - guid проекта
     */
    getProjectInfo(id){
      this.loaderProjects = true;
      this.axios
          .get(`/api/get/one/film/project/${id}`)
          .then(response => {
            this.projectInfo = response.data;
            this.loaderProjects = false;
          })
          .catch(error => {
            this.showError(error);
          });
    },
    /**
     * Функция для получения данных о заявках проекта
     */
    getProjects(isLoadingMore) {
      this.axios
          .get(`/api/get/measure-services`, {params: this.getParams})
          .then(response => {
            if (isLoadingMore) {
              this.applications = [...this.applications, ...response.data.records];
              this.totalApplications = response.data.total;
            } else {
              this.data = response.data;
              this.applications = response.data.records;
              this.totalApplications = response.data.total;
              this.showLoader = false;
            }
            this.isLoadingMore = false;
          })
          .catch(error => {
            this.showError(error);
          });
    },

    removeProjects(){
      this.modalProjectsDeleteShow = false;
      this.loaderProjects = true;
      this.axios
          .post(`/api/delete/film/projects/${this.activeProjects.guid}`)
          .then(response => {
            this.loaderProjects = false;
            this.$router.push('/lk/filmProjects');
          })
          .catch(error => {
            this.loaderProjects = false;
            this.showError(error);
          });
    },

    loadMore() {
      this.isLoadingMore = true;
      this.getParams.page = ++this.data.page;
      this.getProjects(true);
    },

    /**
     * Скрол до заявок при переходе на страницу с #projectDetailApplications
     */
    scrollToApplications() {
      let block = document.getElementById('projectDetailApplications');
      if(block) {
        window.scrollBy({
          top: block.offsetTop - window. scrollY,
          behavior: 'smooth'
        });
      }
    },
    /**
     * Функция открытия модалки с предупреждением об удалении заявки
     * @param {string | undefined} guid - гуид заявки, которую необходимо удалить
     */
    openModalApplicationDelete(guid) {
      this.$emit("openModalApplicationDelete", guid);
    },
  },
  watch: {
    // Если место для кнопки появилось, то добавить в список телепорт-компонентов новое значение 'blockProj' и телепортировать кнопку (компонент blockButtonKinokomisProj.vue)
    isBlockProjExist() {
      if(this.isBlockProjExist) {
        this.$nextTick(() => {
          if(this.$refs.blockProj && !this.teleportComponents.blockProj) this.changeTeleportComponents({action: 'set', id: 'blockProj', value: true});

          // Проскролить до заявок
          if(!this.alreadyScrollToApplications && this.$route.query['scrollTo'] === 'applications') {
            this.alreadyScrollToApplications = true;
            this.scrollToApplications();
          }
        });
      } else {
        this.$nextTick(() => {
          if(this.teleportComponents.blockProj) this.changeTeleportComponents({action: 'delete', id: 'blockProj'});
        });
      }
    }
  },
  computed: {
    // Геттер со списком телепорт-компонентов
    ...mapGetters([
      'teleportComponents',
    ]),

    // Проверяем условие появления места для кнопки.
    isBlockProjExist() {
      return this.totalApplications !== null && !this.loaderProjects && !this.showLoader;
    }
  },
  mounted() {
    this.getProjectInfo(this.id);
    this.getProjects(this.isLoadingMore);
  },
  beforeUnmount() {
    if(this.teleportComponents.blockProj) this.changeTeleportComponents({action: 'delete', id: 'blockProj'});
  },
  emits: ["openModalApplicationDelete"]
}
</script>

<style lang="scss" scoped>

h1 {
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.02em;
  margin-bottom: 0.8rem;
}

.trusted2_sec {
  cursor: auto;
  .td2 {

    .div_tit {
      font-size: 1.8rem;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 2.4rem;
      letter-spacing: 0.02em;
      margin-bottom: 1.6rem;
    }

    .div_data {
      display: flex;
      gap: 1.6rem;

      .div_item {
        min-width: 378px;

        .div_title {
          font-size: 1.2rem;
          line-height: 1.4rem;
          letter-spacing: 0.015em;
          color: #9096A2;
        }

        .div_content {
          font-size: 1.8rem;
          line-height: 2.4rem;
          letter-spacing: 0.015em;
        }
      }
    }

    .div_struct {
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: 0.015em;
      color: #ED850A;

      &.dec_underline {
        text-decoration: underline;
      }
    }

    .div_links {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;

      a {
        font-size: 1.8rem;
        line-height: 2.4rem;
        letter-spacing: 0.015em;
        color: #ED850A;
        text-decoration: underline;
        width: fit-content;
      }
    }
  }
}

.trusted2_req_sec {
  display: flex;
  justify-content: space-between;

  .div_header {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 3.2rem;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }

  a {

    img {
      filter: none;
      margin-right: .8rem;
    }
  }
}

.h1_lk{
  display: flex;
  justify-content: space-between;
  gap: 2.4rem;
  align-items: center;
  margin-bottom: 2rem;
  h2{
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.02em;
    margin-bottom: 0;
  }
  .btn .text{
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }
}

.row.row_list{
  width: calc(100% + 2.4rem);
}

.trusted_persone_detail{
  margin-bottom: 10rem;
}

.return-src{
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: .5rem;
}

.card-trusted2 .card-holder .div_tag {
  gap: 1rem;
}
.div_tag{
  font-size: 1.2rem;
}

.card-trusted2 .card-holder .td1 {
  overflow: hidden;
  border-radius: 0.8rem;
  img {
    width: calc(100% + 1rem);
    height: calc(100% + 1rem);
    object-fit: cover;
    top: -.2rem;
    position: relative;
  }
}

.delete_person{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;

  .delete_person_tit{
    text-align: center;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 133.333%;
    letter-spacing: 0.048rem;
    color: #fff;
  }

  .delete_person_img{
    width: 64px;
    height: 64px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.delete_person_buttons{
  display: flex;
  gap: 1.6rem;
  align-items: center;
}

.not_img{
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
}

.visible-none{
  visibility: hidden;
}

.card-trusted2 .card-holder .div_buttons .div_buttons_mod {
  margin-left: auto;
}

.trusted_persone_detail {
  .card-support-measure {
    padding: 1.6rem;
  }
}

.card-trusted2 :deep(.card-holder) {

  .sp_all {
    gap: 0 0.8rem;

    a, svg {
      flex: none;
    }
  }

  .div_type_isFile {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  .collapse_cont {

    .div_tit {
      font-size: 1.8rem;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 2.4rem;
      letter-spacing: 0.02em;
      margin-bottom: 0;
    }

    .div_struct {
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: 0.015em;

      span {
        text-decoration: underline;
      }
    }

    &.or_letter {
      .div_struct {
        color: #ED850A;
      }
    }

    &.dec_underline {
      .div_struct {
        text-decoration: underline;
      }
    }

    .div_tit_collapse_btn {
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 1.33;
      letter-spacing: 0.02em;
      color: rgba(237, 133, 10, 1);
    }

    .collapse_btn {
      padding-top: 0;
      padding-bottom: 0;

      &::after {
        margin: 0 5px 0 20px;
        width: 10px;
        height: 10px;
        transform: translateY(-0.3rem) rotate(135deg);
      }

      &.collapsed::after {
        transform: translateY(0.3rem) rotate(-45deg);
      }
    }

    .collapse_card {
      padding-top: 1.6rem;
      padding-bottom: 0;
    }

    .collapse_text {
      flex: 1;
      display: flex;
      .div_tit_collapse_btn {
        margin-left: auto;
      }
    }
  }

  @media (max-width: 1024px) {
    flex-direction: row;

    .td1 {
      width: 14.6rem;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;

    .td1 {
      height: 240px;
      width: 180px;
      border-radius: .8rem;
    }

    .card_mobile{
      h3{
        font-size: 2.4rem;
        margin-bottom: 0;
      }
    }

    .div_type_md_flex_column {
      flex-direction: column;
      align-items: normal;
    }

    .sp_all_md_flex_column {
      flex-direction: column;
      align-items: normal;
    }

    .div_type_isFile {
      a {
        margin-left: auto;
      }
    }
  }
}

@media(max-width: 767px){
  .trusted2_sec .td2 .div_data{
    flex-wrap: wrap;
  }
  .h1_lk{
    flex-wrap: wrap;
    gap: 1.6rem;
    h2{
      font-size: 2.4rem;
      display: inline-flex;
      gap: .8rem;
      width: 100%;
    }
    div {
      width: 100%;
    }
  }
}


</style>