<template>
  <div class="map-gis-wrap" ref="mapGisWrap">
    <div class="map-gis" ref="mapGisRef"></div>
    <div class="zoom-controls">
      <div class="zoom-controls-btn" @click="zoom('in')"><img src="/img/ico/plus_st.svg">{{dataInfo}}</div>
      <div class="zoom-controls-btn" @click="zoom('out')"><img src="/img/ico/minus_st.svg" class="img_bl"></div>
      <div class="zoom-controls-btn" @click="centerMapOnMarkers(map, markers);">
        <svg data-v-005a9fee="" class="svg-icon" width="24" height="24" stroke="#5d6068"><use data-v-005a9fee="" xlink:href="/svg/icons.svg#home"></use></svg>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, Ref, onMounted,  onBeforeUnmount, getCurrentInstance, ComponentInternalInstance, ComponentPublicInstance } from "vue";
import { load } from '@2gis/mapgl';
import { Clusterer, InputMarker } from '@2gis/mapgl-clusterer';
import axios from "axios";
import {IMapGis, IMapGisParams} from "@/interface/props/IMapGisProps";

const { dataMap } = defineProps<IMapGis>();

const mapGisWrap: Ref<HTMLDivElement | null> = ref(null);
const mapGisRef: Ref<HTMLDivElement | null> = ref(null);

/**
 * удаляем попап .map-card-gis
 */
function mapGisCardHide():void{
  const popup = mapGisWrap.value?.querySelector('.map-card-gis');
  if(popup){
    popup.remove();
  }
  const pointMap = mapGisWrap.value?.querySelector('.map-gis-point-active');
  if(pointMap){
    pointMap.classList.remove('map-gis-point-active');
  }
}

/**
 * карта
 */
let map: any;
/**
 * маркеры
 */
let markers: InputMarker[];

/**
 * иницилизация карты с маркерами
 * @param points InputMarker[] маркеры
 */
async function mapGis(points: InputMarker[]){
  if(mapGisRef.value){
    const mapglAPI = await load();
    map = new mapglAPI.Map(mapGisRef.value, {
      center: [37.2601110050964, 55.417315569117406],
      //zoom: 15,
      key: "42ad45ce-016d-11eb-9d17-f321695485f2",
      zoomControl: false,
    });

    // вид маркера
    const createMarkerElement = (id: string): HTMLElement => {
      const markerElement = document.createElement('div');
      markerElement.classList.add('map-gis-point');
      markerElement.id = 'marker' + id;
      markerElement.innerHTML = '<svg class="svg-icon" width="48" height="48"><use xlink:href="/svg/icons.svg#pin2gis"></use></svg>';
      return markerElement;
    };

    // преобразуем и фильтруем маркеры
    markers = [...points].map((item, index) => {
      // Проверяем наличие координат перед добавлением маркера
      if (item.lat !== undefined && item.lon !== undefined) {
        return {
          type: "html",
          coordinates: [item.lon, item.lat],
          html: createMarkerElement(item?.guid),
          //anchor: [48, 48],
          userData: item,
          // icon: '/img/ico/pin2gis.svg',
          // hoverIcon: '/img/ico/pin2gishov.svg',
          size: [48, 48],
          anchor: [24, 48],
          hoverAnchor: [24, 48],
        };
      }
      // Вернем null для тех элементов, которые не имеют координат
      return null;
    }).filter(marker => marker !== null) as InputMarker[];

    centerMapOnMarkers(map, markers);

    // Создаем кластеризатор
    const clusterer = new Clusterer(map, {
      radius: dataMap.radius?.value ? dataMap.radius?.value : 0,
      clusterStyle: {
        //type: "html",
        //html: createMarkerElement(),
        //anchor: [48, 48]
        icon: '/img/ico/pin2gis.svg',
        hoverIcon: '/img/ico/pin2gishov.svg',
        size: [48, 48],
        hoverSize: [48, 48],
        anchor: [24, 48],
        hoverAnchor: [24, 48],
        labelOffset: [0, -30]
      }
    });
    clusterer.load(markers);

    clustererClick(clusterer, mapglAPI)
  }
}

/**
 * масштабирование карты
 * @param type {'in' | 'out'} -приближение и удаление
 */
function zoom(type: 'in' | 'out') {
  if (!map) {
    return;
  }
  const zoomStep = 1; // Шаг изменения масштаба
  const currentZoom = map.getZoom();

  if (type === 'in') {
    map.setZoom(currentZoom + zoomStep);
  } else if (type === 'out') {
    map.setZoom(currentZoom - zoomStep);
  }
}
/**
 * центруем карту по маркерам
 * @param map - карта
 * @param markers - маркеры
 */
function centerMapOnMarkers(map: any, markers: Array<{coordinates: number[]}>) {
  if (markers.length === 0) return;

  // если ишем центр карты без fitBounds
  // let sumLat = 0;
  // let sumLon = 0;

  let minLat = 0;
  let minLon = 0;
  let maxLat = 0;
  let maxLon = 0;

  markers.forEach(marker => {
    const [lon, lat] = marker.coordinates;
    // sumLat += lat;
    // sumLon += lon;
    if (lat < minLat || minLat === 0) minLat = lat;
    if (lat > maxLat || maxLat === 0) maxLat = lat;
    if (lon < minLon || minLon === 0) minLon = lon;
    if (lon > maxLon || maxLon === 0) maxLon = lon;
  });



  // const centerLat = sumLat / markers.length;
  // const centerLon = sumLon / markers.length;

  // Устанавливаем центр карты
  //map.setCenter([centerLon, centerLat]);

  // Зум на всех маркерах (вручную определить подходящий уровень зума)
  //map.setZoom(15); // Подберите иной уровень зума на ваше усмотрение

  // автоматически изменит центр и масштаб карты в соответствии с указанными границами
  map.fitBounds(
      {
        northEast: [minLon, minLat],
        southWest: [maxLon, maxLat],
      },
      {
        padding: { top: 50, left: 50, bottom: 50, right: 50 },
      }
  );
}

/**
 * события при клике по кластеру или маркеру
 * @param clusterer кластеризатор
 * @param mapglAPI класс карты
 */
function clustererClick(clusterer, mapglAPI){
  // Добавляем обработчик событий на кластеры
  clusterer.on('click', (event) => {
    if (event.target.type === 'cluster') {
      // находим центр у величивам зум
      const markerCluster = [...event.target.data].map(item => {
        return {
          coordinates: item.coordinates
        }
      })
      centerMapOnMarkers(map, markerCluster);
      //const currentZoom = map.getZoom();
      //map.setZoom(currentZoom + 3);

    } else {
      //console.log(event, 'event.target')

      // скрываем старые popup
      mapGisCardHide();
      const pointActive = mapGisWrap.value?.querySelector('#marker' + event.target.data.userData.guid);
      if(pointActive){
        pointActive.classList.add('map-gis-point-active');
      };

      const htmlMarker = `<div class="map-card-gis">
          <div class="div_close" onclick="document.querySelector('.map-card-gis').remove(); document.querySelector('.map-gis-point-active').classList.remove('map-gis-point-active')">
              <svg class="svg-icon" width="24" height="24" stroke="#ED850A"><use data-v-005a9fee="" xlink:href="/svg/icons.svg#close"></use></svg>
          </div>
          <div class="div_img">
              ${event.target.data.userData?.logo?.imageUrl ? `<a target="_blank" href="${dataMap.url?.value}${event.target.data.userData.guid}"><img src="${event.target.data.userData?.logo?.imageUrl || ''}" alt=""></a>` : ''}
          </div>
          <div class="div_info">
              ${event.target.data.userData?.category?.name ? `<div class="div_tag">${event.target.data.userData.category.name}</div>` : ''}
              ${event.target.data.userData?.name ? `<div class="div_tit"><a target="_blank" href="${dataMap.url?.value}${event.target.data.userData.guid}">${event.target.data.userData.name}</a></div>` : ''}
              ${event.target.data.userData?.area ? `<div class="div_text">Общая площадь: ${event.target.data.userData?.area} м2</div>` : ''}
              ${dataMap.url && dataMap.btnText ? `<div class="div_btn"><a target="_blank" class="btn btn-light btn-lite" href="${dataMap.url?.value}${event.target.data.userData.guid}">${dataMap.btnText?.value}</a></div>` : ''}
          </div>
       </div>`;

      if(window.innerWidth <= 767){
        if(mapGisRef.value){
          mapGisRef.value.insertAdjacentHTML('afterbegin', htmlMarker);
          if(mapGisRef){
            const markerCoords = event.target.data.coordinates;
            const projectedCoords = map.project(markerCoords);

            // Заданный отступ от верхнего края карты
            const offsetFromTop = 120;

            // Преобразуем отступ сверху в координаты
            const cordsUnProject = map.unproject([projectedCoords[0], projectedCoords[1] + offsetFromTop]);

            // Устанавливаем новый центр карты
            map.setCenter([markerCoords[0], cordsUnProject[1]]);
          }
        }
      } else {
        new mapglAPI.HtmlMarker(map, {
          coordinates: event.target.data.coordinates,
          zIndex: 1,
          anchor: [0, 0],
          html: htmlMarker
        });

        map.setCenter(event.target.data.coordinates);
      }
    }
  });
}


// Типизируем экземпляр компонента
interface CustomComponent extends ComponentPublicInstance {
  showError: (error: unknown) => void;
}

/**
 * Хук для управления AbortController
 */
let controller = new AbortController();

onBeforeUnmount(() => {
  controller.abort();
  controller = null;
});

/**
 * Получаем текущий экземпляр компонента
 */
const instance = getCurrentInstance() as ComponentInternalInstance | null;
const proxy = instance ? instance.proxy as CustomComponent : null;

/**
 * Флаг загрузки данных
 * @default false
 */
const isLoadingPoints = ref(true);


const params: IMapGisParams = {};
if(dataMap.types) params.types = dataMap.types.value;

/**
 * Функция для получения списка данных
 */
async function getPoints() {
  try {
    isLoadingPoints.value = true;
    const response = await axios.get('/api/data/pins-on-map', {
      signal: controller.signal,
      params: params
    });
    mapGis(response.data.records);

  } catch (error) {
    // Используем proxy с типизацией CustomComponent
    if (proxy && typeof proxy.showError === 'function') {
      proxy.showError(error);
    } else {
      console.error('Error:', error);
    }
  } finally {
    isLoadingPoints.value = false;
  }
}

onMounted(():void => {
  getPoints();
})
</script>

<style scoped lang="scss">
.map-gis-wrap {
  &.cinema-park-map {
    height: 600px;
    border-radius: 24px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      pointer-events: none;
      box-shadow: inset  0 0 20px rgba(0, 0, 0, 1);
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: block;
    }
    @media (max-width: 767px) {
      height: 500px;
      border-radius: 8px;
      &:before {
        box-shadow: none;
      }
    }
  }
}
.map-gis {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: inherit;
}
:deep(.map-gis-point) {
  width: 48px;
  height: 48px;
  cursor: pointer;
  svg {
    fill: #ffffff;
    stroke: #ED850A;
    &:hover {
      fill: #ED850A;
      stroke: #ffffff;
    }
  }
  &.map-gis-point-active {
    svg {
      fill: #ED850A;
      stroke: #ffffff;
    }
  }
}
:deep(.map-card-gis) {
  //position: absolute;
  //left: 0;
  //top: 0;
  transform: translate(48px, -50%);
  width: 282px;
  gap: 8px;
  border-radius: 12px;
  background: #000106;
  z-index: 10;
  .div_close {
    cursor: pointer;
    width: 36px;
    height: 36px;
    padding: 9px;
    background: #FFFFFFCC;
    border-radius: 6px 12px 6px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -1px;
    top: -1px;
    backdrop-filter: blur(16px)
  }
  .div_img {
    height: 209px;
    border-radius: 12px 12px 8px 8px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .div_info {
    padding: .8rem 1.6rem 1.6rem 1.6rem;
  }
  .div_tag {
    color: #ED850A;
    font-size: 14px;
  }
  .div_tit {
    font-size: 18px;
  }
  .div_text {
    color: #B2B5BB;
    font-size: 16px;
  }
  .div_btn {
    display: none;
  }
  @media (max-width: 767px) {
    z-index: 70;
    transform: none;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    box-shadow: 0px -12px 16px 0px #00000040;
    border-radius: 8px;
    .div_close {
      border-radius: 8px;
    }
    .div_info {
      padding-bottom: 0;
    }
    .div_img {
      border-radius: 8px;
      pointer-events: none;
    }
    .div_tit {
      margin-bottom: 8px;
    }
    .div_btn {
      margin: 0 -1.6rem;
      display: block;
      width: auto;
      .btn {
        width: 100%;
        border-radius: 8px;
      }
    }
  }
}
.zoom-controls {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  &-btn {
    width: 48px;
    height: 48px;
    border-radius: 6px;
    border: 1px solid #F1F2F3;
    box-shadow: -4px 4px 4px 0px #92929240;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    background: #ffffff;
    cursor: pointer;
  }
  @media (max-width: 767px) {
    display: none;
  }
}

:deep([href="https://dev.2gis.ru/link_api_map"]){
  display: none;
}
</style>
