// alertMeasureLeaveRoute для вывода предупреждения перед переходом на другую страницу.
// Добавляется на компонент measure-form-component.
// alertMeasureLeaveRoute при открытой форме выполняет действие при переходе на другую страницу.
// Форма на странице. Чтобы добавить alertMeasureLeaveRoute на measure-form-component, нужно добавить:
//      import:
//          import mixinModalAlertMeasureLeaveRoute from "@/mixin/mixinModalAlertMeasureLeaveRoute";
//      mixin:
//          mixins: [mixinModalAlertMeasureLeaveRoute],
//      measure-form-component следующие props и emits:
//          v-bind="modalAlertMeasureLeaveRouteAttr()"
//          v-on="modalAlertMeasureLeaveRouteEmits()"

// ВАЖНО!!! Перехват перехода на другую страницу работает только для методов router.push и <router-link>. При использовании метода window.location.href или атрибута href данный перехват не работает!!!

// Позволительно использовать только на одну меру на странице, т.к. при любом переходе на другую страницу будет вызываться данная модалка.

// Пример в компоненте testForm.vue

// Дополнительно.
// Если необходимо вызвать модалку из компонента не связанного с тем, в котором подмешан данный mixin, то можно использовать:
// 1) modalAlertMeasureLeaveRouteEmit (emit)
// 2) modalAlertMeasureLeaveRouteEmitAnswer (prop)

// Пример вызова модаки из формы не связанной с mixin в компоненте myCompanySelected.vue

import {onBeforeRouteLeave, onBeforeRouteUpdate} from "vue-router";
export default {
    data() {
        return {
            /**
             * Состояние отображения модалки modalAlertMeasureLeaveRoute
             * @type {boolean}
             * @default false
             */
            modalAlertMeasureLeaveRouteShow: false,
            /**
             * Ссылка для перехода на другую страницу
             * @type {string}
             * @default ""
             */
            modalAlertMeasureLeaveRouteUrl: "",
            /**
             * Состояние, которое показывает доступен ли переход на другую страницу через методы router.push() и <router-link>
             * @type {boolean}
             * @default true
             */
            modalAlertMeasureLeaveRouteAvailable: true,
            /**
             * Состояние, которое показывает будет ли произведен переход на другую страницу после подтверждения перехода в модалке
             * @type {boolean}
             * @default true
             */
            modalAlertMeasureLeaveRouteRedirect: true,
            /**
             * Специальный id запроса на открытие модалки (необходим, чтобы компонент вызвавший emit modalAlertMeasureLeaveRouteEmitAnswer и получивший значение modalAlertMeasureLeaveRouteEmit при закрытии модалки смог отличить свой запрос от чужих)
             * @type {number | null}
             * @default null
             */
            modalAlertMeasureLeaveRouteRequestId: null,
        }
    },
    props: {
        /**
         * Пропс получения ответа (события) от компонентов, где необходимо вывести модалку, но где нет меры (формы).
         */
        modalAlertMeasureLeaveRouteEmitAnswer: {
            type: Object,
        },
    },
    watch: {
        /**
         * Функция отслеживания ответа от компонентов, где необходимо вывести модалку, но где нет меры (формы).
         */
        modalAlertMeasureLeaveRouteEmitAnswer() {
            // Проверка, что emit требует открыть модалку
            if(this.modalAlertMeasureLeaveRouteEmitAnswer?.value === 'showModal') {
                this.modalAlertMeasureLeaveRouteRequestId = this.modalAlertMeasureLeaveRouteEmitAnswer.requestId; // Присваивание id запроса который пришел с событием
                // Проверяем, нужно ли отключить переход на другую страницу для этого запроса
                if(this.modalAlertMeasureLeaveRouteEmitAnswer.disableRedirect) {
                    this.modalAlertMeasureLeaveRouteRedirect = false;
                }
                // Открываем модалку
                this.modalAlertMeasureLeaveRouteShow = true;
            }
        }
    },
    methods: {
        /**
         * Пропсы, которые присваиваются мере (форме)
         * @return {{"modal-alert-measure-leave-location-type": string, "modal-alert-measure-leave-show": boolean}} - пропсы
         */
        modalAlertMeasureLeaveRouteAttr() {
            return {
                'modal-alert-measure-leave-location-type': 'onPage', // Тип меры (на странице или в модалке)
                'modal-alert-measure-leave-show': this.modalAlertMeasureLeaveRouteShow // Атрибут отображения модалки
            };
        },
        /**
         * События, которые присваиваются мере (форме)
         * @return {{modalAlertMeasureLeaveRouteCloseCancel: default.methods.modalAlertMeasureLeaveRouteCloseCancel, modalAlertMeasureLeaveRouteAvailableOn: default.methods.modalAlertMeasureLeaveRouteAvailableOn, modalAlertMeasureLeaveRouteCloseApply: default.methods.modalAlertMeasureLeaveRouteCloseApply, modalAlertMeasureLeaveRouteAvailableOff: default.methods.modalAlertMeasureLeaveRouteAvailableOff}} - события
         */
        modalAlertMeasureLeaveRouteEmits() {
            return {
                'modalAlertMeasureLeaveRouteCloseApply': this.modalAlertMeasureLeaveRouteCloseApply, // Emit с функцией закрытия модалки с переходом на другую страницу
                'modalAlertMeasureLeaveRouteCloseCancel': this.modalAlertMeasureLeaveRouteCloseCancel, // Emit с функцией закрытия модалки без перехода на другую страницу
                'modalAlertMeasureLeaveRouteAvailableOff': this.modalAlertMeasureLeaveRouteAvailableOff, // Emit с функцией отключения доступа перехода на другую страницу
                'modalAlertMeasureLeaveRouteAvailableOn': this.modalAlertMeasureLeaveRouteAvailableOn, // Emit с функцией включения доступа перехода на другую страницу
            };
        },
        /**
         * Функция, которая вызывается при отклонения перехода на другую страницу
         */
        modalAlertMeasureLeaveRouteCloseCancel() {
            this.modalAlertMeasureLeaveRouteShow = false; // Закрыть modalAlertMeasureLeaveRoute без перехода на другую страницу
            this.modalAlertMeasureLeaveRouteRedirect = true;
            this.$emit("modalAlertMeasureLeaveRouteEmit",{requestId: this.modalAlertMeasureLeaveRouteRequestId, value: 'modalClosedCancel'});
        },
        /**
         * Функция, которая вызвается при поддтверждении перехода на другую страницу
         */
        modalAlertMeasureLeaveRouteCloseApply() {
            this.modalAlertMeasureLeaveRouteShow = false; // Закрыть modalAlertMeasureLeaveRoute
            if(this.modalAlertMeasureLeaveRouteRedirect) {
                this.$emit("modalAlertMeasureLeaveRouteEmit",{requestId: this.modalAlertMeasureLeaveRouteRequestId, value: 'modalClosedApply'});
                this.modalAlertMeasureLeaveRouteAvailableOn(); // Дать доступ для перехода на другую страницу
                this.$router.push(this.modalAlertMeasureLeaveRouteUrl); // Перейти на другую страницу
            } else {
                this.modalAlertMeasureLeaveRouteRedirect = true;
                this.$emit("modalAlertMeasureLeaveRouteEmit",{requestId: this.modalAlertMeasureLeaveRouteRequestId, value: 'modalClosedApply'});
            }
        },
        /**
         * Функция отключения свободного перехода на другие страницы
         */
        modalAlertMeasureLeaveRouteAvailableOff() {
            if(this.$root.globalAlertData?.modalAlertMeasureLeave?.active) this.modalAlertMeasureLeaveRouteAvailable = false; // Закрыть доступ на другую страницу
        },
        /**
         * Функция включение свободного перехода на другие страницы
         */
        modalAlertMeasureLeaveRouteAvailableOn() {
            this.$emit("modalAlertMeasureLeaveRouteEmit",null); // Не открывать модалку на несвязанных модалках
            this.modalAlertMeasureLeaveRouteAvailable = true; // Открыть доступ на другую страницу
        },
    },
    created() {
        /**
         * Функция перехвата обновления страницы
         */
        onBeforeRouteUpdate((to, from, next) => {
            if(!this.modalAlertMeasureLeaveRouteAvailable) {
                this.modalAlertMeasureLeaveRouteUrl = to.fullPath; // Сохранить ссылку с переходом на другую страницу
                this.modalAlertMeasureLeaveRouteShow = true; // Показать модалку
                next(false); // Предотвратить переход
            } else {
                // next(); // Совершить переход без релоада страницы
                window.location.href = to.fullPath; // Совершить переход c релоадом страницы (нужен для страниц с авторизацией)
            }
        });

        /**
         * Функция перехвата перехода на другую страницу
         */
        onBeforeRouteLeave((to, from, next) => {
            if(!this.modalAlertMeasureLeaveRouteAvailable) {
                this.modalAlertMeasureLeaveRouteUrl = to.fullPath; // Сохранить ссылку с переходом на другую страницу
                this.modalAlertMeasureLeaveRouteShow = true; // Показать модалку
                next(false); // Предотвратить переход
            } else {
                // next(); // Совершить переход без релоада страницы
                window.location.href = to.fullPath; // Совершить переход c релоадом страницы (нужен для страниц с авторизацией)
            }
        });
    },
    mounted() {
        // Событие, которое обозначает другим компонентам о том, что данный mixin используется
        if(this.$root.globalAlertData?.modalAlertMeasureLeave?.active) this.$emit("modalAlertMeasureLeaveRouteEmit", {requestId: this.modalAlertMeasureLeaveRouteRequestId, value: 'mounted'});
    },
    emits: ['modalAlertMeasureLeaveRouteEmit'],
}