<template>
  <card
      card-type="support-measure"
      :class="{'options_showed': showedOptions}"
  >
    <div class="div_status"  v-if="event.alert || event.status">
      <template v-if="event.status">
        <cl-status type="approved" class="status-ico-wrap" v-if="event.status == 'Одобрена'">
          {{ event.status }}
        </cl-status>
        <cl-status type="rejected" class="status-ico-wrap" v-else-if="event.status == 'Отклонена'">
          {{ event.status }}
        </cl-status>
        <cl-status type="new" class="status-ico-wrap" v-else-if="event.status == 'Новая'">
          {{ event.status }}
        </cl-status>
        <cl-status type="consideration" class="status-ico-wrap" v-else-if="event.status == 'В работе'">
          {{ event.status }}
        </cl-status>
        <cl-status type="draft" class="status-ico-wrap" v-else-if="event.status == 'Черновик'">
          {{ event.status }}
        </cl-status>
        <cl-status type="lock" class="status-ico-wrap" v-else-if="event.status == 'Завершена'">
          {{ event.status }}
        </cl-status>
        <cl-status v-else>{{ event.status }}</cl-status>
      </template>
      <Popper
          v-if="event.alert"
          content="Требуется действия"
          class="light2 popper_info"
          hover="true"
          arrow
      >
        <img src="/img/alert-circle.svg">
      </Popper>
<!--      <span v-if="event.result && event.resultCode" class="color_gray">{{ event.result }}</span>-->
    </div>
    <h4  v-if="event.outerParams?.['frontOutput:name_poject']" class="div_tit_h">{{ event.outerParams['frontOutput:name_poject'].value }}</h4>
    <div class="div_tit_flex">
      <div class="dt1" v-if="event.measureSupport?.image"><img :src="event.measureSupport.image" alt=""></div>
      <div class="dt2" v-if="event.name">
        <div class="div_tit_gray">Тип заявки:</div>
        <div>{{ event.name }}</div>
      </div>
    </div>
    <div class="div_number" v-if="event.code"><span class="sp_num">№{{ event.code }}</span>
      <template v-if="event.fio">
        <span class="color_gray">отправил:</span> {{ event.fio.split(' ')[0] }} {{ event.fio.split(' ')[1] ? event.fio.split(' ')[1].charAt(0) + '.' : '' }} {{ event.fio.split(' ')[2] ? event.fio.split(' ')[2].charAt(0) + '.' : '' }}
      </template>
      <template v-if="event.created">
      <span class="color_gray">Дата подачи:</span> {{ new Date(event.created).getYear() == new Date().getYear() ? $filters.ruDate1(new Date(event.created)) : $filters.ruDate3(new Date(event.created)) }}
      </template>
    </div>
    <div class="div_tit_flex2" v-if="event.outerParams">
      <div class="dt" v-if="event.outerParams['frontOutput:filming_date_from']">
        <div class="div_tit_gray">Дата съемки:</div>
        <div>{{ event.outerParams['frontOutput:filming_date_from'].value }} <template v-if="event.outerParams['frontOutput:filming_date_to'] && event.outerParams['frontOutput:filming_date_from'] != event.outerParams['frontOutput:filming_date_to']">- {{ event.outerParams['frontOutput:filming_date_to'].value }}</template></div>
      </div>
      <div class="dt" v-if="event.outerParams['frontOutput:name_address'] || event.outerParams['frontOutput:name_location']">
        <div class="div_tit_gray">Локация:</div>
        <div>{{ event.outerParams['frontOutput:name_location'].value }} <template v-if="event.outerParams['frontOutput:name_address'] && event.outerParams['frontOutput:name_location']"> / </template> {{ event.outerParams['frontOutput:name_address'].value }}</div>
      </div>

      <div class="dt" v-if="event.outerParams['frontOutput:name_route']">
        <div class="div_tit_gray">Маршут:</div>
        <div>{{ event.outerParams['frontOutput:name_route'].value }}</div>
      </div>
    </div>

    <Popper
        v-if="cardOptions && Object.keys(cardOptions).length > 0"
        class="div_btn_options"
        @click.stop
        @open:popper="openOptions()"
        @close:popper="closeOptions()"
    >
      <div class="btn_options">
        <svg class="svg-icon" width="24" height="24" stroke="#ed850a" :class="{'hidden': !showedOptions}">
          <use xlink:href="/svg/icons.svg#close"></use>
        </svg>
        <svg class="svg-icon" width="24" height="24" stroke="white" :class="{'hidden': showedOptions}">
          <use xlink:href="/svg/icons.svg#kebab-menu"></use>
        </svg>
      </div>
      <template #content>
        <div class="div_options_wrapper" :class="{'hidden': !showedOptions}">
          <loader
              v-if="!optionsLoaded"
              :animation-duration="3000"
              :size="60"
          ></loader>
          <div v-else-if="!optionsLoadedWithError" class="div_options">
            <div v-if="(cardOptions.optionFileImg || cardOptions.optionFileText) && event.outerParams?.['frontOutput:printed_application_form']?.files?.[0]?.url" class="div_options_btn">
              <cl-button link download="application" class="btn_option" :img="getImage(cardOptions.optionFileImg)" :href="event.outerParams['frontOutput:printed_application_form'].files[0].url">
                {{cardOptions.optionFileText?.value}}
              </cl-button>
            </div>
            <div v-if="(cardOptions.optionDetailImg || cardOptions.optionDetailText) && event.guid" class="div_options_btn">
              <cl-button link class="btn_option" :img="getImage(cardOptions.optionDetailImg)" :href="`/lk/my-applications/item/${event.guid}`">
                {{cardOptions.optionDetailText?.value}}
              </cl-button>
            </div>
            <div v-if="(cardOptions.optionRecallImg || cardOptions.optionRecallText) && optionsDataMeasureRequests?.outCode && Object.keys(optionsDataMeasureRequests.outCode).length !== 0 && event.status != 'Черновик'" class="div_options_btn">
              <cl-button class="btn_option" :img="getImage(cardOptions.optionRecallImg)" @click="openModalMeas(optionsDataMeasureRequests.outCode)">
                {{cardOptions.optionRecallText?.value}}
              </cl-button>
            </div>
            <div v-if="(cardOptions.optionEditImg || cardOptions.optionEditText) && event.status == 'Черновик' && event.measureSupport?.guid" class="div_options_btn">
              <cl-button link class="btn_option" :img="getImage(cardOptions.optionEditImg)" :href="`/form/${event.measureSupport.guid}`">
                {{cardOptions.optionEditText?.value}}
              </cl-button>
            </div>
            <div v-if="(cardOptions.optionDeleteImg || cardOptions.optionDeleteText) && event.status == 'Черновик' && event.guid" class="div_options_btn">
              <cl-button class="btn_option" :img="getImage(cardOptions.optionDeleteImg)" @click="openModalApplicationDelete">
                {{cardOptions.optionDeleteText?.value}}
              </cl-button>
            </div>
          </div>
          <div v-else class="div_options">
            <div v-if="cardOptions.optionErrorText" class="div_options_err_text">
              {{cardOptions.optionErrorText.value}}
            </div>
            <div v-if="cardOptions.optionErrorBtnText" class="div_options_btn">
              <cl-button type="light" class="btn-middle w-100" @click="updateRequests()">{{cardOptions.optionErrorBtnText?.value}}</cl-button>
            </div>
          </div>
        </div>
      </template>
    </Popper>
  </card>
</template>

<script>
import ClButton from "@/components/library/ClButton.vue";
import {mapGetters} from "vuex";

export default {
  name: "CardSupportMeasure",
  components: {ClButton},
  data() {
    return {
      /**
       * Состояние, которое показывает открыто ли окно с опциями
       * @type {boolean}
       * @default false
       */
      showedOptions: false,
      /**
       * Опции (Информация о доп. действиях в заявке на меру поддержки)
       * @type {object | null}
       * @default null
       */
      optionsDataMeasureRequests: null,
      /**
       * Состояние, которое показывает загружены ли опции (Информация о доп. действиях в заявке на меру поддержки)
       * @type {boolean}
       * @default false
       */
      optionsDataMeasureRequestsLoaded: false,
      /**
       * Состояние, которое показывает было ли уже запущено получение всех опций
       * @type {boolean}
       * @default false
       */
      optionsDataTaken: false,
      /**
       * Состояние, которое показывает были ли ошибки при получении всех опций
       * @type {boolean}
       * @default false
       */
      optionsLoadedWithError: false,
      /**
       * Контроллер запросов
       * @type {object | null}
       * @default null
       */
      controller: null,
    }
  },
  props: {
    event: {
      type: Object
    },
    /**
     * Тексты и икноки для опций в карточке
     */
    cardOptions: {
      type: Object
    }
  },
  computed: {
    /**
     * Состояние, которое показывает загружены ли все опции
     * @return {boolean}
     */
    optionsLoaded() {
      return this.optionsDataMeasureRequestsLoaded
    }
  },
  methods: {
    /**
     * Функция открытия опций
     */
    openOptions() {
      this.showedOptions = true;
      if(!this.optionsDataTaken) {
        this.optionsDataTaken = !this.optionsDataTaken;
        this.getMeasureRequests(this.event.guid);
      }
    },

    /**
     * Функция закрытия опций
     */
    closeOptions() {
      this.showedOptions = false;
    },

    /**
     * Функция получения опций (Информация о доп. действиях в заявке на меру поддержки)
     * @param {string} id - id карточки (меры)
     */
    getMeasureRequests(id) {
      this.axios
          .get(`/api/get/measure-services/${id}/measure-service-requests`, {signal: this.controller.signal})
          .then(response => {
            this.optionsDataMeasureRequests = response.data;
            this.optionsDataMeasureRequestsLoaded = true;
          })
          .catch(error => {
            this.optionsLoadedWithError = true;
            this.optionsDataMeasureRequestsLoaded = true;
            this.showError(error);
          });
    },

    /**
     * Функция для повтора запросов, чтобы полученить все опции
     */
    updateRequests() {
      this.optionsDataMeasureRequestsLoaded = false;
      this.optionsLoadedWithError = false;
      this.getMeasureRequests(this.event.guid);
    },

    /**
     * Функция, которая получает параметры меры и передает их в верхний компонент, чтобы открыть модалку с мерами
     * @param {object} outCode - параметры меры
     */
    openModalMeas(outCode) {
      const value = {
        measureServiceGuid: this.event.guid,
        subRequestGuid: outCode.guid,
        formTitle: outCode.name,
      }
      this.$emit("openModalMeas", value)
    },

    /**
     * Открытие модалки с удалением заявки
     */
    openModalApplicationDelete() {
      this.$emit("openModalApplicationDelete", this.event.guid);
    }
  },
  created() {
    this.controller = new AbortController();
  },
  beforeUnmount() {
    // отмена запросов
    this.controller.abort();
    this.controller = null;
  },
  emits: ['openModalMeas', 'openModalApplicationDelete']
}
</script>

<style lang="scss" scoped>

</style>