<template>
  <div class="how_to_get_new">
    <container :class="dataInfo?.dataHeader?.containerClass?.value">
      <div class="div_wrap">
        <div class="div_tit">
          <h2 class="">
            <template v-if="dataInfo?.dataHeader?.dataTitle">
              {{ dataInfo.dataHeader.dataTitle.value }}
            </template>
            <template v-else>
              Как добраться
            </template>
          </h2>
          <div class="sub_h2">
            <div v-if="dataInfo?.dataHeader?.dataDesc">
              <div class="text">
                {{ dataInfo.dataHeader.dataDesc.value }}
              </div>
            </div>
          </div>
        </div>
        <div class="ya-map-container">
          <yandex-map
              :coords="[55.417070, 37.260839]"
              :controls="[]"
              :zoom=15
              class="ya-map-howtoget"
          >
            <ymap-marker
                :coords="[55.417070, 37.260839]"
                :icon="markerIcon"
                :options="markerOptions"
            >
            </ymap-marker>
          </yandex-map>
        </div>
        <div class="div_card_desc" v-if="dataInfo?.dataHeader?.dataCardDesc">
          <div class="div_card_title" v-if="dataInfo.dataHeader.dataCardDesc">
            <div class="text">
              {{ dataInfo.dataHeader.dataCardDesc.value }}
            </div>
<!--            <div class="btn-hidden" @click="this.blockVisible == true ? this.blockVisible = false : this.blockVisible = true">-->
<!--              <cl-button type="light"><template v-if="this.blockVisible">Скрыть</template><template v-else>Показать</template></cl-button>-->
<!--            </div>-->
          </div>
        </div>
        <div class="card_change d-flex d-md-none">
          <div class="tag tag1" v-if="dataInfo?.dataAdress?.dataTitle" :class="{'active': this.boolCard}" @click="this.boolCard = true">
            <img src="/img/ico/bus-front.svg" alt="">
            <div class="text">
              {{ dataInfo?.dataAdress?.dataTitle.value }}
            </div>
          </div>
          <div class="tag tag2" v-if="dataInfo?.dataCar?.dataTitle" :class="{'active': !this.boolCard}" @click="this.boolCard = false">
            <img src="/img/ico/car_card.svg" alt="">
            <div class="text">
              {{ dataInfo?.dataCar?.dataTitle?.value }}
            </div>
          </div>
        </div>
        <div class="div_card_block">
          <div class="el_img">
            <div class="img img1" v-if="this.boolCard" @click="modalShow = true">
              <template v-if="dataInfo?.dataAdress?.dataImage">
                <img :src="getImage(dataInfo.dataAdress.dataImage.value, 'reference')" alt="">
              </template>
              <template v-else>
                <img src="/img/addres_map.jpg" alt="">
              </template>
            </div>
            <div class="img img2" v-if="!this.boolCard" @click="modalShow = true">
              <template v-if="dataInfo?.dataCar?.dataImage">
                <img :src="getImage(dataInfo.dataCar.dataImage.value, 'reference')" alt="">
              </template>
              <template v-else>
                <img src="/img/car_map.jpg" alt="">
              </template>
            </div>
            <cl-modal v-model="modalShow" class="modal-gal modal-gal-map" :closeStandart="true">
              <template v-slot:body>
                <div v-if="this.boolCard">
                  <template v-if="dataInfo?.dataAdress?.dataImage">
                    <img :src="getImage(dataInfo.dataAdress.dataImage.value, 'reference')" alt="">
                  </template>
                  <template v-else>
                    <img src="/img/addres_map.jpg" @click="modalShow = true" alt="">
                  </template>
                </div>
                <div v-if="!this.boolCard">
                  <template v-if="dataInfo?.dataCar?.dataImage">
                    <img :src="getImage(dataInfo.dataCar.dataImage.value, 'reference')" alt="">
                  </template>
                  <template v-else>
                    <img src="/img/car_map.jpg" alt="">
                  </template>
                </div>
              </template>
            </cl-modal>
          </div>
          <div class="el_text" :style="dataInfo?.dataHeader?.backgroundImg ? getBkgrImage(dataInfo.dataHeader.backgroundImg, 'hd') : ''">
            <div class="card_change card_change2 d-none d-md-flex">
              <div class="tag tag1" v-if="dataInfo?.dataAdress?.dataTitle" :class="{'active': this.boolCard}" @click="this.boolCard = true">
                <img src="/img/ico/bus-front.svg" alt="">
                <div class="text">
                  {{ dataInfo.dataAdress.dataTitle.value }}
                </div>
              </div>
              <div class="tag tag2" v-if="dataInfo?.dataCar?.dataTitle" :class="{'active': !this.boolCard}" @click="this.boolCard = false">
                <img src="/img/ico/car_card.svg" alt="">
                <div class="text">
                  {{ dataInfo.dataCar.dataTitle.value }}
                </div>
              </div>
            </div>
            <div class="text text1" v-if="this.boolCard">
              <div class="cont_text" v-if="dataInfo?.dataAdress?.dataText" v-html="dataInfo.dataAdress.dataText.value"></div>
            </div>
            <div class="text text2" v-if="!this.boolCard">
              <div class="cont_text" v-if="dataInfo?.dataCar?.dataText" v-html="dataInfo.dataCar.dataText.value"></div>
            </div>
          </div>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import {yandexMap, ymapMarker} from 'vue-yandex-maps'

export default {
  components: {yandexMap, ymapMarker},
  data() {
    return {
      boolCard: true,
      markerIcon: {
        layout: 'default#imageWithContent',
        imageHref: '/img/ico/marker2.svg',
        imageSize: [60, 60],
        imageOffset: [-60, -60],
        contentOffset: [-60, -60],
      },
      modalShow: false,
      blockVisible: true,
    }
  },
  name: "howToGet",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  mounted() {
    if (this.dataInfo.address) {
      this.dataTextAdr = this.dataInfo.address;
    }
  }
}
</script>

<style lang="scss" scoped>

.how_to_get_new {
  padding: 6rem 0;

  @media (max-width: 768px) {
    padding: 6.4rem 0;
  }
}

.div_wrap {
  min-height: 39.9rem;

  .div_inner {
    display: flex;
    justify-content: space-between;
    gap: 2.4rem;

    .div_cont {

      h2 {
        margin-bottom: 2.4rem;
      }

      .div_cards {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;

        .div_card {
          border: 2px solid #ED850A;
          min-width: 52.8rem;
          min-height: auto;
          padding: 2.4rem;
          border-radius: 1.2rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .div_top {
            display: flex;
            gap: .8rem;
            margin-bottom: .8rem;
            align-items: center;

            .div_img {
              width: 2.6rem;
              height: 2.6rem;

              img {
                object-fit: contain;
                object-position: center;
                width: 100%;
                height: 100%;
              }
            }

            .div_tit {
              font-size: 2.4rem;
              font-weight: 500;
              line-height: 3.2rem;
              letter-spacing: 0.02em;
              text-align: left;
              text-transform: uppercase;
            }
          }

          .div_txt {
            font-size: 1.6rem;
            line-height: 2.1rem;
            letter-spacing: 0.015em;
            text-align: left;
            margin-bottom: 1.6rem;
          }

          .btn {
            white-space: nowrap;
            max-width: 19rem;
            max-height: 4rem;
          }
        }

        .grey_border {
          border: 1px solid #6B7280;
        }
      }
    }


  }

  .ya-map-container {
    min-width: 50rem;
    min-height: 270px;
    width: 100%;
    height: 27rem;
    margin-bottom: 3.2rem;
    .ya-map-howtoget {
      width: 100%;
      height: 100%;
      border-radius: 1.2rem;
    }
  }

  @media (max-width: 1440px) {

    .ya-map-container {
      min-width: 100%;
    }
  }

  @media (max-width: 1024px) {

    .div_cont {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .div_inner {
      min-height: auto;
      flex-direction: column-reverse;
      justify-content: center;

      .div_cont {

        h2 {
          font-size: 3.2rem;
          line-height: 4.8rem;
          letter-spacing: 0.02em;
          margin-bottom: 2.4rem;
        }

        .div_cards {

          .div_card {
            min-width: 100%;

            .div_top {

              .div_tit {
                font-size: 2.4rem;
                line-height: 3.2rem;
                letter-spacing: 0.02em;
              }
            }

            .div_txt {
              font-size: 1.6rem;
              line-height: 2.1rem;
              letter-spacing: 0.015em;
            }
          }
        }
      }


    }
  }
}

.ya-map-container {
  min-width: 100%;

  h2 {
    font-size: 3.2rem;
    line-height: 4.8rem;
    letter-spacing: 0.02em;
    margin-bottom: 2.4rem;
  }

  .ya-map-howtoget {
    width: 100%;
    height: 29rem;
    border-radius: 1.2rem;
  }
}


.div_tit{
  display: flex;
  flex-direction: column;
  gap: .8rem;
  margin-bottom: 2.4rem;
  text-align: center;
  h2{
    margin-bottom: 0;
    font-size: 3.2rem;
  }
  .sub_h2{
    display: flex;
    justify-content: center;
    .text{
      max-width: 67rem;
    }
  }
}

.div_card_desc{
  font-size: 2.4rem;
  line-height: 150%;
  margin-bottom: 2.4rem;
  text-align: center;
  text-transform: uppercase;
  .div_card_title{
    display: flex;
    justify-content: center;
    //align-items: center;
    .text{
      text-transform: uppercase;
    }
    //.btn{
    //  min-height: 4rem;
    //  height: 4rem;
    //}
    //@media(max-width: 767px){
    //  font-size: 2.1rem;
    //  flex-direction: column;
    //  gap: 1.6rem;
    //  .btn-hidden{
    //    width: 100%;
    //    .btn{
    //      width: 100%;
    //    }
    //  }
    //}
  }
}

.div_card_block{
  display: flex;
  gap: 2.4rem;
  height: 41.2rem;
  .el_img{
    width: 50%;
    height: 100%;
    border-radius: 1.6rem;
    overflow: hidden;
    .img{
      position: relative;
      height: 100%;
      background-color: white;
      img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .el_text{
    width: 50%;
    background: url("@/assets/img/map_background.jpg") no-repeat center center;
    background-size: cover;
    border-radius: 2.4rem;
    padding: 2.4rem;
  }
}

.card_change{
  display: flex;
  gap: .8rem;
  margin-bottom: 1.6rem;
  .tag{
    display: flex;
    gap: .4rem;
    width: 50%;
    max-height: 5.2rem;
    font-size: 1.4rem;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.6rem;
    background: black;
    border-radius: 3rem;
    border: 1px solid #2E3138;
    transition: .4s background-color;
    cursor: pointer;
    &.tag1{
      width: 60%;
    }
    &.tag2{
      width: 40%;
    }
    img{
      width: 2.4rem;
      height: 2.4rem;
    }
    .text{
      white-space: nowrap;
    }
    &.active{
      background: #ED850A;
    }
  }
}

@media(max-width: 1200px){
  .div_card_block{
    height: 44rem;
    //.el_img img{
    //  height: 380px;
    //  width: 588px;
    //}
  }
  .card_change {
    .tag {
      padding: 8px 16px;
      height: 40px;
    }
  }
}

@media (max-width: 1200px) and (min-width: 990px){
  .div_card_block{
    .el_text{
      width: 58%;
    }
    .el_img{
      width: 42%;
    }
  }
  .card_change{
    .tag{
      &.active{
        flex: 1;
      }
      &:not(.active){
        width: fit-content;
        gap: 0;
        .text{
          width: 0;
          overflow: hidden;
        }
      }
    }
  }
}

@media(max-width: 991px){
  .div_card_block{
    height: fit-content;
    flex-direction: column;
    .el_img, .el_text{
      width: 100%;
    }
    .el_img{
      height: 40.2rem;
      img{
        width: 100%;
        height: auto;
      }
    }
    .el_text{
      padding: 2.4rem;
    }
  }
}

@media (max-width: 767px){
  .div_card_block {
    .el_text {
      padding: 1.2rem;
    }
    .el_img {
      height: 19.3rem;
    }
  }
  .card_change {
    .tag {
      font-size: 13px;
      &.active {
        flex: 1;
      }

      &:not(.active) {
        width: fit-content;
        gap: 0;

        .text {
          width: 0;
          overflow: hidden;
        }
      }
    }
  }
}

@media(max-width: 767px){
  .div_wrap .ya-map-container{
    min-height: 192px;
    height: 192px;
  }
}

.container.container0{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

</style>

<style lang="scss">
  .cont_text > p{
    margin-bottom: 1.2rem !important;
    line-height: 133.33%;
    @media(max-width: 1200px){
      font-size: 1.6rem;
    }
  }
  .card_change.card_change2 .tag .text{
    font-size: 1.6rem;
  }
</style>