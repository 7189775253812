<template>
  <div class="film_projects">
    <container>
      <div class="h1_lk">
        <h1 class="hasSubtit hasSubtitProf">Проекты</h1>
        <div class="btn-holder">
          <cl-button type="light btn-middle btn-center-text" @click="this.$router.push({path: '/lk/filmProjects/addProject'})">
            <img src="/img/ico/orange_plus.svg" >
            Добавить проект
          </cl-button>
        </div>
      </div>

      <cl-modal v-model="modalProjectsDeleteShow" modal-type="center" class="modal-trusted" :closeFonActive="false" :closeNone="true">
        <div class="delete_person">
          <div class="delete_person_img">
            <img src="/img/ico/trashOrg.svg" alt="">
          </div>
          <div class="delete_person_tit">Вы точно хотите удалить проект "{{ activeProjects.name }}"?</div>
          <div class="delete_person_buttons">
            <cl-button class="btn-light btn-middle sp_img btn-ico" img="/img/ico/cancel.svg" @click="modalProjectsDeleteShow = false">Отменить</cl-button>
            <cl-button class="btn-middle  sp_img btn-ico" img="/img/ico/checkWhite.svg" @click="removeProjects">Подтвердить</cl-button>
          </div>
        </div>
      </cl-modal>

      <row class="trusted_persone">
        <div id="loader-measure-ms-form" class="loader-trusted" v-if="loaderProjects">
          <loader
              :animation-duration="3000"
              :size="60"
              :color="'#ED850A'"
          ></loader>
        </div>
        <column md="12" v-for="item in projects">
          <film-projects-card :item="item" @open-modal-delete-emit="openModalDelete"/>
        </column>
      </row>
    </container>
  </div>
</template>

<script>

import Card from "@/components/library/Card.vue";
import MsForm from "@/components/forms/MsForm.vue";
import {mapGetters} from "vuex";
import ClModal from "@/components/library/ClModal.vue";
import ClButton from "@/components/library/ClButton.vue";
import FilmProjectsCard from "@/components/filmProjectsCard.vue";

export default {
	name: "filmProjects",
	components: {FilmProjectsCard, ClButton, ClModal, MsForm, Card},
	props: {
		dataInfo: {
			type: Object,
			require: true
		},
	},
	data(){
		return{
			projects: null,
			modalProjectsDeleteShow: false,
			activeProjects: null,
			loaderProjects: true,
		}
	},
	computed: {
		...mapGetters([
			'getUser'
		]),
	},
	methods: {

		getProjects(){
			this.loaderProjects = true;
			this.axios
				.get(`/api/get/list/film/projects/`)
				.then(response => {
					this.projects = response.data.records;
				})
				.catch(error => {
					this.showError(error);
				})
				.finally(() => {
					this.loaderProjects = false;
				});
		},

		removeProjects(){
			this.modalProjectsDeleteShow = false;
			this.loaderProjects = true;
			this.axios
				.post(`/api/delete/film/projects/${this.activeProjects.guid}`)
				.then(response => {
					this.getProjects();
				})
				.catch(error => {
          this.loaderProjects = false;
					this.showError(error);
				});
		},

    openModalDelete(item) {
      if(item?.guid && item?.name) {
        this.activeProjects = {'guid': item.guid, 'name': item.name};
        this.modalProjectsDeleteShow = !this.modalProjectsDeleteShow;
      }
    }
	},
	mounted(){
		this.getProjects();
	}
}
</script>

<style lang="scss" scoped>
.trusted_persone{
  margin-top: 3.6rem;
  position: relative;
}

.modal_bkcg{
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 1, 6, 0.80);
}

.modal_info{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 6px;
  background: #17191C;
  max-width: 800px;
}

.mInfo_row2{
  display: flex;
  gap: 12px;
  height: 64px;

  .mInfo_info{
    display: flex;
    gap: 12px;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 131.25%;
    letter-spacing: 0.24px;
  }

  img{
    height: 100%;
    width: auto;
    object-fit: contain;
  }
}

.delete_person{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;

  .delete_person_tit{
    text-align: center;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 133.333%;
    letter-spacing: 0.048rem;
    color: #fff;
  }

  .delete_person_img{
    width: 64px;
    height: 64px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.delete_person_buttons{
  display: flex;
  gap: 1.6rem;
  align-items: center;

  .btn {
    min-width: 19.8rem;
  }
}

.loader-trusted{
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.h1_lk{
  display: flex;
  justify-content: space-between;
  gap: 2.4rem;
  align-items: center;
  margin-bottom: 2rem;
  h1{
    margin-bottom: 0;
  }
  @media(max-width: 767px){
    flex-direction: column;
    align-items: start;
    .btn-holder {
      width: 100%;
      .btn{
        width: 100%;
      }
    }
  }
}
</style>