<template>
  <container>
    <row class="trusted_persone_detail">
      <column md="12">
        <router-link to="/lk/filmProjects" class="return-src">
          <img src="/img/ico/move-left-2.svg" height="19" width="19">
          Все проекты
        </router-link>
      </column>
    </row>
<!--      <column md="12">-->
<!--        <card card-type="trusted2" class="trusted2_sec">-->
<!--          <div class="td1" v-if="this.projectInfo !== null">-->
<!--            <template v-if="this.projectInfo?.logo !== null && this.imageUrl !== ''">-->
<!--              <img :src="this.projectInfo.logo.imageUrl" alt="" @click="openFileInput">-->
<!--            </template>-->
<!--            <template v-else>-->
<!--              <img :src="this.imageUrl" :class="{'not_img' : this.projectInfo.logo == null}" alt="" @click="openFileInput">-->
<!--            </template>-->
<!--            <input type="file" @change="handleFileUpload" ref="fileInput" style="display: none;">-->
<!--            <cl-button @click="uploadImage" class="btn-save">Сохранить</cl-button>-->
<!--            <a class="btn-cancel color-or" @click="this.imageUrl = ''">Отменить</a>-->
<!--          </div>-->
<!--          <div class="td2">-->
<!--            <div class="div_tag">-->
<!--              <span class="sp_all"><span class="sp1">Номер проекта {{this.projectInfo?.id}}</span></span>-->
<!--              <span class="sp_all"><span class="sp1" v-if="this.projectInfo?.created">Дата изменения {{ $filters.ruDate6(new Date(this.projectInfo?.created)) }}</span></span>-->
<!--            </div>-->
<!--            <h3 v-if="this.projectInfo !== null && this.projectInfo?.name">{{ this.projectInfo?.name }}</h3>-->
<!--            <div class="div_type" v-if="this.projectInfo !== null && this.projectInfo?.typeAudio?.name">{{ this.projectInfo?.typeAudio.name }}</div>-->
<!--            <div class="div_type" v-if="this.application !== undefined && this.applications.length > 0 ">-->
<!--              <span class="sp_all">-->
<!--                <span class="sp1">Кол-во заявок:</span>-->
<!--                <span class="sp_color_or">{{ this.applications.length }}</span>-->
<!--              </span>-->
<!--            </div>-->
<!--            <div class="div_type">-->
<!--              <div class="sp_all" v-if="this.projectInfo !== null && this.projectInfo?.nationalFilm">-->
<!--                <span class="sp1">УНФ</span>-->
<!--                <span>{{ this.projectInfo?.nationalFilm }}</span>-->
<!--                <svg class="svg-icon" width="24" height="24" stroke="#10B981">-->
<!--                  <use xlink:href="/svg/icons.svg#check"></use>-->
<!--                </svg>-->
<!--              </div>-->
<!--              <div class="sp_all" v-else>-->
<!--                <span class="sp1">УНФ</span>-->
<!--                <svg class="svg-icon" width="24" height="24" stroke="#F43F5E" >-->
<!--                  <use xlink:href="/svg/icons.svg#close"></use>-->
<!--                </svg>-->
<!--              </div>-->
<!--              <div class="sp_all">-->
<!--                ФСО-->
<!--                <svg class="svg-icon" width="24" height="24" stroke="#F43F5E" >-->
<!--                  <use xlink:href="/svg/icons.svg#close"></use>-->
<!--                </svg>-->
<!--              </div>-->
<!--              <div class="sp_all">-->
<!--                Гарантийное письмо-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </card>-->
<!--      </column>-->
<!--    </row>-->
    <ms-form  class="formEditProjects"  :urlData="'/api/add/film/projects/'" :submitName="'Сохранить'" :btn-submit-modal="true" :btnCloseModal="false" @closeModal="modalProjectsEditShow = false" @ok="okProjects"/>

    <cl-modal v-model="modalProjectsOk" modal-type="center" :close-fon-active="false" :close-none="true">
      <template v-slot:header>
        <div class="success_block">
          <img src="/img/ico/check-circle-2.svg" alt="">
          <div>{{ `Проект ${modalProjectsName} успешно создан!`}}</div>
        </div>
      </template>
      <template v-slot:footer>
        <a href="/lk/filmProjects" class="btn btn-default m-0-auto">К списку проектов</a>
      </template>
    </cl-modal>

  </container>
</template>

<script>

import Card from "@/components/library/Card.vue";
import MsForm from "@/components/forms/MsForm.vue";
import {mapGetters} from "vuex";
import ClModal from "@/components/library/ClModal.vue";
import ClButton from "@/components/library/ClButton.vue";
import {Base64} from "js-base64";

export default {
  name: "filmProjects",
  components: {ClButton, ClModal, MsForm, Card},
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data(){
    return{
      projects: null,
      modalProjectsEditShow: false,
      loaderProjects: true,
      modalProjectsOk: false,
      modalProjectsName: null,
      projectInfo: null,
      id: this.$route.params.post_id3,
      image: null,
      imageUrl: null,
    }
  },
  computed: {
    ...mapGetters([
      'getUser'
    ]),
  },
  methods: {
    okProjects(){
      this.modalProjectsName = document.getElementById('film_projects_name').value;
      this.modalProjectsOk = true;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/forms/form_edit_project";

.trusted_persone_detail {
  margin-bottom: 3.2rem;

  .return-src {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    cursor: pointer;

    img {
      width: 1.9rem;
      height: 1.9rem;
    }
  }
}

</style>