<template>
  <modal-info
      v-model="isShowModalPayment"
      :modal-params="{
        active: dataInfo.active,
        isBlackColor: dataInfo.isBlack,
        isCenter: {
          value: true
        },
        isFonClose: false,
        icon: dataInfo.icon,
        title: dataInfo.title,
        text: dataInfo.text,
        buttons: [
            {
              text: dataInfo.closeBtnText,
              func: closeModal
            }
        ]
      }"
  />
</template>

<script>
import ClModal from "@/components/library/ClModal.vue";
import ClButton from "@/components/library/ClButton.vue";
import ModalInfo from "@/components/modalInfo.vue";

export default {
  name: "modalPayment",
  components: {ModalInfo, ClButton, ClModal},
  data() {
    return {
      /**
       * Состояние, с помощью которого можно открывать/закрывать модалку
       * @type {boolean}
       * @default false
       */
      isShowModalPayment: false,
    }
  },
  props: {
    /**
     * Данные из админки
     * @type Object
     */
    dataInfo: {
      type: Object,
      require: true,
    },
    /**
     * Тип модалки (результат оплаты)
     */
    modalType: {
      type: String,
    }
  },
  methods: {
    /**
     * Функция для открытия модалки.
     */
    openModal() {
      this.isShowModalPayment = true;
    },
    /**
     * Функция для закрытия модалки.
     */
    closeModal() {
      this.isShowModalPayment = false;
    },
    /**
     * Функция для проверки типа сообщения об оплате
     */
    checkPayment() {
      if(this.$route.query.payment === this.modalType) {
        this.openModal();
      }
    }
  },
  mounted() {
    this.checkPayment();
  }
}
</script>

<style lang="scss" scoped>

</style>