<template>
  <div class="form-group" :class="formItemClasses" :data-singleid="formItem.attr?.singleName || null">
    <template v-if="formItem.type === 'hidden'">
      <input :type="formItem.type"
             :id="formItem['full_name'].replace(/\[/g, '_').replace(/]/g, '')"
             :name="formItem['full_name']"
             :data-name="formItem['full_name']"
             :value="value"
             :required="!!required"
             @focus="isFocused = true"
             @blur="isFocused = false"
             :data-srcmap="formItem.attr?.sourceDataMap"
             :data-qa-column="formItem.attr?.sourceDataMap"
      >
    </template>
    <template v-else-if="formItem.type !== 'iasupload'">
      <template v-if="formItem.type !== 'label' && formItem.type !== 'stars' && formItem.type !== 'action_button' && formItem.type !== 'captcha' && formItem.type !== 'file' && formItem.type !== 'film_project_file'">
        <label :for="item['full_name'].replace(/\[/g, '_').replace(/]/g, '')" class="label_input">
          <template v-if="formItem.type === 'string'">
            <label class="form-label" ref="formLabel" v-if="!hiddenLabel" :class="required === true ? 'required' : ''">{{formItem.title}}</label>
            <template v-if="formItem.widget === 'textarea'">
              <template v-if="formItem.options?.widget === 'wysiwyg'">
                <wysiwyg :id="formItem['full_name'].replace(/\[/g, '_').replace(/]/g, '')"
                         :initial-value="value"
                         :class="[
                            formItem.attr?.class,
                            typeof formItem.attr !== undefined && !formItem.attr.placeholder ? 'responsive-control' : null
                          ]"
                         :placeholder="formItem.attr?.placeholder"
                         :name="formItem['full_name']"
                         :data-name="formItem['full_name']"
                         @is-focus="stringFocusHandler"
                         @is-blur="stringBlurHandler"
                         @is-input="formItemValue"
                         :readonly="formItem.options?.readonly"
                         :data-srcmap="formItem?.attr.sourceDataMap"
                         :data-outername="formItem.attr && formItem.attr['data-outername']"
                         :data-qa-column="formItem.attr?.sourceDataMap"
                />
              </template>
              <div class="textarea-cont"
                   :style="marginTop" v-else>
                <div class="textarea-counter" v-if="value.length > 0">{{value.length}}</div>
                <perfect-scrollbar>
                <textarea class="form-control"
                          :class="[
                            formItem.attr?.class,
                            typeof formItem.attr !== undefined && !formItem.attr.placeholder ? 'responsive-control' : null
                          ]"
                          :required="required"
                          :placeholder="formItem.attr?.placeholder"
                          :id="formItem['full_name'].replace(/\[/g, '_').replace(/]/g, '')"
                          :name="formItem['full_name']"
                          :data-name="formItem['full_name']"
                          @focus="stringFocusHandler"
                          @blur="stringBlurHandler"
                          v-model="value"
                          @input="textAreaHandler"
                          @change="formItemValue(value)"
                          :readonly="formItem.options?.readonly"
                          :data-srcmap="formItem?.attr.sourceDataMap"
                          :data-outername="formItem.attr && formItem.attr['data-outername']"
                          :data-qa-column="formItem.attr?.sourceDataMap"
                ></textarea>
                </perfect-scrollbar>
              </div>
            </template>
            <template v-else>
              <input type="text"
                     class="form-control"
                     :class="[
                        typeof formItem.attr !== 'undefined' ? formItem.attr.class : null,
                        typeof formItem.attr !== undefined && !formItem.attr?.placeholder ? 'responsive-control' : null,
                        item?.attr?.tooltip ? 'input-tooltip' : null
                     ]"
                     :required="required"
                     v-model="value"
                     v-maska="maskInput"
                     :placeholder="formItem.attr?.placeholder"
                     @input="validationHandler"
                     @change="formItemValue(value)"
                     :id="item['full_name'].replace(/\[/g, '_').replace(/]/g, '')"
                     :name="item['full_name']"
                     :data-name="item['full_name']"
                     @focus="stringFocusHandler"
                     @blur="stringBlurHandler"
                     :readonly="formItem.options?.readonly"
                     :style="marginTop"
                     :data-srcmap="formItem.attr?.sourceDataMap"
                     :data-outername="formItem.attr && formItem.attr['data-outername']"
                     :data-qa-column="formItem.attr?.sourceDataMap"
              >

              <Popper v-if="item?.attr?.tooltip"
                  class="light popper_info"
                  arrow
                  hover
                  placement="right"
              >
                <img src="/img/ico/ico_info_orange.svg">
                <template #content>
                  {{ item.attr.tooltip }}
                </template>
              </Popper>
            </template>
          </template>
          <template v-if="formItem.type === 'checkbox'">
            <div class="form-check">
              <label class="label_input" :data-textval="formItem.title" @mouseenter="checkBoxInformerHandler" @mouseleave="checkBoxInformerHandler">
                <input type="checkbox"
                       class="form-control form-check-input"
                       :id="item['full_name'].replace(/\[/g, '_').replace(/]/g, '')"
                       :name="item['full_name']"
                       :data-name="item['full_name']"
                       :required="required"
                       :checked="formItem.checked"
                       @change="checkboxHandler($event, formItem)"
                       :readonly="formItem.options?.readonly"
                       :data-srcmap="formItem?.attr.sourceDataMap"
                       :data-outername="formItem.attr && formItem.attr['data-outername']"
                       :data-qa-column="formItem.attr?.sourceDataMap"
                >
                <span class="sp_all"></span>
                <template v-if="formItem.options.widget === 'checkbox'">
                  <span :class="required === true ? 'required' : ''">
                    {{ formItem.title }}
                  </span>
                </template>
                <template v-else-if="formItem.options.widget === 'personalConfirm'">
                  <span :class="required === true ? 'required' : ''"
                        v-html="formItem.title.replace(formItem.options.linkReplace, `<a class='a-link--confirm' href='${formItem.options.fileUrl}' target='_blank' download>${formItem.options.linkReplace}</a>`)"></span>
                </template>
              </label>
            </div>
          </template>
          <template v-if="formItem.type === 'select'">
            <template v-if="formItem.widget === 'choice-expanded'">
              <radio-button :radio-data="formItem.options.choices"
                            :hidden-label="hiddenLabel"
                            :name="item['full_name']"
                            :id="`${item['full_name'].replace(/\[/g, '_').replace(/]/g, '')}`"
                            :title="formItem.title"
                            :required="required"
                            :data-outername="formItem.attr && formItem.attr['data-outername']"
                            :data-qa-column="formItem.attr?.sourceDataMap"
              />
            </template>
            <template v-else>
              <label class="form-label" v-if="!hiddenLabel" :class="required === true ? 'required' : ''">{{ formItem.title }}</label>
              <my-select
                :class="typeof formItem.attr !== undefined && !formItem.attr.placeholder ? 'responsive-control' : null"
                :items="formItem.options.choices"
                :value-name="'data'"
                :group-label="'choices'"
                :placeholder="formItem.attr?.placeholder"
                @select="formItemValue"
                :track-by="'label'"
                :multiple="formItem.isMultiple"
                :group-type="'choiceGroup'"
                @focus="selectFocusHandler(true)"
                @blur="selectFocusHandler(false)"
                @search-filled="isFilled = $event"
                :name="item['full_name']"
                :id="`${item['full_name'].replace(/\[/g, '_').replace(/]/g, '')}`"
                :init-value="value"
                :readonly="formItem.options?.readonly"
                :style="marginTop"
                :required="required"
                :choice-count="formItem.options['choice_count']"
                :data-srcmap="formItem.attr?.sourceDataMap"
                :data-outername="formItem.attr && formItem.attr['data-outername']"
                :data-qa-column="formItem.attr?.sourceDataMap"
              />
            </template>
          </template>
          <template v-if="formItem.type === 'datetime'">
            <label v-if="!hiddenLabel" :class="required === true ? 'required' : ''" class="form-label2" :for="`dp-input-${item['full_name'].replace(/\[/g, '_').replace(/]/g, '')}`">{{ formItem.title }}</label>
            <datetime :readonly="formItem.options?.readonly"
                      :id="`${item['full_name'].replace(/\[/g, '_').replace(/]/g, '')}`"
                      @focus="stringFocusHandler"
                      @blur="stringBlurHandler"
                      @internal-model-change="isFilled = $event"
                      :name="formItem['full_name']" :data-name="formItem['full_name']" :value="value" textInput
                      :data-srcmap="formItem.attr?.sourceDataMap" :data-qa-column="formItem.attr?.sourceDataMap" :data-outername="formItem.attr && formItem.attr['data-outername']"/>
          </template>
        </label>
      </template>
      <template v-else-if="formItem.type === 'file'">
<!--    новый тип добавление файла-->
        <label :for="item['full_name'].replace(/\[/g, '_').replace(/]/g, '')" class="form-label form-label-file" :class="required === true ? 'required' : ''">{{ formItem.title }} <template v-if="formItem.attr['data-example'] && !formItem.attr['line-break']">(<a class="a-wh" :href="formItem.attr['data-example']"  :target="[...formItem.attr['data-example'].split('.')].at(-1) == 'pdf' ? '_blank' : '_self'">образец</a>)</template></label>
        <file-picker-forms v-model="value"
                           :id="formItem['full_name'].replace(/\[/g, '_').replace(/]/g, '')"
                           :readonly="formItem.options?.readonly"
                           :accept="formItem.hasOwnProperty('attr') ? formItem.attr['data-fileaccept'] : ''"
                           :size="formItem.hasOwnProperty('attr') ? formItem.attr['data-filesize'] : ''"
                           :multiple="formItem.hasOwnProperty('attr') ? formItem.attr['multiple'] : ''"
                           :name="formItem['full_name']"
                           :formItemAttr="formItem.hasOwnProperty('attr') ? formItem.attr : ''"
                           :classFileView="formItem.hasOwnProperty('attr') ? formItem.attr['classFileView'] : ''"
                           @changeInput="formItemValue"
        />
      </template>
      <template v-else-if="formItem.type === 'film_project_file' && formItem.properties?.file">
        <label :for="formItem.properties.file['full_name'].replace(/\[/g, '_').replace(/]/g, '')" class="form-label form-label-file" :class="required === true ? 'required' : ''">{{ formItem.title }} <template v-if="formItem.attr['data-example'] && !formItem.attr['line-break']">(<a class="a-wh" :href="formItem.attr['data-example']"  :target="[...formItem.attr['data-example'].split('.')].at(-1) == 'pdf' ? '_blank' : '_self'">образец</a>)</template></label>
        <file-picker-forms v-model="formItem.properties.file.value"
                           :id="formItem.properties.file['full_name'].replace(/\[/g, '_').replace(/]/g, '')"
                           :readonly="formItem.properties.file.options?.readonly"
                           :accept="formItem.hasOwnProperty('attr') && 'data-fileaccept' in formItem.attr ? formItem.attr['data-fileaccept'] : (formItem.properties.file.hasOwnProperty('attr') ? formItem.properties.file.attr['data-fileaccept'] : '')"
                           :size="formItem.hasOwnProperty('attr') && 'data-filesize' in formItem.attr ? formItem.attr['data-filesize'] : (formItem.properties.file.hasOwnProperty('attr') ? formItem.properties.file.attr['data-filesize'] : '')"
                           :multiple="formItem.hasOwnProperty('attr') && 'multiple' in formItem.attr ? formItem.attr['multiple'] : (formItem.properties.file.hasOwnProperty('attr') ? formItem.properties.file.attr['multiple'] : '')"
                           :name="formItem.properties.file['full_name']"
                           :formItemAttr="formItem.hasOwnProperty('attr') ? (formItem.properties.file.hasOwnProperty('attr') ? {...formItem.properties.file.attr, ...formItem.attr} : formItem.attr) : (formItem.properties.file.hasOwnProperty('attr') ? formItem.properties.file.attr : '')"
                           :classFileView="formItem.hasOwnProperty('attr') && 'classFileView' in formItem.attr ? formItem.attr['classFileView'] : (formItem.properties.file.hasOwnProperty('attr') ? formItem.properties.file.attr['classFileView'] : '')"
                           @changeInput="formItemValue"
        />
      </template>
      <template v-else-if="formItem.type === 'stars'">
        <label v-if="!hiddenLabel" class="form-label" :class="required === true ? 'required' : ''">{{
            formItem.title
          }}</label>
        <stars :item="formItem" :readonly="formItem.options?.readonly"
               @choose-star="formItemValue" :data-outername="formItem.attr && formItem.attr['data-outername']" :data-qa-column="formItem.attr?.sourceDataMap"/>
      </template>
      <template v-else-if="formItem.type === 'action_button'">
        <button class="btn btn-light"
                :data-modal="this.formItem['full_name'].replace(/\[/g, '_').replace(/]/g, '')"
                @click="modalOpenHandler(formItem.options.doAction + 'Open')"
                :data-outername="formItem.attr && formItem.attr['data-outername']"
                :data-qa-column="formItem.attr?.sourceDataMap"
                type="button"
        >{{formItem.title}}</button>
        <div class="modal fade" :id="formItem['full_name'].replace(/\[/g, '_').replace(/]/g, '')" tabindex="-1">
          <div class="modal-dialog">
            <div class="modal-content">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть">
                <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.505859" y="28.2895" width="40" height="1" transform="rotate(-45 0.505859 28.2895)" fill="currentColor"></rect><rect x="1.21094" y="0.00335693" width="40" height="1" transform="rotate(45 1.21094 0.00335693)" fill="currentColor"></rect></svg>
              </button>
              <h3>{{ formItem.options.actionData.meta.modalTitle }}</h3>
              <perfect-scrollbar class="modal-body" style="max-height: 50vh">
                <div class="product-list">
                  <template v-for="item of productList" :key="item.Guid">
                    <div class="product-item">
                      <div class="product-check form-group">
                        <div class="form-check">
                          <label class="label_input">
                            <input type="checkbox"
                                   class="form-control form-check-input"
                                   :id="item.Guid"
                                   :checked="item.Checked"
                                   @change="chooseProduct(item)"
                            >
                            <span class="sp_all"></span>
                          </label>
                        </div>
                      </div>
                      <div class="product-info">
                        <h4>{{ item.Name }}</h4>
                        <div class="text" v-if="item.Description">{{item.Description}}</div>
                      </div>
                      <div class="product-status" :class="`product-status--${item.ModerationStatus.Name.getStatusNumber()}`">{{item.ModerationStatus.Name.toLowerCase()}}</div>
                      <div class="product-price">
                        <div class="current" v-if="item.PriceAfterDiscount">{{String(item.PriceAfterDiscount).priceFormat()}}</div>
                        <div class="full">{{String(item.Price).priceFormat()}}</div>
                      </div>
                    </div>
                  </template>
                </div>
              </perfect-scrollbar>
              <div class="modal-buttons">
                <button class="btn btn-light" type="button" data-bs-dismiss="modal">отменить</button>
                <button class="btn btn-primary" type="button" :disabled="modalAddDisabled" @click="modalAddHandler(formItem.options.doAction + 'Add')">добавить</button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="formItem.type === 'captcha'">
        <label class="form-label" ref="formLabel" v-if="!hiddenLabel" :class="required === true ? 'required' : ''">{{formItem.options.captchaData.challenge}}</label>
        <captcha
          :id="formItem['full_name'].replace(/\[/g, '_').replace(/]/g, '')"
          :full-name="formItem['full_name']"
          :captcha-info="formItem.options.captchaData"
          @captcha-blocked="$emit('captcha-blocked', $event)"
        />
      </template>
      <template v-else>
        <label class="form-label" :data-qa-column="formItem.attr?.sourceDataMap" :class="[formItem.attr?.class, required === true ? 'required' : '']">
          <template v-if="formItem.options?.link">
            <span v-html="formItem.options.description.replace(formItem.options?.linkReplace, `<a class='a-link--confirm' href='${formItem.options?.link}' target='_blank' download>${formItem.options.linkReplace}</a>`)"></span>
          </template>
          <template v-else>
            {{ formItem.options?.description }}
          </template>
        </label>
      </template>
      <template v-if="formItem.attr !== undefined && Object.keys(formItem.attr).includes('label-help')">
        <div class="informer">{{formItem.attr["label-help"]}} <template v-if="formItem.attr['data-example'] && formItem.attr['line-break']"><a class="a-wh" :href="formItem.attr['data-example']"  :target="[...formItem.attr['data-example'].split('.')].at(-1) == 'pdf' ? '_blank' : '_self'">(образец)</a></template></div>
      </template>
    </template>
    <file-uploader :hidden-label="hiddenLabel" :sign-step="signStep" :id="`id_${formItem.propertyOrder}`"
                   :item="formItem" :required="required" v-if="formItem.type === 'iasupload'"
                   @iasupload="formItemValue" @clear="formItemValue" :data-outername="formItem.attr && formItem.attr['data-outername']"
    />
    <div class="validation-error">
      <template v-if="validError.length > 0">
        <span v-for="error of validError">
          {{ error }}
        </span>
      </template>
      <template v-if="subFormError !== null">
        <span>{{subFormError}}</span>
      </template>
    </div>
  </div>
</template>

<script>
import MySelect from "@/components/forms/my-select/MySelect";
import FileUploader from "@/components/forms/FileUploader";
import {maska} from 'maska'
import Datetime from "@/components/forms/Datetime";
import Stars from "@/components/forms/Stars";
import Modal from "bootstrap/js/dist/modal"
import RadioButton from "@/components/forms/RadioButton";
import Wysiwyg from "@/components/forms/Wysiwyg.vue";
import Captcha from "@/components/forms/Captcha.vue";
import FilePickerForms from "@/components/forms/FilePickerForms.vue";
import Popper from "vue3-popper";

/**
 * Компонент с полями формы
 */
export default {
  name: "FormItem",
  components: {FilePickerForms, Captcha, Wysiwyg, RadioButton, Stars, Datetime, FileUploader, MySelect, Popper},
  directives: {maska},
  props: {
    item: Object,
    required: Boolean,
    signStep: Boolean,
    hiddenLabel: Boolean,
    draftSaved: Boolean,
    loaded: Boolean,
    propkey: String
  },
  data() {
    return {
      value: this.item.value || "",
      validError: [], // массив под ошибки
      formItem: this.item, // прием данных из props
      state: "", // состояние (необходимо для правил)
      maskInput: "",
      isFocused: false,
      marginTop: null,
      labelNormal: null,
      labelSmall: null,
      modalComponent: null,
      isFilled: false,
      productList: [],
      listToSend: [],
      modalShow: false,
      modalAddDisabled: true,
      subFormError: !this.item.attr?.rppvalid && this.item.attr?.rppcomment?.length ? this.item.attr?.rppcomment : null,
      popper: false,
    }
  },
  computed: {
    formItemClasses() {
      return [
        this.isFocused ? 'focus' : null,
        this.value.length > 0 || this.isFilled ? "filled" : null,
        this.formItem.options !== undefined && this.formItem.options.readonly ? "readonly" : null,
        this.formItem.type,
        this.subFormError !== null ? "sub-form-error" : null,
        this.formItem.options?.widget,
        this.validError.length ? "error" : null,
        this.formItem.hasOwnProperty('data-behaviour') && this.formItem.attr["data-behaviour"] ? 'animation-' + this.formItem.attr["data-behaviour"] : null,
      ]
    }
  },
  watch: {
    value(oldVal, newVal) {
      if (this.item.type !== "select") {
        this.subFormError = null
      } else {
        let _old, _new
        _old = Array.isArray(oldVal) ? oldVal.join(" ") : oldVal
        _new = Array.isArray(newVal) ? newVal.join(" ") : newVal
        if (_old !== _new) {
          this.subFormError = null
        }
      }

      this.animationBlock();
    },
    draftSaved() {
      if (this.formItem.type === "action_button" && this.draftSaved) {
        setTimeout(() => {
          this.fetchProductList()
        }, 1000)
      }
    }
  },
  mounted() {
    this.doMask()
    if (this.value) {
      this.validationHandler();

      this.animationBlock();
    }
    if (this.item.type === "checkbox") {
      this.formItem.checked = this.item.value === null ? false : this.item.value
    }
    if (this.item.value !== null && this.item.widget === "textarea" && this.item.options?.widget !== "wysiwyg") {
      let textArea = this.$el.querySelector("textarea")
      textArea.style.height = `${textArea.scrollHeight}px`
    }
    if (this.formItem.options && this.formItem.options.placeholder === undefined) {
      if (this.item.type === "string" || this.item.type === "select") {
        this.marginForPlaceLabel()
      }
    }
    if (this.formItem.type === "string" && this.value.split(".").every(item => !isNaN(parseInt(item)))) {
      this.value = this.value.replace(".", ",")
    }
    // todo: заменить позор на нормальный универсальный функционал
    if (this.item.attr && (this.item.attr.sourceDataMap === "Price" || this.item.attr.sourceDataMap === "CampaignProductionsDiscount")) {
      [...document.querySelectorAll("[data-srcmap='Price']"), ...document.querySelectorAll("[data-srcmap='CampaignProductionsDiscount']")]
        .forEach(item => item.addEventListener("input", this.calcHandler))
    }
  },
  methods: {
    calcHandler(event) {
      if (event.target.closest(".multiholder").querySelector("[data-srcmap='PriceAfterDiscount']")) {
        let discountString = event.target.closest(".multiholder").querySelector("[data-srcmap='CampaignProductionsDiscount']").value
        let priceString = event.target.closest(".multiholder").querySelector("[data-srcmap='Price']").value
        if (discountString.indexOf(",") !== -1) {
          discountString = discountString.replace(",", ".")
        }
        if (priceString.indexOf(",") !== -1) {
          priceString = priceString.replace(",", ".")
        }
        let discount = Number(discountString) || 0
        let price = Number(priceString) || 0
        event.target.closest(".multiholder").querySelector("[data-srcmap='PriceAfterDiscount']").value = Number(price * (1 - discount / 100)).toFixed(2).replace(".", ",")
        event.target.closest(".multiholder").querySelector("[data-srcmap='PriceAfterDiscount']").dispatchEvent(new Event("input"))
      }
    },
    async bankInfoByBic() {
      if (this.formItem.options?.actionData) {
        if (this.value.length === this.formItem.options.actionData.meta.whenLength) {
          const actionData = this.formItem.options.actionData
          const mapping = actionData.meta.mapping
          if (actionData) {
            await fetch(actionData.url.bicInfoUrl.replace(actionData.meta.bicReplace, this.value)).then(r => r.json()).then(data => {
              for (let item in mapping) {
                let target = document.querySelector(`[data-outername="${mapping[item]}"]`)
                if (target.nodeName === "SELECT") {
                  if (target.querySelector(`[value="${data[item]}"]`)) {
                    target.querySelector(`[value="${data[item]}"]`).selected = true
                    target.dispatchEvent(new CustomEvent("select", {
                      detail: {custom: true}
                    }))
                  }
                } else {
                  target.value = data[item]
                  target.dispatchEvent(new Event("input"))
                }
              }
            })
          }
        }
      }
    },
    chooseProduct(product) {
      product.Checked = !product.Checked
      if (product.Checked) {
        this.listToSend.push(product.Guid)
      } else {
        this.listToSend.splice(this.listToSend.indexOf(product.Guid), 1)
      }
      this.modalAddDisabled = this.listToSend.length === 0
    },
    modalOpenHandler(cb) {
      return this[cb]()
    },
    modalAddHandler(cb) {
      return this[cb]()
    },
    marginForPlaceLabel() {
      if (this.item.type === "string" || this.item.type === "select" && this.item.widget !== "choice-expanded") {
        if (typeof this.item.attr !== undefined && !this.item.attr?.placeholder) {
          const marginValue = this.$el.querySelector(".form-label").scrollHeight - this.$el.querySelector(".responsive-control").scrollHeight - 3
          this.marginTop = `margin-top: ${marginValue > 0 ? marginValue + 5 : 0}px`
        }
      }
    },
    /**
     * Функция для вывода маски, если она есть
     */
    doMask() {
      if (this.item.constraints) {
        this.item.constraints.forEach(constraint => {
          if (constraint.doMask) {
            this.maskInput = constraint.mask
          }
          /*else if (constraint.type === "Email") {
            this.maskInput = {mask: 'T*@E*', tokens: { 'T': {pattern: /[a-zA-Z0-9.+_\-]/}, 'E': {pattern: /[a-zA-Z0-9._\-]/} }}
          }*/
        })
      }
    },
    stringFocusHandler() {
      this.isFocused = true
      setTimeout(() => this.marginForPlaceLabel(), 250)
    },
    stringBlurHandler() {
      this.isFocused = false
      setTimeout(() => this.marginForPlaceLabel(), 250)
    },
    /**
     * Функция валидации вводимых данных
     */
    validationHandler() {
      if (this.formItem.options?.doAction && this.formItem.type === "string") {
        this[this.formItem.options?.doAction]()
      }
      if (this.item.constraints) {
        this.validError.length = 0;
        this.item.constraints.forEach(constraint => {
          switch (constraint.type) {
            case "NotBlank": // поле не должно быть пустым
              if (this.value.length === 0) {
                this.validError.push(constraint.message);
              }
              break;
            case "Email": // поле с электронной почтой
              const EMAIL_REGEX = /^([a-zA-Z0-9\.\+\_\-])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z0-9])+$/;
              if (EMAIL_REGEX.test(this.value) === false) {
                this.validError.push(constraint.message);
              }
              break;
            case "Regex" || "PhoneConstraint": // поле с правилом, заданным регулярным выражением
              const PATTERN = constraint.pattern.slice(1, -1);
              if (new RegExp(PATTERN).test(this.value) === false) {
                this.validError.push(constraint.message)
              }
              break
            case "Length":
              if (this.value.length > constraint.max) {
                this.validError.push(constraint.message)
              }
          }
        });
      }
    },
    /**
     * Функция для определения высоты textarea
     */
    textAreaHandler(event) {
      const target = event.target
      target.style.height = "auto"
      target.style.height = `${target.scrollHeight}px`
      this.validationHandler()
    },
    /**
     * Функция передачи события в родительский компонент
     * @param value - значение поля
     */
    formItemValue(value) {
      this.value = value
      this.$emit('form-item-value', this.value, this.item, this.state) // передача названия события, значения поля, самого поля и его состояния (для правил)
    },
    /**
     * Отдельная функция для чекбоксов
     * @param event
     * @param item
     */
    checkboxHandler(event, item) {
      if (this.loaded) {
        item.checked = event.target.checked
        this.state = item.checked ? "choosen" : "notchoosen" // изменение состояния чекбокса
        this.formItemValue(item.checked)
      }
    },
    selectFocusHandler(bool) {
      this.isFocused = bool
    },
    mbmAppendProductMultiOpen() {
      let btn = document.querySelector(`[data-modal="${this.formItem['full_name'].replace(/\[/g, '_').replace(/]/g, '')}"]`)
      let formGroup = btn.closest(".form-group").previousElementSibling || btn.closest(".form-group").nextElementSibling
      const isValid = Array.from(formGroup.querySelectorAll(".multiholder")).filter(group => {
        return [...group.querySelectorAll(".form-control:not([type='hidden']):not([type='checkbox'])")].filter(item => item.value).length > 0
      }).length > 0
      if (isValid) {
        this.$emit("modal-open", {type: "modal-open", valid: true})
      } else if (Array.from(formGroup.querySelectorAll(".multiholder")).length > 1) {
        this.$emit("modal-open", {type: "modal-open", valid: true})
      } else {
        this.$emit("modal-open", {type: "modal-open", valid: false})
      }
    },
    async fetchProductList() {
      let modal = new Modal(document.getElementById(this.formItem['full_name'].replace(/\[/g, '_').replace(/]/g, '')))
      modal.show()
      await fetch(this.formItem.options.actionData.url.listProducts).then(r => r.json()).then(data => {
        this.productList = data.reverse()
        window.scrollUp()
        window.hideLoader()
      }).catch(error => {
        window.showErrorMessage("Непредвиденная ошибка!")
        window.hideLoader()
      }) //this.formItem.options.actionData.url.listProducts
    },
    async mbmAppendProductMultiAdd() {
      const singleProductInfoUrl = this.formItem.options.actionData.url.singleProductInfoUrl,
            singleProductGuidPlace = this.formItem.options.actionData.meta.singleProductGuidPlace

      const btn = document.querySelector(`[data-modal="${this.formItem['full_name'].replace(/\[/g, '_').replace(/]/g, '')}"]`)
      let formGroup
      if (btn.closest(".form-group").previousElementSibling?.matches(".multigroup")) {
        formGroup = btn.closest(".form-group").previousElementSibling
      } else if (btn.closest(".form-group").nextElementSibling?.matches(".multigroup")) {
        formGroup = btn.closest(".form-group").nextElementSibling
      }
      const addMoreBtn = formGroup.querySelector(".add-more-place .btn")
      const multigroup = addMoreBtn.parentElement.previousElementSibling
      const checkValue = (group) => {
        return [...group.querySelectorAll(".form-control:not([type='hidden']):not([type='checkbox'])")].filter(item => item.value.length > 0).length > 0
      }
      const fillFormWithData = formData => {
        let _multigroups = Array.from(formGroup.querySelectorAll(".multiholder"))
        let oneGroup = _multigroups[_multigroups.length - 1]
        for (let item in formData) {
          if (typeof formData[item] !== "object" || Array.isArray(formData[item])) {
            if (oneGroup.querySelector(`[data-srcmap="${item}"]`) !== null && typeof oneGroup.querySelector(`[data-srcmap="${item}"]`) !== "undefined") {
              if (oneGroup.querySelector(`[data-srcmap="${item}"]`).nodeName !== "SELECT") {
                switch (typeof formData[item]) {
                  case "number": {
                    oneGroup.querySelector(`[data-srcmap="${item}"]`).value = String(formData[item]).replace(".", ",")
                    break
                  }
                  case "boolean": {
                    oneGroup.querySelector(`[data-srcmap="${item}"]`).checked = formData[item]
                    break
                  }
                  default: {
                    oneGroup.querySelector(`[data-srcmap="${item}"]`).value = formData[item]
                  }
                }
                oneGroup.querySelector(`[data-srcmap="${item}"]`).dispatchEvent(new Event("input"))
              } else {
                if (Array.isArray(formData[item])) {
                  formData[item].forEach(value => {
                    if (oneGroup.querySelector(`[data-srcmap="${item}"] [value="${value}"]`) !== null && typeof oneGroup.querySelector(`[data-srcmap="${item}"] [value="${value}"]`) !== "undefined") {
                      oneGroup.querySelector(`[data-srcmap="${item}"] [value="${value}"]`).selected = true
                    }
                  })
                } else {
                  if (oneGroup.querySelector(`[data-srcmap="${item}"] [value="${formData[item]}"]`) !== null && typeof oneGroup.querySelector(`[data-srcmap="${item}"] [value="${formData[item]}"]`) !== "undefined") {
                    oneGroup.querySelector(`[data-srcmap="${item}"] [value="${formData[item]}"]`).selected = true
                  }
                }
                oneGroup.querySelector(`[data-srcmap="${item}"]`).dispatchEvent(new Event("select"))
              }
            }
          } else {
            let dataItem = formData[item]
            for (let subItem in dataItem) {
              if (subItem === "size") {
                if (oneGroup.querySelector(`[data-srcmap="${item}.guid"]`) !== null && typeof oneGroup.querySelector(`[data-srcmap="${item}.guid"]`) !== "undefined") {
                  oneGroup.querySelector(`[data-srcmap="${item}.guid"]`).dataset.filesize = dataItem[subItem]
                }
              } else if (subItem === "url") {
                if (oneGroup.querySelector(`[data-srcmap="${item}.guid"]`) !== null && typeof oneGroup.querySelector(`[data-srcmap="${item}.guid"]`) !== "undefined") {
                  oneGroup.querySelector(`[data-srcmap="${item}.guid"]`).dataset.fileurl = dataItem[subItem]
                }
              } else {
                if (oneGroup.querySelector(`[data-srcmap="${item}.${subItem}"]`) !== null && oneGroup.querySelector(`[data-srcmap="${item}.${subItem}"]`) !== "undefined") {
                  oneGroup.querySelector(`[data-srcmap="${item}.${subItem}"]`).value = dataItem[subItem]
                  oneGroup.querySelector(`[data-srcmap="${item}.${subItem}"]`).dispatchEvent(new Event("change"))
                }
              }
            }
          }
        }
      }
      const promiseArr = this.listToSend.map(item => fetch(singleProductInfoUrl.replace(singleProductGuidPlace, item))
        .then(r => r.json()).then(d => Promise.resolve(d)).catch(err => window.showErrorMessage(err.message)))

      window.showLoader()
      Promise.all(promiseArr).then(promiseData => {
        if (checkValue(multigroup)) {
          addMoreBtn.dispatchEvent(new Event("click"))
        }
        new Promise(resolve => {
          promiseData.forEach((dataItem, index) => {
            setTimeout(() => {
              fillFormWithData(dataItem)
              if (index !== promiseData.length - 1) {
                addMoreBtn.dispatchEvent(new Event("click"))
              }
            }, 200)
          })
          setTimeout(() => resolve(), 200)
        }).then(() => {
          this.productList = []
          document.getElementById(this.formItem['full_name'].replace(/\[/g, '_').replace(/]/g, '')).querySelector(".btn-close").dispatchEvent(new Event("click"))
          document.documentElement.scrollTo(0, 0)
          this.$emit("product-update", true)
        })
      }).catch((e) => {
        if (e) {
          window.showErrorMessage(e)
        }
      })
    },
    // если есть связанное поле attr data-connected то вешаем на него анимацию
    animationBlock(){
      this.$nextTick(() => {
        if(this.item.hasOwnProperty('attr') && this.item.attr.hasOwnProperty('data-connected') && document.getElementById(this.item.attr['data-connected'])){
          const el = document.getElementById(this.item.attr['data-connected']),
              elAnimation = el.closest('.form-group'),
              animationClasses = elAnimation.getAttribute('class').split(' ').filter(className => className.includes('animation-'));
          if(this.value){
            elAnimation.classList.add('animation-active');
          } else {
            elAnimation.classList.remove('animation-active');
          }
          // для скрытия/закрытия "attr": {"data-behaviour": "hide"}
          if(animationClasses.includes('animation-hide')){
            elAnimation.classList.remove('d-none');
            elAnimation.style.height = elAnimation.scrollHeight + 'px';
            if(!this.value) {
              setTimeout(()=> {
                elAnimation.classList.add('animation-middle');
              });
            }
            setTimeout(()=>{
              if(this.value) {
                elAnimation.style.height = 'auto'
              } else {
                elAnimation.style.height = '0px'
                elAnimation.classList.add('d-none');
              }
              elAnimation.classList.remove('animation-middle');
            }, 300);
          }
        }
      });
    }
  }
}
</script>

<style>

</style>