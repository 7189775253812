<template>
  <a
      v-if="link"
      class="btn"
      :class="[buttonType, buttonDisabled]"
  >
    <slot name="before"></slot>
    <span v-if="img" class="sp_img"><img :src="img"></span>
    <span class="text"><slot/></span>
    <slot name="after"></slot>
  </a>
  <router-link
    :to="routerLink"
    v-else-if="routerLink"
    class="btn"
    :class="[buttonType, buttonDisabled]"
  >
      <slot name="before"></slot>
      <span v-if="img" class="sp_img"><img :src="img"></span>
      <span class="text"><slot/></span>
      <slot name="after"></slot>
  </router-link>
  <button
      v-else
      class="btn"
      :class="[buttonType, buttonDisabled]"
      :disabled="disabled"
  >
    <slot name="before"></slot>
    <span v-if="img"  class="sp_img"><img :src="img"></span>
    <span class="text"><slot/></span>
    <slot name="after"></slot>
  </button>
</template>

<script>

/**
 * Компонент кнопки
 * В пропсах принимает следующие значения
 * type: light - тип кнопки, по умолчанию default
 * disabled - для отключения
 */
export default {
  name: "ClButton",
  props: {
    type: String,
    link: Boolean,
    routerLink: String,
    disabled: Boolean,
    img: String
  },
  data() {
    return {
      buttonType: this.type ? `btn-${this.type}` : "btn-default",
      buttonDisabled: this.disabled ? "btn-disabled" : null
    }
  },
  watch: {
    disabled: {
      deep: true,
      handler(newValue) {
        this.buttonDisabled = this.disabled ? "btn-disabled" : null
      },
    }
  }
}
</script>

<style lang="scss">
@keyframes MoveScaleUpInitial {
  to {
    transform: translate3d(0, -105%, 0) scale3d(1, 2, 1);
    opacity: 0;
  }
}

@keyframes MoveScaleUpEnd {
  from {
    transform: translate3d(0, 100%, 0) scale3d(1, 2, 1);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.btn {
  min-height: 5.6rem;
  padding: 1.1rem 3.2rem;
  border-radius: .6rem;
  box-shadow: none;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 133.333%;
  letter-spacing: 0.036rem;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0);
  color: #ffffff;
  font-variant-numeric: lining-nums proportional-nums;

  &.btn-filter {
    justify-content: space-between;
    gap: 11px;
  }
  &.btn-lite {
    min-height: 38px;
    padding: 0 32px;
  }
  &.btn-middle {
    min-height: 48px;
    padding: 0 24px;
  }
  &.btn-small{

  }

  :disabled,
  &.btn-disabled {
    color: #6B7280 !important;
    opacity: 1;
    cursor: default;
    pointer-events: none;
    border: 1px solid #6B7280 !important;
    background: none !important;
    &.pointer-events-a {
      pointer-events: all;
    }
  }

  &.btn-default {
    background: #ED850A;
    color: #fff;
    border: 1px solid #ED850A;
    &:hover {
      border: 1px solid #F9BA6C;
      background: #ED870A;
      box-shadow: 0px 0px 0px 0px rgba(237, 135, 10, 0.05), 0px 1px 2px 0px rgba(237, 135, 10, 0.05), 0px 3px 3px 0px rgba(237, 135, 10, 0.04), 0px 8px 5px 0px rgba(237, 135, 10, 0.03), 0px 14px 6px 0px rgba(237, 135, 10, 0.01), 0px 22px 6px 0px rgba(237, 135, 10, 0.00), inset 0 0 0 1px #F9BA6C;

    }
    &:focus {
      border: 1px solid #FFF;
      background: #ED850A;
    }
    &:active {
      background: none;
      border: 1px solid #ED850A;
      color: #ED850A;
    }

  }

  &.btn-light {
    background: none;
    border: 1px solid #ED850A;
    color: #ED850A;
    &.color_or {
      color: #ED850A;
    }
    &.color_wh{
      color: white;
    }
    &:hover {
      border: 1px solid #ED850A;
      background: rgba(237, 133, 10, 0.20);
    }
    &:focus {
      background: none;
      border: 1px solid #ED850A;
    }
    &:active {
      background: #ED850A;
      &.color_or {
        color: #ffffff;
      }
    }
    &.active {
      background: #ED850A;
      border: 1px solid #ED850A;
      color: white;
      &:not(.btn-tag) {
        pointer-events: none;
      }
      &.color_or {
        color: #ffffff;
      }
    }
  }
  &.btn-light2 {
    color: #000106;
    background: none;
    border: 1px solid #ED850A;
    &.color_or {
      color: #ED850A;
    }
    &:hover {
      border: 1px solid #ED850A;
      background: rgba(237, 133, 10, 0.20);
    }
    &:focus {
      background: none;
      border: 1px solid #ED850A;
    }
    &:active {
      background: #ED850A;
      &.color_or {
        color: #ffffff;
      }
    }
    &.active {
      background: #ED850A;
      border: 1px solid #ED850A;
      pointer-events: none;
      &.color_or {
        color: #ffffff;
      }
    }
  }
  &.btn-light3 {
    color: #FFF;
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.20);
    &:hover {
      border: 1px solid #ED850A;
      background: rgba(237, 133, 10, 0.20);
    }
    &:focus {
      background: none;
      border: 1px solid #ED850A;
    }
    &:active {
      background: #ED850A;
    }
    &.active {
      background: #ED850A;
      border: 1px solid #ED850A;
      pointer-events: none;
    }
  }
  &.btn-grad {
    background: linear-gradient(90deg, #ED850A 2.48%, #FF6767 100%);
    color: #fff;
    border: 1px solid #ED850A;
    &:hover {
      background: #FF6767;
    }
    &:focus {
      background: #FF6767;
    }
    &:active {
      background: none;
      color: #ED850A;
    }
  }
  &.btn-wh {
    background: #ffffff;
    border: 1px solid #ffffff;
    color: #000106;
    //&:hover {
    //  border: 1px solid #ED850A;
    //  background: rgba(237, 133, 10, 0.20);
    //}
    //&:focus {
    //  background: none;
    //  border: 1px solid #ED850A;
    //}
    //&:active {
    //  background: #ED850A;
    //  &.color_or {
    //    color: #ffffff;
    //  }
    //}
  }
  &.btn-ico-one{
    padding: 1.6rem;
    height: 4.8rem;
    min-height: 4.8rem;
    isplay: flex;
    align-items: center;
    justify-content: center;
  }
  &.btn-disable-background{
    background-color: #2E3138;
    color: #000106;
    pointer-events: none;
    user-select: none;
    border-color: transparent;
  }

  .sp_img {
    flex: none;
    display: flex;
    align-items: center;
    margin-right: .8rem;
    img {
      display: block;
    }
  }
  &.btn-ico {
    .sp_img {
      margin-right: 8px;
    }
  }
  &.nomargin{
    .sp_img{
      margin: 0;
    }
  }
  &.btn-ico-right {
    .sp_img {
      margin-left: 8px;
      margin-right: 0;
      order: 10;
    }
    .circle_or {
      margin-left: 8px;
      flex: none;
    }
  }
  &.btn-ico2 {
    width: 56px;
    height: 56px;
    padding: 0;
    &.btn-middle {
      height: 48px;
      width: 48px;
    }
  }
  &.btn-op {
    border: none !important;;
    background: none !important;
    box-shadow: none !important;
    padding: 0;
    display: flex;
    align-items: center;
    gap: .8rem;
    min-height: 2rem;
  }
  &.btn-text-none {
    border-color: #ffffff;
    min-width: 48px;
    padding: 0;
    .text {
      display: none;
    }
    .sp_img {
      margin: 0;
    }
  }
  &.btn_connect {
    border-color: rgba(0, 0, 0, 0);
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;
    font-weight: 500;
    &:before {
      content: "";
      background: #D01737;
      position: absolute;
      right: 4px;
      top: 3px;
      width: 12px;
      height: 12px;
      border-radius: 100%;
    }
  }
  &.btn-circle {
    border-radius: 100%;
    width: 56px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.btn-width-mob-full {
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  &.btn-center-text{
    .text{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: .8rem;
    }
  }
}


@media (max-width: 991px) {
  .btn{
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 112.5%;
    letter-spacing: 0.32px;
  }
}
.btn-to-location{
  margin-top: 2.4rem;
}
</style>