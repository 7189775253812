<template>
  <teleport v-if="teleportComponents.blockProj" to="#block_proj">
    <cl-button type="light btn-middle btn-cent" @click="showSelectedForm()">
      <img src="/img/ico/orange_plus.svg">
      {{ dataInfo.textButton?.value }}
    </cl-button>
  </teleport>

  <cl-modal v-model="showForm" :closeFonActive="false" class=" modal_form modal_projects_add">
    <h2 v-if="dataInfo.title" class="title">{{ dataInfo.title.value }}</h2>
    <div ref="perfectScrollbar" class="perfect_scrollbar">
      <div ref="perfectScrollbarContent" class="perfect_scrollbar_cont">
        <row v-if="dataInfo.sections?.length > 0">
          <template v-for="section in dataInfo.sections">
            <column v-if="section.title" md="12">
              <h3 class="sub_title">{{section.title.value}}</h3>
            </column>
            <template v-if="section.items?.length > 0">
              <template v-for="item in section.items">
                <column md="12">
                  <card cardType="projects-add">
                    <div class="left_side">
                      <div class="ico" v-if="item.image || item.title">
                        <img v-if="item.image" :src="getImage(item.image)" alt="">
                        <div v-if="item.title" class="card_title" >{{ item.title.value }}</div>
                      </div>
                    </div>
                    <div class="center">
                      <div v-if="item.desc" class="card_desc">{{ item.desc.value }}</div>
                    </div>
                    <div class="right_side">
                      <div v-if="item.btnText && item.btnLink" class="card_btn">
                        <cl-button type="light" class="btn-middle" :link="true" :href="item.btnLink.value" target="_blank">{{item.btnText.value}}</cl-button>
                      </div>
                    </div>
                  </card>
                </column>
              </template>
            </template>
          </template>
        </row>
      </div>
    </div>
  </cl-modal>

  <cl-modal v-if="dataInfo.isWidget" class="modal-iframe-excursions" v-model="isShowModalIframe">
    <loader
        :animation-duration="3000"
        :size="60"
        :color="'#ED850A'"
        v-if="isShowModalIframeLoader"
    ></loader>
    <iframe v-show="!isShowModalIframeLoader" class="iframe-excursions" ref="iframeModalOr" :src="dataInfo.widgetLink.value"/>
  </cl-modal>
</template>

<script>

// С помощью teleportComponents, можно обозначать что блок с определенным id появился (см. компонент ProjectDetail.vue) и телепортировать в него компонент.
// Работает так:
// 1) Когда место куда необходимо телепортировать элемент появляется, то создается новый элемент списка в teleportComponents с помощью changeTeleportComponents (см. компонент ProjectDetail.vue)
// 2) В teleportComponents отслеживаются изменения и при появлении необходимого id создается блок с teleport (v-if='teleportComponents.blockProj')
// Таким образом блок с teleport будет правильно телепортироваться в нужное место.

import MeasureFormComponent from "@/components/measureForm.vue";
import {mapGetters} from "vuex";
import Column from "@/components/library/Column.vue";
import ClButton from "@/components/library/ClButton.vue";
import {list} from "quill/ui/icons";
import ClModal from "@/components/library/ClModal.vue";
import PerfectScrollbar from "perfect-scrollbar";

export default {
    name: "blockButtonKinokomisProj",
    components: {ClModal, ClButton, Column, MeasureFormComponent},
    props: {
        dataInfo: {
            type: Object,
            require: true
        },
    },
    data(){
        return{
            showForm: false,
            showMode: 'inline',
            formParams: {
                'appendCss': '/override/measure.css',
            },
            isMountedComponent: false,
            isShowModalIframe: false,
            isShowModalIframeLoader: true,
            /**
             * Perfect Scrollbar
             * @type {PerfectScrollbar | null}
             * @default null
             */
            ps: null,
            /**
             * Resize Observer
             * @type {ResizeObserver | null}
             * @default null
             */
            resizeObserver: null,
        }
    },
    computed: {
      list() {
        return list
      },
      ...mapGetters([
          'widthSite',
          'getUser',
          'teleportComponents',
      ])
    },
    methods: {
      showModalIframe(){
        this.isShowModalIframe = !this.isShowModalIframe;
        this.$nextTick(()=>{
          if(this.$refs.iframeModalOr){
            this.$refs.iframeModalOr.addEventListener('load', () => {
              this.isShowModalIframeLoader = false;
            });
          } else {
            this.isShowModalIframeLoader = false;
          }
        });
      },

      showSelectedForm() {
        if(this.dataInfo?.isWidget) {
          if(this.dataInfo?.widgetLink) {
            this.showModalIframe();
          }
        } else {
          this.showForm = !this.showForm;
        }
      },

      /**
       * Функция добавления perfect scrollbar
       */
      addPerfectScrollbar() {
        if(this.$refs.perfectScrollbar && this.$refs.perfectScrollbarContent) {
          this.ps = new PerfectScrollbar(this.$refs.perfectScrollbar);
          this.resizeObserver = new ResizeObserver(() => setTimeout(() => {
            this.ps.update();
          }, 15));
          this.resizeObserver.observe(this.$refs.perfectScrollbarContent);
        }
      }
    },
    watch: {
      showForm() {
        if(this.showForm) {
          this.$nextTick(this.addPerfectScrollbar);
        } else {
          if(this.resizeObserver) this.resizeObserver.disconnect();
        }
      },
    },
    mounted() {
      document.querySelector('body').classList.add('button_kinomiks');
    },
    beforeUnmount() {
      if(this.resizeObserver) this.resizeObserver.disconnect();
      document.querySelector('body').classList.remove('button_kinomiks');
    },
}
</script>

<style lang="scss" scoped>
  .btn-cent{
    .text {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1.2rem;
    }
    @media(max-width: 767px){
      width: 100%;
    }
  }

  .perfect_scrollbar {
    height: calc(100vh - 30px * 2  - 32px * 2 - 2.4rem - 3.2rem);

    &.ps--active-y {
      padding-right: 2.6rem;
    }

    .ps__rail-y {
      right: 5px;
    }

    .perfect_scrollbar_cont {
      display: flex;
      flex-direction: column;
    }
    
    @media (max-width: 991px) {
      height: calc(100vh - 40px * 2  - 32px * 2 - 2.4rem - 3.2rem);
    }
    
    @media (max-width: 767px) {
      height: calc(100vh - 20px * 2 - 2.4rem - 3.2rem);
    }
  }

  .row {
    gap: 1.6rem;
  }

  .modal_projects_add {
    max-height: 100vh;
    overflow: hidden;

    .title {
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 1.33;
      letter-spacing: 0.02em;
      margin-bottom: 2.4rem;
    }

    .sub_title {
      font-size: 2.4rem;
      font-weight: 400;
      line-height: 1.33;
      letter-spacing: 0.015em;
      text-align: center;
      margin-bottom: 0;
    }

    @media (max-width: 991px) {
      .modal-dialog {
        margin: 0;
      }
    }
    
    @media (max-width: 767px) {
      .sub_title {
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: 0.02em;
        text-align: left;
      }
    }
  }
</style>