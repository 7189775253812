<template>
  <loader
      v-if="isLoadingRemove"
      :animation-duration="3000"
      :size="60"
  ></loader>
  <div class="form-file" v-else>
    <div class="file_el_list" v-if="(modelValue && modelValue.length > 0) || (formItemAttr['data-url'] && formItemShow)">
      <template v-if="this.classFileView">
        <div class="card-document" v-for="file in (modelValue || [formItemAttr['data-url']])">
          <a v-bind="{href: file.name ? null : formItemAttr['data-url']}" target="_blank" class="dt1"><img src="/img/ico/icoDoc.svg" alt=""></a>
          <div class="dt2">
            <div class="card-document-top-over">
              <div class="card-document-top not_active">
                <div class="card-document-tit">
                  {{ file.name ? [...file.name.split('.')][0] : formItemAttr['data-name'] }}
                </div>
                <div class="krug_del krug_del_img" @click="clearInput(formItemAttr['data-fileid'])"></div>
              </div>
            </div>
            <div class="card-document-bot">
              <div class="card-document-format">{{ file.name ? [...file.name.split('.')].at(-1) : formItemAttr['data-url'].split('.').at(-1) }}<span v-if="file.size || formItemAttr['data-size']">, {{file.size ? formatBytes(file.size, 2) : formatBytes(formItemAttr['data-size'], 2)}}</span></div>
              <div v-if="!file.name" class="card-document-dow">
                <a :download="formItemAttr['data-name'] ? formItemAttr['data-name'] : 'file'" :href="[formItemAttr['data-url']]" target="_blank" class="a-or2">Скачать</a>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="file_el file_el_new1" v-for="file in (modelValue || [formItemAttr['data-url']])">
          <div class="file_td1">
            <div class="file_ico"></div>
          </div>
          <div class="file_td2 file_td2dop file_td2_text">
            <div class="file_tit plUpFilename">
              <div class="file_tit_flex" v-if="file.name">
                <div class="file_tit_flex_dt1 plUploadedFilename">{{ [...file.name.split('.')][0] }}</div>
                <div class="file_tit_flex_dt2 plUpExt2">.{{ [...file.name.split('.')].at(-1) }}</div>
              </div>
              <a v-else
                 class="file_tit_flex"
                 target="_blank"
                 :download="formItemAttr['data-name']"
                 :href="formItemAttr['data-url']">
                <div class="file_tit_flex_dt1 plUploadedFilename">{{ formItemAttr['data-name'] }}</div>
                <div class="file_tit_flex_dt2 plUpExt2">.{{ [...formItemAttr['data-url'].split('.')].at(-1) }}</div>
              </a>
            </div>
            <div class="file_text_st"><span class="file_st_green status_hold"><span>Документ загружен</span></span></div>
          </div>
          <div class="file_td2 file_td2_del"><span class="krug_del krug_del_img" @click="clearInput(formItemAttr['data-fileid'])"></span></div>
        </div>
      </template>
    </div>
    <span
        v-if="(!modelValue && !formItemAttr['data-url']) || (!formItemShow && !modelValue) || multiple"
        class="btn btn-default btn-middle" @click="open"><span class="sp_img"><img class="img_wh" src="/img/ico/upload.svg" alt=""></span>{{formItemAttr['button-text'] ? formItemAttr['button-text'] : "добавить файл"}}</span>
    <input
        hidden
        ref="input"
        type="file"
        @change="updateInput"
        :accept="accept"
        :name="name"
        :data-name="name"
        :id="id"
        :readonly="readonly"
        :size="size"
        class=""
    />
  </div>
</template>

<script>
/*
* id - id input
* name - name input
* readonly - readonly
* accept - допустимые расширения image/jpeg,image/png,image/gif
* size - допустимый размер
* multiple - множественный выбор
* modelValue
* */
import ClButton from "@/components/library/ClButton.vue";

export default {
  name: 'FilePickerForms',
  components: {ClButton},
  props: {
    id: {
      type: String
    },
    name: {
      type: String
    },
    readonly: {
      type: Boolean
    },
    modelValue: {
      type: [String, Number, Object]
    },
    accept: {
      type: String,
    },
    size: {
      type: String,
    },
    multiple: {
      type: Boolean
    },
    formItemAttr: {
      type: Object,
    },
    classFileView: {
      type: String,
    }
  },
  data() {
    return {
      formItemShow: true,
      isLoadingRemove: false
    }
  },
  watch:{
    modelValue(){
      this.$emit("changeInput", this.modelValue);
    }
  },
  methods: {
    open() {
      this.$refs.input.click();
    },
    updateInput(event) {
      this.$emit("update:modelValue", event.target.files);
    },
    clearInput(id){
      if(this.modelValue){
        this.$refs.input.value = null;
        this.$emit("update:modelValue", '');
      } else {
        this.isLoadingRemove = true;
        return this.axios
            .post(`/api/post/delete/file/${id}`)
            .then(response => {
              this.showInfo(false, 'Спасибо за внимание', 'Файл удален');
              this.formItemShow = false;
            })
            .catch(error => {
              this.showError(error);
            })
            .finally(() => {
              this.isLoadingRemove = false;
            });
      }

    },

    formatBytes(bytes, decimals) {
      if(bytes == 0) return '0 Bytes';
      let k = 1024,
          dm = decimals || 2,
          sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
          i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  },
}
</script>

<style lang="scss" scoped>

.card-document {
  display: flex;
  opacity: 1;
  padding: 1.6rem;
  gap: 1.6rem;
  border-radius: .6rem;
  background: rgba(25, 26, 31, 1);

  &:hover,
  &-show {
    //background-color: #0C0C0E;
  }
  > .dt1 {
    width: 5rem;
    flex: none;
  }
  > .dt2 {
    flex: 1;
  }
  &-top-over {
    margin-bottom: 0.8rem;
    &.collapse-start {
      max-height: 4.8rem;
      overflow: hidden;
      .card-document-tit {
        -webkit-line-clamp: inherit;
        line-clamp: inherit;
        height: auto;
      }
    }
  }
  &-top {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
    transition: height .3s ease;
    gap: 1.2rem;

    &.not_active {
      transition: none;
      cursor: default;
    }
    &:not(.show-collapse):not(.not_active) {
      height: 4.8rem !important;
    }
    &.show-collapse {
      .card-document-tit {
        -webkit-line-clamp: inherit;
        line-clamp: inherit;
        height: auto;
      }
      .card-document-str {
        transform: rotate(180deg);
      }
    }
  }
  &-tit {
    font-size: 1.6rem;
    line-height: 150%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 2.4rem;
    overflow: hidden;
  }
  &-str {
    flex: none;
    transition: all .3s ease;
    height: 2.4rem;
  }
  &-bot {
    display: flex;
    font-size: 1.4rem;
    gap: 2.4rem;
  }
  &-format {
    color: #9096A2;
    letter-spacing: 0.021rem;
    text-transform: uppercase;
    min-width: 10.5rem;
  }
  &-dow {}
  .krug_del {
    flex: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: url("../../../public/img/ico/trashOrg.svg") center center no-repeat ;
    background-size: contain;
    width: 2.4rem;
    height: 2.4rem;
    border: none;
  }
}

</style>