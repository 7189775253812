<template>
  <div v-if="dataInfo && Object.keys(dataInfo).length > 0" class="dop_info_img">
    <container>
      <div class="dop_info_img__cont" :class="{'dop_info_img_right': dataInfo.isRightPlace}">
        <div class="dt1">
          <div class="dop_info_img_card" :class="{'dop_info_img_card_backRotated': dataInfo.isRightPlace}" :style="dataInfo.backgroundImg ? getBkgrImage(dataInfo.backgroundImg, 'hd') : ''">
            <div class="div_cont">
              <div class="div_info">
                <h2 v-if="dataInfo.title" class="div_title">{{dataInfo.title.value}}</h2>
                <div v-if="dataInfo.text" class="div_text" :class="{'div_text__small': dataInfo.smallText}" v-html="dataInfo.text.value"></div>
              </div>
              <div v-if="dataInfo.buttons?.length > 0" class="div_btns">
                <template v-for="button in dataInfo.buttons">
                  <div v-if="button.text && (button.link || button.modalLink)" class="div_btn">
                    <cl-button v-if="button.link" link :href="button.link.value" @click="v_ym(button.yandexMetrikaTarget?.value)" :class="getButtonClass(button.type?.value)">
                      {{button.text.value}}
                      <template v-slot:after>
                        <svg class="svg-icon" width="24" height="24" :stroke="getSvgStroke(button.type?.value)">
                          <use xlink:href="/svg/icons.svg#move-up-right"></use>
                        </svg>
                      </template>
                    </cl-button>
                    <cl-button v-else-if="button.modalLink" @click="v_ym(button.yandexMetrikaTarget?.value); showModalIframe(button.modalLink.value);" :class="getButtonClass(button.type?.value)">
                      {{button.text.value}}
                      <template v-slot:after>
                        <svg class="svg-icon" width="24" height="24" :stroke="getSvgStroke(button.type?.value)">
                          <use xlink:href="/svg/icons.svg#move-up-right"></use>
                        </svg>
                      </template>
                    </cl-button>
                  </div>
                </template>
              </div>
              <div v-if="dataInfo.icon" class="div_icon">
                <img :src="getImage(dataInfo.icon, 'hd')" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="dt2">
          <div class="div_img">
            <img v-if="dataInfo.image" :src="getImage(dataInfo.image, 'hd')" alt="">
          </div>
        </div>
      </div>

      <cl-modal modal-type="iframe-excursions" v-model="isShowModalIframe">
        <loader
            :animation-duration="3000"
            :size="60"
            :color="'#ED850A'"
            v-if="isShowModalIframeLoader"
        ></loader>
        <iframe v-show="!isShowModalIframeLoader" class="iframe-excursions" ref="iframeModal" :src="iframeModalLink"/>
      </cl-modal>

    </container>
  </div>
</template>

<script setup lang="ts">
import ClButton from "@/components/library/ClButton.vue";
import ClModal from "@/components/library/ClModal.vue";
import Container from "@/components/library/Container.vue";
import {IDataInfoProps} from "@/interface/props/IDataInfoProps";
import {nextTick, Ref, ref} from "vue";
import Loader from "@/components/library/Loader.vue";

/**
 * Пропы
 */
const props = defineProps<IDataInfoProps>();

/**
 * Состояние открытой/закрытой модалки с iframe
 */
let isShowModalIframe: Ref<boolean> = ref(false);

/**
 * Состояние лоадера в модалке
 */
let isShowModalIframeLoader: Ref<boolean> = ref(true);

/**
 * Модалка с iframe
 */
const iframeModal: Ref<HTMLElement | null> = ref(null);

/**
 * Ссылка на виджет для iframe
 */
let iframeModalLink: Ref<string | undefined> = ref(undefined);

/**
 * Функция получения цвета иконки в кнопке по типу
 * @param {string} buttonType - тип кнопки
 */
function getSvgStroke(buttonType?: string): string {
  switch (buttonType) {
    case '1':
      return '#ffffff';
    case '2':
      return '#ed850a';
    case '3':
      return '#ffffff';
    default:
      return '#ffffff'
  }
}

/**
 * Функция получения класса кнопки по типу
 * @param {string} buttonType - тип кнопки
 */
function getButtonClass(buttonType?: string): string {
  switch (buttonType) {
    case '1':
      return 'div_custom_btn_or';
    case '2':
      return 'div_custom_btn_wh';
    case '3':
      return 'div_custom_btn_gr';
    default:
      return 'div_custom_btn_or';
  }
}

/**
 * Функция открытия модалки с iframe
 * @param {string} iframeLink - ссылка на виджет для iframe
 */
function showModalIframe(iframeLink?: string): void {
  iframeModalLink.value = iframeLink;
  isShowModalIframe.value = true;
  nextTick(()=>{
    if(iframeModal.value){
      iframeModal.value.addEventListener('load', () => {
        isShowModalIframeLoader.value = false;
      });
    } else {
      isShowModalIframeLoader.value = false;
    }
  });
}

</script>

<style lang="scss" scoped>

.dop_info_img {
  padding: 6rem 0;
  overflow: hidden;

  &__cont {
    display: flex;
    gap: 2.4rem;
  }

  .dt1, .dt2 {
    min-height: 50rem;
  }

  .dt1 {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .dt2 {
    width: 57.5%;
  }

  .div_img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 2.4rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 991px) {
    &__cont {
      flex-direction: column-reverse;
      gap: 1.6rem;
    }

    .dt1{
      min-height: 40.8rem;
    }

    .dt2 {
      width: auto;
      min-height: unset;
    }

    .div_img {
      min-height: 45rem;
      max-height: 50rem;
    }
  }
  
  @media (max-width: 767px) {
    .dt1 {
      min-height: 28.4rem;
    }

    .div_img {
      min-height: 21.4rem;
      max-height: 45rem;
    }
  }
}

.dop_info_img_card {
  flex: 1;
  border: 0.1rem solid rgba(23, 25, 28, 1);
  border-radius: 2.4rem;
  padding: 3.2rem;
  background: url(@/assets/img/dopInfoImgCardBackgroud.jpg) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;

  :deep(.ps__rail-y) {
    right: 0 !important;
  }

  .div_cont {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    flex: 1;
  }

  .div_info{
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .div_title {
    font-family: Playfair Display;
    font-size: 4.8rem;
    font-weight: 500;
    line-height: 1.16;
    letter-spacing: 0.02em;
    margin-bottom: 1.6rem;
    text-transform: unset;
  }

  .div_text {
    font-size: 2.4rem;
    line-height: 1.33;
    letter-spacing: 0.015em;

    &__small {
      font-size: 1.8rem;
      line-height: 1.33;
      letter-spacing: 0.015em;
    }

    :deep(p) {
      margin-bottom: 0.8rem;
    }

    :deep(ul) {
      margin-bottom: 0.8rem;

      li {
        padding-left: 2.7rem;
        position: relative;
      }

      li:before {
        display: inline-block;
        content: "";
        position: absolute;
        top: 0.55em;
        left: 1.1rem;
        width: 0.4rem;
        height: 0.4rem;
        background-color: white;
        border-radius: 50%;
      }
    }

    :deep(> :last-child) {
      margin-bottom: 0;
    }
  }

  .div_btns {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    :deep(.btn) {
      width: 100%;
      border-radius: 1.6rem;
    }
  }

  .div_custom_btn_or, .div_custom_btn_wh, .div_custom_btn_gr {
    justify-content: space-between;
  }

  .div_custom_btn_wh {
    color: black;
    background: white;
    border: none;
  }

  .div_custom_btn_gr {
    color: white;
    background: rgba(63, 63, 66, 1);
    border: none;
  }

  .div_icon {
    margin-top: auto;
    display: flex;
    justify-content: start;
    img {
      margin: 0 0 calc(-3rem - 3.2rem) calc(-3rem - 3.2rem);
      max-width: 30rem;
      max-height: 30rem;
    }
  }

  @media (max-width: 991px) {
    .div_title {
      font-size: 3.2rem;
      font-weight: 500;
      line-height: 1.25;
      letter-spacing: 0.02em;
      margin-bottom: 0.8rem;
    }

    .div_text {
      font-size: 2.4rem;
      line-height: 1.33;
      letter-spacing: 0.015em;
    }

    .div_icon {
      img {
        margin: 0 0 calc(-2rem - 3.2rem) calc(-2rem - 3.2rem);
      }
    }
  }
  
  @media (max-width: 767px) {
    padding: 1.6rem;

    .div_cont {
      gap: 0.8rem
    }

    .div_text {
      font-size: 1.6rem;
      line-height: 1.5;
      letter-spacing: 0.015em;
    }

    .div_icon {
      img {
        margin: 0 0 calc(-2rem - 1.6rem) calc(-2rem - 1.6rem);
        max-height: 18rem;
        max-width: 18rem;
      }
    }
  }
}

.dop_info_img_right {
  flex-direction: row-reverse;

  .dop_info_img_card {
    .div_icon {
      justify-content: end;
      img {
        margin: 0 calc(-3rem - 3.2rem) calc(-3rem - 3.2rem) 0;
      }
    }
  }

  @media (max-width: 991px) {
    flex-direction: column-reverse;

    .dop_info_img_card {
      .div_icon {
        img {
          margin: 0 calc(-2rem - 3.2rem) calc(-2rem - 3.2rem) 0;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .dop_info_img_card {
      .div_icon {
        img {
          margin: 0 calc(-2rem - 1.6rem) calc(-2rem - 1.6rem) 0;
        }
      }
    }
  }
}

.dop_info_img_card_backRotated {
  background: url(@/assets/img/dopInfoImgCardBackgroudRotated.jpg) no-repeat center center;
  background-size: cover;
}

</style>