<template>
  <div v-if="dataInfo && Object.keys(dataInfo).length !== 0" class="block_wrap pavilony_list">
    <container>
      <div v-if="dataInfo.title || (!dataInfo.isButtonHide && dataInfo.types)" class="div_tit">
        <div class="div_tit_inner">
          <h2 v-if="dataInfo.title">{{ dataInfo.title.value }}</h2>
          <div v-if="dataInfo.subTitle" class="div_sub_title">{{dataInfo.subTitle.value}}</div>
        </div>
        <template v-if="!dataInfo.isButtonHide && dataInfo.types">
          <div class="sub_slider_top">
            <a class="btn btn-light" target="_blank" :href="`/arenda_kinopomecheniy/pavilony?types=${dataInfo.types.value}`">{{dataInfo.buttonText ? dataInfo.buttonText.value : 'Посмотреть все'}}</a>
          </div>
        </template>
      </div>

      <loader
          v-if="isLoading"
          :animation-duration="3000"
          :size="60"
      ></loader>
      <not-info
          v-if="!isLoading && dataList?.length == 0"
          :title="`Здесь пока ничего нет`"
          :text="`Нет результатов`"
      >
      </not-info>
      <template v-if="!isLoading && dataList?.length > 0">
        <swiper
            :spaceBetween="24"
            :navigation="true"
            :threshold='10'
            :modules="modules"
            :loop="getSwiperLoop()"
            class="slide100h"
            :class="{'arrow_center': !scrollPagination, 'arrow_center_op': !scrollPagination, 'progress-bar-line': scrollPagination, 'progress-bar-line-button': scrollPagination}"
            :pagination="scrollPagination ? {type: 'progressbar'} : false"
            :centered-slides="centeredSlides"
            :slides-per-view="isNaN(Number(slidesPerView)) ? 'auto' : Number(slidesPerView)"
            :breakpoints="{
                  '992': {
                    slidesPerView: isNaN(Number(slidesPerViewLg)) ? 'auto' : Number(slidesPerViewLg),
                    centeredSlides: centeredSlidesLg,
                  },
                  '768': {
                    slidesPerView: isNaN(Number(slidesPerViewMd)) ? 'auto' : Number(slidesPerViewMd),
                    centeredSlides: centeredSlidesMd,
                  },
                }"
        >
          <swiper-slide
              :key='item.guid'
              v-for='item of dataList'
          >
            <card-rent2-pavilony :item="item" :routeLink="dataInfo.link?.value + item.guid" class="border_img"/>
          </swiper-slide>
          <template v-if="!dataInfo.isButtonHide && dataInfo.types">
            <div class="sub_slider_bottom">
              <a class="btn btn-light" :class="{'btn-middle': scrollPagination}" target="_blank" :href="`/arenda_kinopomecheniy/pavilony?types=${dataInfo.types.value}`">{{dataInfo.buttonText ? dataInfo.buttonText.value : 'Посмотреть все'}}</a>
            </div>
          </template>
        </swiper>
      </template>
    </container>
  </div>
</template>

<script>
/*
* types - тип для фильтра (обязательное поле)
* */
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Pagination} from "swiper";
import {mapGetters} from "vuex";
import CardRent2Pavilony from "@/components/cardRent2Pavilony.vue";

export default {
  name: "pavilonyListTypes",
  components: {
    CardRent2Pavilony,
    Swiper,
    SwiperSlide,
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
    /**
     * Настройка свайпера slidesPerView. Количество слайдов на странице для экрана больше 991px
     * @default 4
     */
    slidesPerViewLg : {
      type: String,
      default: '4',
    },
    /**
     * Настройка свайпера slidesPerView. Количество слайдов на странице для экрана больше 767px и меньше 991px
     * @default 2
     */
    slidesPerViewMd : {
      type: String,
      default: '2',
    },
    /**
     * Настройка свайпера slidesPerView. Количество слайдов на странице для экрана меньше 767px
     * @default 1
     */
    slidesPerView : {
      type: String,
      default: '1',
    },
    /**
     * Настройка свайпера. Добавление скролла
     * @default false
     */
    scrollPagination: {
      type: Boolean,
      default: false,
    },
    /**
     * Настройка свайпера. Центрирование слайдов, на размере экрана 0-767
     */
    centeredSlides: {
      type: Boolean,
      default: false,
    },
    /**
     * Настройка свайпера. Центрирование слайдов, на размере экрана 768-991
     */
    centeredSlidesMd: {
      type: Boolean,
      default: false,
    },
    /**
     * Настройка свайпера. Центрирование слайдов, на размере экрана 992-max
     */
    centeredSlidesLg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      getParams: {
        all: 1
      },
      dataList: [],
      controller: null,
      loopStatus: false,
    }
  },
  methods: {
    /**
     * Получить локации по ids
     * @param {Array<string>} ids - id локаций
     * @return {Promise<T | *[]>}
     */
    fetchDataByIds(ids) {
      const idsParam = ids.join(',');
      return this.axios
          .get(`/api/data/all-platforms?ids=${idsParam}`, { signal: this.controller.signal })
          .then(response => response.data.records)
          .catch(error => {
            this.showError(error);
            return [];
          });
    },

    /**
     * Получить локации по типу
     * @param {string} types - тип
     * @return {Promise<T | *[]>}
     */
    fetchDataByTypes(types) {
      const typesParam = types.value;
      return this.axios
          .get(`/api/data/all-platforms?types=${typesParam}`, { signal: this.controller.signal })
          .then(response => response.data.records)
          .catch(error => {
            this.showError(error);
            return [];
          });
    },

    /**
     * Добавить локации в свайпер по ids
     * @param {Array<string>} ids - id локаций
     */
    addLocationsByIds(ids) {
      this.isLoading = true;
      this.fetchDataByIds(ids).then(locations => {
        locations.forEach(location => {
          if (Array.isArray(this.dataList)) {
            if (!this.dataList.find(item => item.guid === location.guid)) {
              this.dataList.push(location);
            }
          }
        });
        this.isLoading = false;
      });
    },

    /**
     * Добавить локации в свайпер по типу
     * @param {string} types - тип
     */
    addLocationsByTypes(types) {
      this.isLoading = true;
      this.fetchDataByTypes(types).then(locations => {
        locations.forEach(location => {
          if (!this.dataList.find(item => item.guid === location.guid)) {
            this.dataList.push(location);
          }
        });
        this.isLoading = false;
      });
    },

    /**
     * Добавить локации в свайпер по ids и по типу
     * @param {Array<string>} ids - id локаций
     * @param {string} types - тип
     */
    addLocationsByBoth(ids, types) {
      this.isLoading = true;
      const idsPromise = this.fetchDataByIds(ids);
      const typesPromise = this.fetchDataByTypes(types);

      Promise.all([idsPromise, typesPromise]).then(results => {
        const [idsLocations, typesLocations] = results;
        const allLocations = [...idsLocations, ...typesLocations];

        allLocations.forEach(location => {
          if (!this.dataList.find(item => item.guid === location.guid)) {
            this.dataList.push(location);
          }
        });

        this.isLoading = false;
      });
    },

    /**
     * Получить значение loop для свайпера в зависимости от экрана
     * @return {boolean} - значение для loop
     */
    getSwiperLoop() {
      if(this.widthSite < 768) {
        return isNaN(Number(this.slidesPerView)) ? true : this.dataList.length >= Number(this.slidesPerView)
      } else if(this.widthSite < 992) {
        return isNaN(Number(this.slidesPerViewMd)) ? true : this.dataList.length >= Number(this.slidesPerViewMd)
      } else {
        return isNaN(Number(this.slidesPerViewLg)) ? true : this.dataList.length >= Number(this.slidesPerViewLg)
      }
    }
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ]),
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    }
  },
  created() {
    this.controller = new AbortController();
  },
  mounted() {
    const ids = Array.isArray(this.dataInfo.listLocations)
        ? this.dataInfo.listLocations.map(location => location.id.value)
        : [];

    if (ids.length > 0 && this.dataInfo.types) {
      this.addLocationsByBoth(ids, this.dataInfo.types);
    } else if (ids.length > 0) {
      this.addLocationsByIds(ids);
    } else if (this.dataInfo.types) {
      this.addLocationsByTypes(this.dataInfo.types);
    }
  },
  beforeUnmount() {
    this.controller.abort();
    this.controller = null;
  }
}
</script>

<style lang="scss" scoped>
.pavilony_list {
  padding: 64px 0;

  .div_tit {
    display: flex;
    justify-content: space-between;
    margin-bottom: 64px;

    .btn{
      height: fit-content;
    }
  }

  .div_tit_inner {
    h2{
      margin-bottom: 0.8rem;
    }
    .div_sub_title {
      text-align: center;
      margin-bottom: 0.8rem;
    }
    & > :last-child {
      margin-bottom: 0;
    }
  }

  .sub_slider_top {
    display: block;
  }

  .sub_slider_bottom{
    margin-top: 2.4rem;
    display: none;
    .btn{
      width: 100%;
    }
  }

  @media (max-width: 991px) {
    .sub_slider_top {
      display: none;
    }

    .sub_slider_bottom{
      display: block;
    }
  }
}

.pavilony_list_v2 {
  padding: 6rem 0;

  .div_tit {
    justify-content: center;
    gap: 0.8rem;
    margin-bottom: 2.4rem;
  }

  h2 {
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.02em;
    text-align: center;
  }

  .div_sub_title {
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.015em;
    color: #6B7280;
  }

  .sub_slider_top {
    margin-left: auto;
    display: none;
  }

  .sub_slider_bottom{
    display: block;
    margin-top: 0;
    grid-area: btn;
    max-width: 30rem;
    .btn {
      width: auto;
    }
  }

  :deep(.progress-bar-line) {
    grid-template-areas:
                          "a a a a"
                          "b c d btn";
  }

  @media(max-width: 991px) {
    h2 {
      font-size: 2.4rem;
      line-height: 1.33;
    }

    .sub_slider_bottom{
      display: flex;
      justify-content: center;
      max-width: none;
      .btn {
        width: auto;
      }
    }

    :deep(.progress-bar-line) {
      grid-template-areas:
                          "a a a"
                          "d d d"
                          "btn btn btn";

      .swiper-button-prev, .swiper-button-next{
        display: none;
      }

      .swiper-slide {
        max-width: 34.3rem;
      }
    }
  }

  @media(max-width: 767px) {
    .div_sub_title {
      font-size: 1.4rem;
      line-height: 1.428;
    }

    .sub_slider_bottom{
      .btn {
        width: 100%;
      }
    }
  }
}
</style>