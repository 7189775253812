<template>
  <modal-info
      v-model="modalShow"
      :modal-params="{...modalDeleteApplicationStaticParams, ...modalDeleteApplicationDynamicParams}"
  />
</template>

<script setup lang="ts">
import ModalInfo from "@/components/modalInfo.vue";
import {IModalInfoModalParams} from "@/interface/IModalInfo";
import axios from "axios";
import router from "@/router";
import {IDataInfoProps} from "@/interface/props/IDataInfoProps";
import {ref, Ref} from "vue";

/**
 * Пропы
 */
const props = defineProps<IDataInfoProps>();

/**
 * Статичные параметры модалки предупреждения об удалении заявки
 * @default {
 *   active: {
 *     value: true
 *   },
 *   isBlackColor: dataInfo.modalDeleteIsBlackColor,
 *   buttonsReverse: dataInfo.modalDeleteButtonsReverse,
 *   isFonClose: false,
 * }
 */
const modalDeleteApplicationStaticParams: IModalInfoModalParams = {
  active: {
    value: true
  },
  isBlackColor: props.dataInfo.modalDeleteIsBlackColor,
  buttonsReverse: props.dataInfo.modalDeleteButtonsReverse,
  isFonClose: false,
};

/**
 * Динамические параметры модалки предупреждения об удалении заявки
 * @default {}
 */
let modalDeleteApplicationDynamicParams: Ref<IModalInfoModalParams> = ref({});

/**
 * Управление отображения модалки
 * @default false
 */
let modalShow: Ref<boolean> = ref(false);

/**
 * Гуид заявки, которую необходимо удалить
 * @default ''
 */
let modalDeleteApplicationGuid: Ref<string | undefined> = ref('');

/**
 * Страница на которую будет происходить редирект
 * @default ''
 */
let redirectAfterSuccess: Ref<string | undefined> = ref('');

/**
 * Функция открытия модалки с предупреждением об удалении заявки
 * @param {string | undefined} guid - guid для удаления заявки
 */
function openModalApplicationDelete(guid?: string): void {
  modalDeleteApplicationGuid.value = guid;
  if(props.dataInfo.modalDeleteRedirect && props.dataInfo.modalDeleteRedirect.value !== window.location.href && props.dataInfo.modalDeleteRedirect.value !== window.location.pathname) redirectAfterSuccess.value = props.dataInfo.modalDeleteRedirect.value;
  modalDeleteApplicationDynamicParams.value = {
    icon: props.dataInfo.modalDeleteIcon,
    title: props.dataInfo.modalDeleteTitle,
    text: props.dataInfo.modalDeleteText,
    buttons: [
      {
        text: props.dataInfo.modalDeleteBtnCancelText,
        lightType: props.dataInfo.modalDeleteBtnCancelLightType,
        icon: props.dataInfo.modalDeleteBtnCancelIcon,
        func: () => {
          closeModalApplicationDelete(false);
        },
      },
      {
        text: props.dataInfo.modalDeleteBtnApplyText,
        lightType: props.dataInfo.modalDeleteBtnApplyLightType,
        icon: props.dataInfo.modalDeleteBtnApplyIcon,
        func: deleteApplication,
      },
    ],
    loader: false,
  };
  modalShow.value = true;
}

/**
 * Функция удаления заявки
 */
function deleteApplication() {
  if(!modalDeleteApplicationDynamicParams.value.loader) {
    modalDeleteApplicationDynamicParams.value = {
      icon: props.dataInfo.modalDeleteIconDeleting,
      title: props.dataInfo.modalDeleteTitleDeleting,
      text: props.dataInfo.modalDeleteTextDeleting,
      loader: true,
    };
    axios
        .post(`/api/data/draft-delete/one/${modalDeleteApplicationGuid.value}`)
        .then(response => {
          modalDeleteApplicationDynamicParams.value = {
            icon: props.dataInfo.modalDeleteIconSuccess,
            title: props.dataInfo.modalDeleteTitleSuccess,
            text: props.dataInfo.modalDeleteTextSuccess,
            buttons: [
              {
                text: props.dataInfo.modalDeleteBtnCancelTextSuccess,
                lightType: props.dataInfo.modalDeleteBtnCancelLightTypeSuccess,
                icon: props.dataInfo.modalDeleteBtnCancelIconSuccess,
                func: () => {
                  closeModalApplicationDelete(true);
                },
              },
            ],
            loader: false,
          };
        })
        .catch(error => {
          modalDeleteApplicationDynamicParams.value = {
            icon: props.dataInfo.modalDeleteIconError,
            title: props.dataInfo.modalDeleteTitleError,
            text: props.dataInfo.modalDeleteTextError,
            buttons: [
              {
                text: props.dataInfo.modalDeleteBtnCancelTextError,
                lightType: props.dataInfo.modalDeleteBtnCancelLightTypeError,
                icon: props.dataInfo.modalDeleteBtnCancelIconError,
                func: () => {
                  closeModalApplicationDelete(false);
                },
              },
              {
                text: props.dataInfo.modalDeleteBtnApplyTextError,
                lightType: props.dataInfo.modalDeleteBtnApplyLightTypeError,
                icon: props.dataInfo.modalDeleteBtnApplyIconError,
                func: deleteApplication,
              },
            ],
            loader: false,
          };
        });
  }
}

/**
 * Функция закрытия модалки о предупреждении об удалении заявки
 * @param {boolean} isApplicationSuccessShow - состояние, которое показывает была ли удалена заявка
 */
function closeModalApplicationDelete(isApplicationSuccessShow: boolean): void {
  if(isApplicationSuccessShow) {
    modalShow.value = false;
    if(redirectAfterSuccess.value) {
      router.push(redirectAfterSuccess.value);
    } else {
      router.go(0);
    }
  } else {
    modalShow.value = false;
  }
}

defineExpose({
  openModalApplicationDelete
})
</script>

<style lang="scss" scoped>

</style>