<template>
  <card class="film_project_card" card-type="trusted2" @click="$router.push(`/lk/filmProjects/project/${item.guid}`)">
    <div class="card_mobile d-block d-md-none">
      <div class="div_tag">
        <span class="sp_all"><span class="sp1">Номер проекта {{ item.id }}</span></span>
      </div>
      <h3 v-if="item?.name">{{ item.name }}</h3>
      <div class="div_tag">
              <span class="sp_all">
                  <span class="sp1">Дата изменения
                    <template v-if="item?.updated !== null">{{ $filters.ruDate6(new Date(item.updated)) }}</template>
                    <template v-else>{{ $filters.ruDate6(new Date(item.created)) }}</template>
                  </span>
                </span>
      </div>
    </div>
    <div class="td1">
      <template v-if="item?.logo?.imageUrl">
        <img :src="item?.logo.imageUrl" alt="">
      </template>
      <template v-else>
        <img class="not_img" src="/img/project_empty_img.jpg" alt=""/>
      </template>
    </div>
    <div class="td2">
      <div class="div_tag d-none d-md-block">
        <span class="sp_all"><span class="sp1">Номер проекта  {{ item.id }}</span></span>
        <span class="sp_all"><span class="sp1">Дата изменения  <template v-if="item?.updated !== null">{{ $filters.ruDate6(new Date(item.updated)) }}</template><template v-else>{{ $filters.ruDate6(new Date(item.created)) }}</template></span></span>
      </div>
      <h3 class="d-none d-md-block" v-if="item?.name">{{ item.name }}</h3>
      <div class="div_type">
        <template v-if="item?.typeAudio?.name || item?.typeAudio?.comment">
          <template v-if="item?.typeAudio?.comment">
            {{ item.typeAudio.comment }}
          </template>
          <template v-else>
            {{ item.typeAudio.name }}
          </template>
        </template>
        <template v-if="(item?.typeAudio?.name || item?.typeAudio?.comment) && (item?.formatAudio?.name || item?.formatAudio?.comment)">&nbsp;/&nbsp;</template>
        <template v-if="item?.formatAudio?.name || item?.formatAudio?.comment">
          <template v-if="item?.formatAudio?.comment">
            {{ item.formatAudio.comment }}
          </template>
          <template v-else>
            {{ item.formatAudio.name }}
          </template>
        </template>
      </div>
      <div class="div_type" v-if="item?.fioDirector !== null && item.fioDirector"><span class="sp_all"><span class="sp1">Режиссер:</span> {{ item.fioDirector }}</span></div>
      <div class="div_type" v-if="item?.claimCount || item?.company?.name">
        <span class="sp_all" v-if="item?.claimCount"><span class="sp1">Кол-во заявок:</span>
          <router-link :to="{path: `/lk/filmProjects/project/${item.guid}`, query: {scrollTo: 'applications'}}" class="sp_link" @click="(event) => event.stopPropagation()">
            {{item.claimCount}}
          </router-link>
        </span>
        <span class="sp_all" v-if="item?.company?.name"><span class="sp1">Локейшн-менеджер</span> {{ item.company.name }}</span></div>
      <div class="div_type div_type2">
        <div class="sp_all">
          <span class="sp1">УНФ</span>
          <template v-if="item?.nationalFilm && item?.natFilmCertificate?.[0]">
            <svg class="svg-icon" width="24" height="24" stroke="#10B981">
              <use xlink:href="/svg/icons.svg#check"></use>
            </svg>
          </template>
          <template v-else>
            <svg class="svg-icon" width="24" height="24" stroke="#F43F5E" >
              <use xlink:href="/svg/icons.svg#close"></use>
            </svg>
          </template>
        </div>
        <div class="sp_all">
          <span class="sp1">ФСО</span>
          <template v-if="item?.fso && item?.fileFso?.[0]">
            <svg class="svg-icon" width="24" height="24" stroke="#10B981">
              <use xlink:href="/svg/icons.svg#check"></use>
            </svg>
          </template>
          <template v-else>
            <svg class="svg-icon" width="24" height="24" stroke="#F43F5E" >
              <use xlink:href="/svg/icons.svg#close"></use>
            </svg>
          </template>
        </div>
      </div>
      <Popper
          class="div_buttons"
          @click.stop
          @open:popper="isPopperOpen = true"
          @close:popper="isPopperOpen = false"
      >
        <div class="div_buttons_ev">
          <svg v-if="!isPopperOpen" class="svg-icon" width="24" height="24" stroke="#ffffff">
            <use xlink:href="/svg/icons.svg#button"></use>
          </svg>
          <svg v-else class="svg-icon svg-close" width="24" height="24" stroke="#ED850A">
            <use xlink:href="/svg/icons.svg#close"></use>
          </svg>
        </div>
        <template #content>
          <div class="div_buttons_mod">
            <div>
              <cl-button class="btn-op"  @click="$router.push(`/lk/filmProjects/project_print/${item.guid}`)">
                <template v-slot:before>
                  <svg class="svg-icon" width="24" height="24" stroke="#ffffff">
                    <use xlink:href="/svg/icons.svg#file-text"></use>
                  </svg>
                </template>
                Скачать PDF
              </cl-button>
            </div>
            <div>
              <cl-button class="btn-op" @click="$router.push(`/lk/filmProjects/editProject/${item.guid}`)">
                <template v-slot:before>
                  <svg class="svg-icon" width="24" height="24" stroke="#ffffff">
                    <use xlink:href="/svg/icons.svg#pensil"></use>
                  </svg>
                </template>
                Редактировать</cl-button>
            </div>
            <div>
              <cl-button class="btn-op" @click="openModalDelete()">
                <template v-slot:before>
                  <svg class="svg-icon" width="24" height="24" stroke="#ffffff">
                    <use xlink:href="/svg/icons.svg#trash"></use>
                  </svg>
                </template>
                Удалить</cl-button>
            </div>
          </div>
        </template>
      </Popper>

    </div>
  </card>
</template>

<script setup lang="ts">
import Card from "@/components/library/Card.vue";
import ClButton from "@/components/library/ClButton.vue";
import {IDataInfoPropsValues} from "@/interface/props/IDataInfoProps";
import {ref, Ref} from "vue";

const props = defineProps<{
  item: IDataInfoPropsValues
}>();

const emit = defineEmits({
  openModalDeleteEmit: (item?: {guid?: string, name?: string}) => {
    return true;
  },
});

let isPopperOpen: Ref<boolean> = ref(false);

function openModalDelete(): void {
  emit('openModalDeleteEmit', {guid: props.item.guid, name: props.item.name});
}

</script>

<style lang="scss" scoped>
.film_project_card {
  :deep(.card-holder) {
    .div_tag {
      gap: 1rem;
      font-size: 1.2rem;
    }

    .td1 {
      overflow: hidden;
      border-radius: 0.8rem;
      width: auto;
      height: 20.2rem;
      aspect-ratio: 3 / 4;

      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .not_img{
      width: 100%;
      height: 100%;
      border-radius: 1.2rem;
    }
  }

  @media (max-width: 1024px) {
    :deep(.card-holder) {
      flex-direction: row;
    }
  }

  @media (max-width: 767px) {
    :deep(.card-holder) {
      flex-direction: column;
      gap: 1.6rem;

      .td1 {
        height: 24rem;
        width: fit-content;
      }
    }
  }
}
</style>