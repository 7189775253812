<template>
  <div class="schedule_kino sub_block_black_wrapper p-0" v-show="calendars.length > 0">
    <div class="sub_block_black">
    <container>
      <h2 class="fs3 d-none d-lg-block" v-if="dataInfo.title">{{ dataInfo.title.value }}</h2>
      <calendar-carousel
          class="afisha_calendar"
          @change="changeDay"
          :period="false"
          :activeDates="calendars.map(dateString => new Date(dateString))"
          :chooseFirstActive="true"
          :firstActiveDate="true"
          :indexCalendar="'keyElCalrinomos'"
      />
      <loader
          v-if="isLoading"
          :animation-duration="3000"
          :size="60"
      ></loader>
      <div class="about_film_list" v-else-if="films && Object.keys(films).length > 0">
        <template v-for="(movie, key) in films" :key="key">
          <div class="about_film_row" v-if="movie?.cinemas && Object.keys(movie.cinemas).length > 0">
            <div class="dt1" :class="{'not_img': !movie.photos || !movie.photos[0].imageUrl}">
              <img :src="movie.photos[0].imageUrl" alt="" v-if="movie.photos && movie.photos[0].imageUrl">
            </div>
            <div class="dt2">
              <div class="div_tit" v-if="movie.name">{{ movie.name }}</div>
              <div class="div_text">
                <template v-if="movie.year">{{ movie.year }}, </template>
                <template v-if="movie.genres && movie.genres.length > 0"
                          v-for="(genres, index) of movie.genres" :key="genres.name">{{ genres.name }}<template v-if="index + 1 < movie.genres.length">, </template> </template>
              </div>
            </div>
            <div class="dt3">
              <div class="list_time">
                <template v-for="(seance, key) in Object.values(movie.cinemas)[0].seanceInfo" :key="key">
                  <div
                      class="item"
                      @click="v_ym(dataInfo?.yandexMetrikaTargetTime?.value); setFormParamsSeance(seance, key);"
                      v-if="seance?.timeStart"
                      :class="{
                      'active': seanceGuid == seance.guid,
                      'not_active_seance': !isActiveSeance(seance),
                      }"
                  >
                    {{ seance.timeStart.split(":")[0] }}:{{ seance.timeStart.split(":")[1] }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>

      <cl-modal class="modal-iframe-excursions" v-model="isShowModalReg" :closeStandart="true">
        <iframe class="iframe-excursions" ref="iframeTypeExcursionsMoskino" :src="'https://online.kinopark.moscow/basketiframe?name=' + externalCodeIframe"
        />
      </cl-modal>
    </container>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, onBeforeUnmount, reactive, getCurrentInstance, defineComponent} from "vue";
import axios from "axios";
import Container from "@/components/library/Container.vue";
import calendarCarousel from "@/components/calendarCarousel.vue";
import ClModal from "@/components/library/ClModal.vue";
import {ticketmanagerAdd} from "@/assets/js/ticketmanagerAdd";


defineComponent({
  name: 'movieShowSchedule',
});

const props = defineProps({
  /**
   * получаем инфу из админки
   * @property {String} title - Заголовок
   * @property {String} regEventVenues - Место проведения Id для regEventVenues
   * @property {String} types - Категория Id для types
   */
  dataInfo: Object
})

/**
 * переводим дату в нужный формат
 * @param date дата
 * @returns {`${string}.${string}.${number}`}
 */
const formatDate = (date) => {
  date = date ? new Date(date) : new Date();
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}

/**
 * Блок по календарю
 */


/**
 * Хук для управления AbortController
  * @type {AbortController}
 */
let controller = new AbortController();

onBeforeUnmount(() => {
  controller.abort();
  controller = null;
});

/**
 * позволяет получить доступ к внутреннему экземпляру компонента
 */
const {proxy} = getCurrentInstance();

/**
 * массив дат
 * @type {UnwrapNestedRefs<*[]>}
 */
const calendars = reactive([]);

/**
 * Флаг загрузки данных
 * @type {Boolean}
 * @default false
 */
const isLoadingCalendars = ref(true);

/**
 * Функция для получения списка фильмов
 */
const getCalendars = async () => {
  try {
    isLoadingCalendars.value = true;
    /**
     * параметры запроса
     * @type {{dateFrom: `${string}.${string}.${number}`, regEventVenues}}
     */
    const paramsCalendars = {
      regEventVenues: props.dataInfo.regEventVenues.value,
      dateFrom: formatDate(),
    };
    if(props.dataInfo?.types?.value){
      paramsCalendars.types = props.dataInfo.types.value;
    }
    if(props.dataInfo?.isNotEvent?.value){
      paramsCalendars.isNotEvent = props.dataInfo.isNotEvent.value;
    }
    const response = await axios.get('/api/data/cinema-seances/calendars', {
      params: paramsCalendars,
      signal: controller.signal
    });
    calendars.splice(0, calendars.length, ...response.data);

    const firstDateEqualOrAfterToday = [...calendars]
        .map(dateString => new Date(dateString))
        .find(date => date.setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0));


    getFilms(firstDateEqualOrAfterToday);

  } catch (error) {
    proxy.showError(error);
  } finally {
    isLoadingCalendars.value = false;
  }
}

/** список фильмов */

/**
 * Список фильмов
 * @type {Array}
 * @default {}
 */
const films = ref({});
/**
 * параметры для поиска фильмов
 * @type {UnwrapNestedRefs<{date: `${string}.${string}.${number}`, regEventVenues: string}>}
 */
const getParams = reactive({
  regEventVenues: props.dataInfo.regEventVenues.value,
  date: formatDate(),
});
if(props.dataInfo?.types?.value){
  getParams.types = props.dataInfo.types.value;
}
if(props.dataInfo?.isNotEvent?.value){
  getParams.isNotEvent = props.dataInfo.isNotEvent.value;
}

/**
 * Реактивное хранилище для данных
 * @type {Object}
 * @default {}
 */
const data = reactive({})
/**
 * Флаг загрузки данных
 * @type {Boolean}
 * @default true
 */
const isLoading = ref(true);

/**
 * Функция для получения списка фильмов
 * @param {string} startDate - дата для поиска
 * @param {Boolean} isLoadMore - Флаг загрузки дополнительных данных
 * @param {Boolean} isLoadPaginator - Флаг загрузки данных для пагинации
 */
const getFilms = async (startDate, isLoadMore, isLoadPaginator) => {

  getParams.date = formatDate(startDate);

  if (!isLoadMore && !isLoadPaginator) {
    delete getParams.page;
  }
  if (!isLoadMore) {
    isLoading.value = true;
  }
  try {
    isLoading.value = true;
    const response = await axios.get('/api/data/cinema-seances', {
      params: getParams,
      signal: controller.signal
    });

    if (isLoadMore) {
      //films.push(Array.from(...response.data));
      films.value = {...films.value, ...response.data};
    } else {
      //films.splice(0, films.length, Array.from(...response.data));
      films.value = response.data;
    }
    //Object.assign(data, response.data);

  } catch (error) {
    proxy.showError(error);
  } finally {
    isLoading.value = false;
  }
}

/**
 * события нажатия по календарю
 * @param date - дата
 */
const changeDay = (date) => {
  getFilms(date.start);
}

/**
 * проверка на активный сеанс
 * @param seance - инфо о сеансе
 * @returns {boolean}
 */
const isActiveSeance = (seance) => {
  if (seance?.date && seance.timeStart) {
    const seanceDate = new Date(seance.date);
    const timeSplit = seance.timeStart.split(":");
    seanceDate.setHours(timeSplit[0], timeSplit[1], timeSplit[2]);

    return new Date() <= seanceDate;
  }
}

/**
 * проверка на externalCode
 * @type {null | string}
 */
let externalCodeIframe = ref(null);
/**
 * открытие модалки
 * @type {Ref<UnwrapRef<boolean>>}
 * @default false
 */
const isShowModalReg = ref(false);
/**
 * получение инфы для модалки и ее открытие
 * @param seanceInfo - инфо о сеансе
 */
let seanceGuid = ref(null);
const setFormParamsSeance = (seanceInfo) => {
  if(seanceInfo.guid) seanceGuid.value = seanceInfo.guid;
  ticketManager.session('fcc1d4dd-23b0-42b5-a428-dcd09b0037ea', `${seanceInfo.externalCode}`)
}

/**
 * монтируем компонене
 */
onMounted(() => {
  getCalendars();

  ticketmanagerAdd();
});
</script>

<style lang="scss" scoped>
.schedule_kino {
  //margin-bottom: 80px;
  padding: 6rem 0 8rem !important;
  h2 {
    text-align: center;
    margin-bottom: 1.6rem;
  }
  .calendar_swiper {
    margin-bottom: 32px;
    :deep(.swiper-button) {
      background: #000106;
      border-radius: 0;
      height: 56px;
    }
    :deep(.swiper) {
      width: calc(1320px - 56px - 56px - 24px - 120px);
      @media(max-width: 1440px){
        width: calc(100vw - 56px * 2 - 44px * 2 - 140px);
      }
      @media(max-width: 991px){
        width: calc(100vw - 56px - 56px - 24px - 50px * 2);
      }
    }
  }
}
.about_film_list {
  .about_film_row {
    display: flex;
    gap: 2.4rem;
    padding: 1.6rem 0;
    &:not(:last-child) {
      border-bottom: 1px solid #6B7280;
    }
    > .dt1 {
      width: 7.7rem;
      height: 11.4rem;
      flex: none;
      border-radius: .9rem;
      overflow: hidden;
      &.not_img {
        background: url(../assets/img/not_img.svg) no-repeat center center;
        background-size: 60% auto;
        border: 1px solid #ffffff;
        position: relative;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    > .dt2 {
      width: 32%;
      flex: none
    }
    .div_tit {
      text-transform: uppercase;
    }
    .div_text {
      color: #6B7280;
    }
    .list_time {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
    }
    .item {
      padding: 12px 18px;
      border-radius: 30px;
      border: 1px solid #2E3138;
      cursor: pointer;
      &:hover {
        border-color: #ED850A;
      }
      &.active {
        border-color: #ED850A;
        background-color: #ED850A;
      }
    }
    @media (max-width: 991px) {
      display: grid;
      grid-template-areas: "logo text text" "logo btn btn";
      grid-template-columns: auto 1fr;
      > .dt1 {
        grid-area: logo;
      }
      > .dt2 {
        grid-area: text;
        width: 100%;
      }
      > .dt3 {
        grid-area: btn;
      }
    }
    @media (max-width: 767px) {
      grid-template-areas: "logo text" "btn btn";
    }
  }
}
.not_active_seance {
  pointer-events: none;
  background-color: #2E3138;
  border: none;
  color: #000106;
}
.not_active_seance2 {
  pointer-events: none;
}
</style>