<template>
  <cl-modal
            v-if="dataGallery && dataGallery.length > 0"
            class="modal-gal"
            :class="{'modal-gal-thumbs': thumbsGal}"
            :modelValue="modelValue"
            @update:modelValue="modelValue = $event"
  >
    <swiper
        class="slide100h modal-gal-slide arrow_center"
        :modules="modules"
        :spaceBetween="32"
        :slides-per-view="1"
        :initial-slide="initialSlide"
        :navigation="true"
        :loop="true"
        :lazy="true"
        :thumbs="thumbsGal && !thumbsSwiper?.destroyed ? { swiper: thumbsSwiper } : false"
    >
      <swiper-slide v-for="galItem in dataGallery"
                    :key="galItem">
        <img :src="typeof galItem == 'string' ? galItem : getImage(galItem, 'reference')" loading="lazy" alt="">
      </swiper-slide>
    </swiper>

    <swiper
        v-if="thumbsGal"
        @swiper="setThumbsSwiper"
        :spaceBetween="12"
        :slidesPerView="'auto'"
        :freeMode="true"
        :watchSlidesProgress="true"
        :threshold='10'
        :modules="modules"
        class="sliderThumbsThumbs"
        :initial-slide="initialSlide"
    >
      <swiper-slide v-for="galItem in dataGallery"
                    :key="galItem">
        <div class="div_thumbs">
          <img :src="typeof galItem == 'string' ? galItem : getImage(galItem, 'reference')" loading="lazy" alt="">
        </div>
      </swiper-slide>
    </swiper>

  </cl-modal>
</template>

<script>
/*
* dataGallery - массив фоток
* initialSlide -  каккого элемента начать
* thumbsGal - показывать галерею из мелких картинок
* */
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Thumbs} from "swiper";
export default {
  name: "modalGallery",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    dataGallery: {
      type: Array,
      require: true
    },
    initialSlide: {
      type: Number,
      default: 0,
    },
    thumbsGal: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return {
      thumbsSwiper: null,
    }
  },
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
  },
  setup() {
    return {
      modules: [Navigation, Thumbs],
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-gal-slide {
  &.arrow_center {
    padding: 0 24px;
    margin: 0 -24px;
    :deep(.swiper-button-next) {
      margin-right: 0;
      transform: translate(0, -50%);
    }
    :deep(.swiper-button-prev) {
      margin-left: 0;
      transform: translate(0, -50%);
    }
  }
  :deep(.swiper-slide){
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 100%;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
  @media (max-width: 767px) {
    height: 90vh;
  }
}
.modal-gal-thumbs {
  .modal-gal-slide {
    height: calc(90vh - 144px - 12px);
  }
  .sliderThumbsThumbs {
    flex: none;
    margin-top: auto;

    .swiper-slide {
      height: auto;
    }
  }
  .div_thumbs {
    height: 144px;
    width: 183px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 767px) {
    .sliderThumbsThumbs {
      width: 100%;
    }
    .div_thumbs {
      height: 94px;
      width: 120px;
    }
  }

}
</style>