<template>
  <div class="block_arenda_map" :class="{'notMenu': !visibleMenu}">
    <div class="top_arenda_map">
      <div class="container" v-if="currentTab == 'rentList'">
        <div class="top_stats">
          <div class="find_obj">
            Найдено обьектов:&nbsp&nbsp<span class="color-or">{{ this.countMapData }}</span>
          </div>
          <div class="btn_toggle_tab">
            <cl-button type="filter btn-ico btn-light" @click="toggleTabs">
              <template v-if="!toggleBtn">
                <img src="/img/ico/ico24_map_toggle_btn.svg">
                Показать списком
              </template>
              <template v-else>
                <img src="/img/ico/ico24_map.svg">
                Показать на карте
              </template>
            </cl-button>
          </div>
        </div>
      </div>
    </div>
    <div class="block_wrap block_map" :class="{ active: !toggleBtn}">
      <Map :mapData="mapGeoObjects"
           :mapReload="mapReload"
           v-if="mapGeoObjects?.records?.length > 0"
      >
        <container>
          <h2 v-if="dataInfo.title">{{ dataInfo.title?.value }}</h2>
          <div class="pos_rel">
            <div class="open_is_mob" @click="mobOpen = !mobOpen" :class="mobOpen ? 'active' : ''"></div>
          </div>
        </container>
      </Map>
    </div>

    <arenda-kinopomecheniy-top-filter v-if="visibleMenu" @searchFun="searchFun" :deleteParamsStatus="deleteParamsStatus"/>

    <div class="block_content" :class="{ active: toggleBtn}">

      <container v-if="currentTab == 'rentList'">
        <div class="rent_list">
          <loader
              v-if="isLoading"
              :animation-duration="3000"
              :size="60"
          ></loader>
          <not-info
              v-if="!isLoading && dataList?.length == 0"
              :title="!Object.keys(getParams).length ? `Здесь пока ничего нет` : `Ничего не нашлось`"
              :text="!Object.keys(getParams).length ? `Нет результатов` : `Нет результатов, удовлетворяющих критериям поиска. Попробуйте сбросить фильтры`"
          >
            <template v-slot:btn v-if="Object.keys(getParams).length">
              <div class="div_btn">
                <cl-button @click="deleteParamsStatus = true;">Сбросить фильтры</cl-button>
              </div>
            </template>
          </not-info>
          <row v-if="!isLoading && dataList?.length > 0">
            <template :key='item.guid' v-for='(item, index) in this.dataList'>
              <column md="6" lg="4" xl="3">
                <card-rent2-pavilony :item="item" :routeLink="'/arenda_kinopomecheniy/pavilony/item/' + item.guid"/>
              </column>
              <column md="12" v-if="index == 7 || (dataList.length < 7 && index + 1 == dataList.length)">
                <div class="not_location" v-if="measureGuid">
                  <h4 class="div_tit">Не нашли требуемую локацию для съемок?</h4>
                  <div class="div_text">Подайте заявку в Кинокомиссию Москвы и получите доступ к новым объектам</div>
                  <div class="div_btn">
                    <cl-button v-if="getIsAuthorization && (!(measureGuid == 'ea91d047b20f4dcf9655aede4e1e5808' || measureGuid == '6f0b7e20239342a28def73088d508dfd' || measureGuid == 'a3a7da300a1e4de19909cd30cdb04cdb') || this.getCompany)" @click="formShow = !formShow">Подать заявку</cl-button>
                    <Popper
                        v-else-if="getIsAuthorization"
                        class="light"
                        arrow
                        hover
                    >
                      <cl-button class="btn-disabled">Подать заявку</cl-button>
                      <template #content>
                        Заявка доступна только юридическим лицам
                      </template>
                    </Popper>
                    <cl-button v-else :link="true" href="/connect/mosru">Подать заявку</cl-button>
                  </div>
                  <cl-modal v-model="formShow" class="modal_form" :closeFonActive="false" v-bind="modalAlertMeasureLeaveTopModalAttr(formShowParams)">
                    <h4 class="modal-header" v-if="formTitle">
                      {{ formTitle }}
                    </h4>
                    <measure-form-component
                        v-if="formShowParams"
                        :measureGuid="measureGuid"
                        :showMode="showMode"
                        :formParams="measureFormParams"
                        :form-type="1"
                        @formTitle="formTitleFun"

                        v-bind="modalAlertMeasureLeaveAttr()"
                        v-on="modalAlertMeasureLeaveEmits()"
                    />
                  </cl-modal>
                </div>
              </column>
            </template>
          </row>
          <div v-if="(data?.page < data?.pages || isLoadingMore) && !isLoading" class="show_more">
            <loader
                v-if="isLoadingMore"
                :animation-duration="1500"
                :size="55"
                :color="'#ED850A'"
            ></loader>
            <cl-button v-else @click="loadMore" type="light sm-w-100">Показать еще</cl-button>
          </div>
        </div>
      </container>
    </div>
  </div>
</template>

<script>
import Map from "@/components/map.vue";
import rentList from "@/components/rentList.vue";
import CardRent2Pavilony from "@/components/cardRent2Pavilony.vue";
import MeasureFormComponent from "@/components/measureForm.vue";
import ArendaKinopomecheniyTopFilter from "@/components/arendaKinopomecheniyTopFilter.vue";
import {mapGetters} from "vuex";
import mixinModalAlertMeasureLeave from "@/mixin/mixinModalAlertMeasureLeave";
import ClModal from "@/components/library/ClModal.vue";

export default {
  name: "mapArendaList",
  components: {ClModal, ArendaKinopomecheniyTopFilter, MeasureFormComponent, CardRent2Pavilony, Map, rentList},
  mixins: [mixinModalAlertMeasureLeave],
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      getParams: {},
      quantity: false,
      mapGeoObjects: null,
      visibleMenu: true,
      /**
       * Состояние загрузки карточек
       * @type {boolean}
       * @default false
       */
      isLoading: false,
      /**
       * Состояние загрузки дополнительных карточек
       * @type {boolean}
       * @default false
       */
      isLoadingMore: false,
      dataList: false,
      mapReload: true,
      controller: null,
      activeCategoryList: [],
      mobOpen: false,
      currentTab: "rentList",
      currentTabShow: false,
      toggleBtn: true,
      countMapData: 0,
      dataDots: {},

      formTitle: "Заявка на новые локации",
      formShow: false,
      formShowParams: true,
      showMode: 'inline',
      measureFormParams: {
        preSetParams: {},
        //'appendCss': '/override/modal_wh.css',
      },
      measureGuid: this.dataInfo.formNew?.value,
    }
  },
  methods: {
    // selectComponent(){
    //   if(this.$route.query?.guid) {
    //     this.currentTab =  "rentItem";
    //     this.visibleMenu = false;
    //   } else {
    //     this.currentTab =  "rentList";
    //     this.visibleMenu = true;
    //   }
    // },
    formTitleFun(name) {
      this.formTitle = name;
    },
    searchFun(params){
      this.getParams = params;
      // console.log(params);
      // this.selectComponent();
      this.$nextTick(() => {
        if(this.toggleBtn) this.getRentItem();
        else this.getMapItem();
      });
      this.deleteParamsStatus = false;
      //delete this.getParams?.checkbox;
      delete this.getParams;
      if (Object.keys(params).length > 0) {
        history.pushState({}, "", "?" + new URLSearchParams(this.getParams));
      }
    },
    getRentItem(isLoadMore) {
      if (!isLoadMore) {
        delete this.getParams?.page;
        this.isLoading = true;
      }
      return this.axios
          .get(`/api/data/all-platforms`, {params: this.getParams, signal: this.controller.signal})
          .then(response => {
            if (isLoadMore) {
              this.dataList = [...this.dataList, ...response.data.records];
            } else {
              this.dataList = response.data.records;
            }
            this.countMapData = response.data.total;
            this.data = response.data;
          })
          .finally(() => {
            this.isLoading = false;
          })
          .catch(error => {
            this.showError(error);
          });
    },

    getMapItem() {
      this.mapReload = true;
      return this.axios
          .get(`/api/data/pins-on-map`, {params: this.getParams, signal: this.controller.signal})
          .then(response => {
            this.countMapData = response.data.total;

            this.mapGeoObjects = {};
            this.mapGeoObjects.records = response.data.records
            this.mapGeoObjects.records.filter(item => {
              return item.lat && item.lon
            });
          })
          .finally(() => {
            this.mapReload = false;
          })
          .catch(error => {
            this.showError(error);
          });
    },

    loadMore() {
      this.isLoadingMore = true;
      this.getParams.page = ++this.data.page;
      this.$nextTick(() => {
        this.getRentItem(true).then(() => {
          this.isLoadingMore = false
        })
      });
    },

    // Переключение табов
    toggleTabs() {
      this.toggleBtn = !this.toggleBtn;
      if (this.toggleBtn){
        document.body.classList.remove('visible_rent_blocks');
        this.getRentItem();
      } else {
        document.body.classList.add('visible_rent_blocks');
        this.getMapItem();
      }
    },

    //возврашаем заголовок формы
    /* formTitleFun(name) {
       this.formTitle = name;
     },*/
  },
  created() {
    this.controller = new AbortController();
  },
  computed: {
    ...mapGetters([
      'getIsAuthorization',
      'getCompany',
      'widthSite',
      'getUser',
    ])
  },
  mounted() {
    if (Object.keys(this.$route.query).length == 0) {
      this.getParams.types = undefined;
      history.pushState({}, "", "?" + new URLSearchParams(this.getParams))
    }

    // this.getRentItem(); - не нужно, запрос выполнится при инициализации фильтров
    // this.selectComponent();
    const element = document.querySelector(".block_wrap.block_wh");
    var ts;
    var tsX
    if(element){
      element.addEventListener('touchstart', (e) => {
        ts = e.touches[0].clientY;
        tsX = e.touches[0].clientX;
      });

      element.addEventListener('touchend', (e) => {
        var te = e.changedTouches[0].clientY;
        var teX = e.changedTouches[0].clientX;

        if((tsX > teX-40) && (Math.abs(teX - tsX) > Math.abs(te - ts))) {
          if(this.mobOpen){
            this.mobOpen = false;
          }
        }
      });
    }
  },
  beforeUnmount() {
    // отмена запросов
    this.controller.abort();
    this.controller = null;
    document.body.classList.remove('visible_rent_blocks')
  },
  watch: {
    $route() {
      // this.currentTabShow = false;
      // this.selectComponent();
    },
  },
}
</script>

<style lang="scss">
.block_wh {
  padding: 70px 0;
}

.rent_list{
  .show_more{
    margin-bottom: 20px;
  }
}

.block_wrap + .block_arenda_map{
  padding-top: 70px;
}

.top_arenda_map{
  padding-bottom: 24px;
  padding-top: 8px;
  .top_stats{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .find_obj{
      font-size: 24px;
      font-weight: 500;
    }
    .btn{
      min-height: 48px;
      padding: 12px 24px;
      gap: 0;
      img{
        padding-right: 12px;
      }
    }
  }
  @media (max-width: 767px) {
    .top_stats{
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      .btn_toggle_tab{
        width: 100%;
      }
      .btn{
        min-height: unset;
        height: 48px;
        font-size: 18px;
        width: 100%;
        justify-content: center;
      }
    }
  }
}

.site_body.pavilony-page{
  .breadcrumbs.breadcrumbs_abs{
    top: 204px;
  }
  .block_arenda_map{
    padding-top: 216px;
  }
  .breadcrumbs.breadc_rent_item{
    top: 120px;
  }
  @media(max-width: 991px){
    .breadcrumbs.breadc_rent_item{
      top: 65px;
    }
  }
  @media(max-width: 767px){
    .breadcrumbs.breadc_rent_item{
      display: none;
    }
    .breadcrumbs{
      display: block;
    }
    .block_arenda_map{
      padding-top: 255px;
    }
    .breadcrumbs.breadcrumbs_abs{
      top: 215px;
    }
  }
}

.site_body_rent .block_wrap, .site_body_rent .block_wrap.big_tabs {
  display: none;
}

.site_body.button_kinomiks{
  .breadcrumbs.breadcrumbs_abs{
    top: 262px;
  }
  .breadcrumbs.breadc_rent_item{
    top: 160px;
  }
  .block_arenda_map{
    padding-top: 274px;
  }
  .rent_item{
    padding-top: 190px;
  }
  @media(max-width: 991px){
    .rent_item {
      padding-top: 130px;
    }
    .breadcrumbs.breadc_rent_item{
      top: 100px;
    }
  }
  @media(max-width: 767px){
    .block_arenda_map{
      padding-top: 298px;
    }
    .breadcrumbs.breadcrumbs_abs{
      top: 255px;
    }
  }
}

@media(max-width: 767px){
  .block_arenda_map{
    .open_is_mob{
      display: none;
    }
    .map_list{
      padding: 16px 0 0 0;
      .ps{
        padding: 0 16px 24px 16px;
        margin-top: 24px;
      }
    }
    .close_list{
      top: 30px;
      right: 12px;
    }
    .ps--active-y{
      width: 100%;
      .card-rent{
        max-width: 700px;
      }
    }
    .ps__rail-y{
      display: none !important;
    }
  }
}

.site_body_rent.visible_rent_blocks{
  .block_wrap, .block_wrap.big_tabs{
    display: block;
  }
  .block_wrap.rent_top{
    padding-top: 40px;
  }
}

.open_is_mob{
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #ECECEC;
  z-index: 1;
  &:after{
    content: '';
    width: 15px;
    height: 15px;
    border-right: 3px solid #000106;
    border-top: 3px solid #000106;
    transform: rotate(45deg);
    margin-right: 20%;
    transition: all 0.2s ease;
  }
  &.active{
    &:after{
      transform: rotate(225deg);
      border-color: #ED850A;
      margin-right: -20%;
    }
  }
}

@media (max-width: 991px) {
  .open_is_mob{
    display: flex;
  }
  .map_info_left{
    width: auto;
    min-width: 160px;
    transition: all 0.2s ease;
    top: 10vh;
    left: -100%;
    &.active{
      left: 0;
    }
    .fs2{
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 133.333%;
      letter-spacing: 0.24px;
    }
    .div_text{
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 133.333%;
      letter-spacing: 0.12px;
    }
  }
}
.block_content, .block_map{
  display: none !important;
  &.active{
    display: block !important;
  }
  .rent_item{
    padding-top: 0;
    padding-bottom: 100px;
  }
}
.block_arenda_map .block_content .block_top {
  display: none;
}
.fon1{
  background: unset;
}
</style>