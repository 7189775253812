<template>
  <div class="breadcrumbs" :class="type == 'breadcrumbs' ? 'breadcrumbs_abs' : type " v-if="dataInfo.listBreadcrumbs && dataInfo.listBreadcrumbs.length > 0">
    <container>
      <template
          v-for="(item, index) in dataInfo.listBreadcrumbs"
          :key="item.text?.value"
      >
        <router-link
            v-if="item.link?.value"
            class="breadcrumbs_a"
            :to="item.link?.value"
        >
          {{ item.text?.value }}<span class="sp_line" v-if="index + 1 < dataInfo.listBreadcrumbs.length">/</span>
        </router-link>
      </template>
      <span id="breadcrumbs_last" class="d-none">
        <span class="sp_line">/</span>
        <span class="breadcrumbs_link"></span>
      </span>
    </container>
  </div>
</template>

<script>

export default {
  name: "Breadcrumbs",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
    type: {
      type: String
    },
  },
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 114.286%;
  letter-spacing: 0.21px;
  .sp_line {
    margin: 0 5px;
  }
  .container {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    a:last-child{
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.breadcrumbs_abs {
    position: absolute;
    top: 110px;
    z-index: 1;
    right: 0;
    left: 0;
    @media (max-width: 991px) {
      top: 64px;
    }
  }
  &.breadcrumbs1 {
    position: absolute;
    top: 264px;
    z-index: 1;
    right: 0;
    left: 0;
    @media (max-width: 991px) {
      top: 282px;
    }
  }
  &.breadcrumbs2 {
    position: absolute;
    top: 203px;
    z-index: 1;
    right: 0;
    left: 0;
    @media (max-width: 991px) {
      top: 215px;
    }
  }
  &.breadcrumbs3 {
    position: absolute;
    top: 208px;
    z-index: 1;
    right: 0;
    left: 0;
    @media (max-width: 991px) {
      top: 165px;
    }
  }
  &.breadcrumbs4 {
    position: absolute;
    top: 107px;
    z-index: 1;
    right: 0;
    left: 0;
    //.container {
    //  max-width: 108rem;
    //  padding: 0;
    //}
    //@media (max-width: 1440px) {
    //  .container {
    //    max-width: 100%;
    //    padding: 0 48px;
    //  }
    //}
    @media (max-width: 991px) {
      top: 10rem;
      /*.container {
        padding: 0 32px;
      }*/
    }
  }
  &.breadcrumbs5 {
    right: 0;
    left: 0;
    /*.container {
      max-width: 108rem;
      padding: 0;
    }*/
    @media (max-width: 991px) {
      top: 130px;
      /*.container {
        padding-right: 24px;
        padding-left: 24px;
      }*/
    }
  }
  &.breadcrumbs6 {
    right: 0;
    left: 0;
    @media (max-width: 991px) {
      top: 220px;
    }
  }

}
@media (max-width: 767px) {
  .breadcrumbs{
    display: none !important;
  }
}
</style>