<template>
  <div class="block_wrap big_first_block no_main_block" :style="[ getBkgrImage([dataInfo.image, dataInfo.image768, dataInfo.image375], 'fullHd') ]">
    <container>
      <h1 class="h1_big h1_banner" v-if="dataInfo.title" v-html="dataInfo.title.value"></h1>
      <div class="div_sub fs4n fs40" v-if="dataInfo.text" v-html="dataInfo.text.value"></div>
      <div class="div_button" v-if="dataInfo.buttonText && dataInfo.buttonLink">
        <cl-button link :href="dataInfo.buttonLink.value">{{dataInfo.buttonText.value}}</cl-button>
      </div>
      <row v-if="dataInfo.listCard && dataInfo.listCard.length > 0" class="nowrap_col_lg">
        <column v-for="(item, index) of dataInfo.listCard"
                :key="index"
                :md="6"
                class="col-lg-auto col-end-full"
        >
          <div class="div_num fs2">{{ item.title?.value }}</div>
          <div class="div_text fs4n" v-if="item.text" v-html="item.text?.value"></div>
        </column>
      </row>
    </container>
  </div>
</template>

<script>


export default {
  name: "bannerTitleFirst",
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  }
}
</script>

<style lang="scss" scoped>
.big_first_block {
  padding: 150px 0 50px 0;
  .container {
    max-height: none !important;
  }

  h1 {
    margin-bottom: 48px;
  }
  .h1_banner{
    text-transform: none;
    @media (max-width: 991px){
      margin-top: 0;
    }
  }
  .div_button {
    margin-bottom: 32px;
  }
  @media (max-width: 991px) {
    background-size: 100% auto;
    background-position: top center;
  }
  @media (max-width: 991px) and (min-width: 768px) {
    .col-end-full:last-child:nth-child(2n+1){
      flex: 0 0 100%;
      max-width: 100%;
      border-top: none;
    }
  }
}
</style>
