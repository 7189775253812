<template>
  <template>
    <teleport v-if="widthSite > 400" to="#site_header_td3">
      <div v-if="dataInfo && dataInfo.isBtn && dataInfo.btnTextHide && dataInfo.btnTextShow && particles" class="mouse_track_btn">
        <cl-button class="btn-middle" :class="{'btn-light': !toggleButton}" @click="toggleFunc">{{toggleButton ? dataInfo.btnTextHide.value : dataInfo.btnTextShow.value}}</cl-button>
      </div>
    </teleport>
    <teleport v-else to="#site_header_container">
      <div class="mouse_track">
        <container>
          <div v-if="dataInfo && dataInfo.isBtn && dataInfo.btnTextHide && dataInfo.btnTextShow && particles" class="mouse_track_btn">
            <cl-button class="btn-middle" :class="{'btn-light': !toggleButton}" @click="toggleFunc">{{toggleButton ? dataInfo.btnTextHide.value : dataInfo.btnTextShow.value}}</cl-button>
          </div>
        </container>
      </div>
    </teleport>
  </template>
</template>

<script setup lang="ts">
import {computed, ComputedRef, onBeforeUnmount, onMounted, Ref, ref} from "vue";
import ClButton from "@/components/library/ClButton.vue";
import {IDataInfoProps} from "@/interface/props/IDataInfoProps";
import Container from "@/components/library/Container.vue";
import store from "@/store";

/**
 * Пропы
 */
const props = defineProps<IDataInfoProps>();

/**
 * Состояние включенного/выключенного следа курсора
 */
let toggleButton: Ref<boolean> = ref(false);

/**
 * Список партиклов (картинок)
 */
let particles: Ref<Array<string>> = ref([]);

/**
 * Число показывающее с каким шансом будет пояляться партикл (в долях)
 */
let randNum: Ref<number> = ref(-1);

/**
 * Ширина экрана
 */
const widthSite: ComputedRef<any> = computed(() => {return store.getters.widthSite});

/**
 * Функция при переключении
 */
function toggleFunc(): void {
  if(toggleButton.value) {
    document.removeEventListener("mousemove", animateTrackDesktop);
    document.removeEventListener("touchstart", animateTrackMobile);
    document.removeEventListener("touchmove", animateTrackMobile);
    document.removeEventListener("touchend", animateTrackMobile);
  } else {
    document.addEventListener("mousemove", animateTrackDesktop);
    document.addEventListener("touchstart", animateTrackMobile);
    document.addEventListener("touchmove", animateTrackMobile);
    document.addEventListener("touchend", animateTrackMobile);
  }
  toggleButton.value = !toggleButton.value;
}

/**
 * Функция добавление следу прикосновению
 * @param {MouseEvent} event - событие при движении пальца/стилуса
 */
function animateTrackMobile (event: TouchEvent): void {
  if(event.touches.length > 0) createParticle(event.touches[0].clientX, event.touches[0].clientY);
}

/**
 * Функция добавление следу курсору
 * @param {MouseEvent} event - событие при движении мыши
 */
function animateTrackDesktop (event: MouseEvent): void {
  createParticle(event.clientX, event.clientY);
}

function createParticle(cordX: number, cordY: number) {
  setTimeout((): void => {
    // Шанс появления звездочки
    if(Math.random() <= randNum.value) {
      // Создать партикл
      let particleEl = document.createElement("div");

      // Задать основной класс
      particleEl.classList.add("mouse_track_el");

      // Задать случайный бэкграунд
      particleEl.style.backgroundImage = "url('" + particles.value[Math.floor(Math.random() * particles.value.length)] + "')";

      // Задать начальное положение партикла относительно себя
      // transform: translate(от -75% до -25%, от -75% до -25%) rotate(от -0.5turn до 0.5turn)
      particleEl.style.transform = "translate(" + (Math.random() * 50 - 75) + "%, " + (Math.random() * 50 - 75) + "%) rotate(" + (Math.random() - 0.5) + "turn)"

      // Задать начальные размеры партикла: от 20px до 100px
      particleEl.style.width = particleEl.style.height = Math.floor(Math.random() * 80 + 20) + "px";

      // Задать начальное положение партикла относительно страницы
      particleEl.style.left = cordX + 'px';
      particleEl.style.top = cordY + 'px';

      // Добавить партикл на страницу
      document.body.appendChild(particleEl);

      // Добавить анимацию движения. Длительность анимации от 500ms до 1500ms
      particleEl.style.transition = "all " + (Math.random() * 1000 + 500) + "ms ease-in";

      // Сдвинуть партикл на 200px вниз
      particleEl.style.top = particleEl.offsetTop + 200 + 'px';

      // Сдвинуть партикл в сторону от -100px до 100px
      particleEl.style.left = particleEl.offsetLeft + Math.floor(Math.random() * 200 - 100) + 'px';

      // Повернуть партикл на угол от -0.75turn до 0.75turn
      particleEl.style.transform = "rotate(" + (Math.random() * 1.5 - 0.75) + "turn)";

      // Уменьшить партикл до 20px
      particleEl.style.width = particleEl.style.height = "20px";

      // Скрыть партикл
      particleEl.style.opacity = "0";

      // Удалить партикл после выполненной анимации.
      setTimeout((): void => {
        particleEl.remove();
      }, 1500);
    }
  }, 10);
}

onMounted((): void => {
  // Взять из админки партиклы
  if(!(props.dataInfo?.particles?.length > 0)) return;
  particles.value = props.dataInfo.particles.map(el => new Image().src = el.particle?.value.src?.reference?.url ? el.particle.value.src.reference.url : '');

  // Взять из админки заданный шанс появляения партиклов
  if(props.dataInfo?.randNum?.value && !isNaN(Number(props.dataInfo.randNum.value)) && Number(props.dataInfo.randNum.value) <= 1 && Number(props.dataInfo.randNum.value) > 0) {
    randNum.value = props.dataInfo.randNum.value;
  }

  // Взять из админки дефолтное значения влючения
  if(!props.dataInfo?.defaultOn) return;
  document.addEventListener("mousemove", animateTrackDesktop);
  document.addEventListener("touchstart", animateTrackMobile);
  document.addEventListener("touchmove", animateTrackMobile);
  document.addEventListener("touchend", animateTrackMobile);
  toggleButton.value = true;
});

onBeforeUnmount((): void => {
  document.removeEventListener("mousemove", animateTrackDesktop);
  document.removeEventListener("touchstart", animateTrackMobile);
  document.removeEventListener("touchmove", animateTrackMobile);
  document.removeEventListener("touchend", animateTrackMobile);
})
</script>

<style lang="scss">
.mouse_track_el {
  transform: translate(-50%, -50%) rotate(0.5turn);
  position: fixed;
  z-index: 99999;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}

.mouse_track_btn {
  display: flex;
  justify-content: end;

  .btn {
    font-size: 18px;
    width: 120px;
  }

  @media (max-width: 991px) {
    .btn {
      font-size: 16px;
      width: 60px;
      padding: 0 5px;
    }
  }
  
  @media (max-width: 400px) {
    .btn {
      width: 100%;
    }
  }
}
</style>