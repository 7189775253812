<template>
</template>

<script setup lang="ts">
import {IDataInfoProps} from "@/interface/props/IDataInfoProps";
import {onBeforeUnmount, onMounted} from "vue";

/**
 * Пропы
 */
const props = defineProps<IDataInfoProps>();

/**
 * Функция добавления классу body
 */
function addBodyClass(): void {
  if(props.dataInfo.bodyClass) {
    let bodyEl: HTMLElement | null = document.querySelector('body');
    if(bodyEl) bodyEl.classList.add(props.dataInfo.bodyClass.value);
  }
}

/**
 * Функция удаления классу body
 */
function removeBodyClass(): void {
  if(props.dataInfo.bodyClass) {
    let bodyEl: HTMLElement | null = document.querySelector('body');
    if(bodyEl) bodyEl.classList.remove(props.dataInfo.bodyClass.value);
  }
}

onMounted((): void => {
  addBodyClass();
})

onBeforeUnmount((): void => {
  removeBodyClass();
})
</script>

<style lang="scss">
.winter_tale {
  #app {
    > div {
      &:not(.sub_block_black_wrapper_m) {
        padding: 3rem 0;
      }

      &.sub_block_black_wrapper_m {
        margin-top: 3rem;
        margin-bottom: 3rem;
      }

      &.block-zima-wrapper {
        padding: 0 0 3rem;
      }

      &.img_map {
        padding: calc(3rem + 5rem) 0;
      }

      &.events-top-one {
        margin-bottom: 0;
      }
    }

    > footer {
      padding-top: 3rem;
    }
  }
}
</style>