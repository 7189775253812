<template>
  <div :class="type">
    <loader
        v-if="isLoading"
        :animation-duration="3000"
        :size="40"
        :color="'#ED850A'"
    ></loader>
    <template v-else-if="type == 'headerPopUp'">
      <template v-if="getIsCompany">
        <div class="div_tit_dop">Личный кабинет</div>
        <div class="div_a" @click="chooseUser()">
          <div class="dt1" :class="{'dt_circle': userAvatar}">
            <img :src="userAvatar" v-if="userAvatar">
            <img src="/img/ico/ico_user.svg" alt="" v-else>
          </div>
          <div class="dt2">
            {{ getUser.lastName }}
            {{ getUser.firstName ? getUser.firstName[0] + '.' : '' }}
            {{ getUser.middleName ? getUser.middleName[0] + '.' : '' }}
          </div>
        </div>
      </template>
      <div class="div_tit_dop" v-if="type == 'headerPopUp'">Организации</div>
      <template v-if="(company.length > 0 && !getIsCompany) || getIsCompany && company.length > 1">
        <template v-for="item in company">
          <div class="div_a" v-if="getCompany?.id != item.sudirId"
               @click="chooseUser(item)">
            <div class="dt1" :class="{'dt_circle': item.logo}">
              <img :src="`https://rpp.mos.ru/services/companies/${item.sudirId}/logo/big`" alt="" v-if="item.logo">
              <img src="/img/ico/ico_company.svg" alt="" v-else>
            </div>
            <div class="dt2">{{ item.name }}</div>
          </div>
        </template>
      </template>
      <a v-if="type == 'headerPopUp'" @click="customRedirect(!this.modalAlertMeasureLeaveRouteEmit, '/auth/sudir/trustee/append')" class="div_a add">
        <span class="dt1"><img src="/img/ico/icon_exit_kv.svg" alt=""></span>
        <span class="dt2">Добавить компанию</span>
      </a>
    </template>
    <template v-else>
      <div class="userItemSelected" :class="{'is-selected': !getIsCompany}" @click="type != 'profileCompany' ? chooseUser() : ''">
        <div class="header_logo"><img :src="userAvatar" v-if="userAvatar"></div>
        <div class="tit">
          {{ getUser.lastName }}
          {{ getUser.firstName ? getUser.firstName[0] + '.' : '' }}
          {{ getUser.middleName ? getUser.middleName[0] + '.' : '' }}
        </div>
        <div class="td_btn" v-if="type == 'profileCompany'"><cl-button class="btn-middle" @click="chooseUser()">{{ getIsCompany ? 'Выбрать' : 'Продолжить' }}</cl-button></div>
      </div>
      <template v-for="item in company">
        <div class="userItemSelected" :class="{'is-selected': getCompany?.id === item.sudirId}"
             @click="type != 'profileCompany' ? chooseUser(item) : ''">
          <div class="header_logo">
            <img :src="`https://rpp.mos.ru/services/companies/${item.sudirId}/logo/big`" alt="" v-if="item.logo">
          </div>
          <div class="tit">
            {{ item.name }}
          </div>
          <div class="td_btn" v-if="type == 'profileCompany'"><cl-button class="btn-light" @click="chooseUser(item)">{{ getCompany?.id === item.sudirId ? 'Продолжить' : 'Выбрать' }}</cl-button></div>
        </div>
      </template>
    </template>

    <div class="fs4" v-if="type == 'profileCompany' && company.length < 1">Вы можете добавить свою организацию</div>

    <div v-if="type == 'profileCompany'">
      <a @click="customRedirect(!this.modalAlertMeasureLeaveRouteEmit, '/auth/sudir/trustee/append')" class="btn btn-default btn_add">Добавить организацию</a>
    </div>
  </div>

  <teleport to="body">
    <div class="loader_fix" v-if="restartLoader">
      <loader
          :animation-duration="3000"
          :size="60"
      ></loader>
    </div>
  </teleport>



</template>

<script>
/*
* userAvatar картинка логотипа
* type - какой вид испооьзуем
* restart - надо ли обновлять страницу при смене полльзователя
* */
import {mapGetters} from "vuex";
import ClButton from "@/components/library/ClButton.vue";

export default {
  name: "myCompanySelected",
  components: {ClButton},
  props: {
    userAvatar: {
      type: String,
    },
    type: {
      type: String,
      default: 'headerPopUp'
    },
    restart: {
      type: Boolean,
      default: true
    },
    /**
     * Данные события о действиях с модалкой из mixinModalAlertMeasureLeaveRoute.js
     */
    modalAlertMeasureLeaveRouteEmit: {
      type: Object,
    }
  },
  data() {
    return {
      company: [],
      redirectURL: null,
      isLoading: false,
      controller: null,
      restartLoader: false,
      /**
       * Выбранный пользователь (при смене пользователя)
       * @type {object}
       * @default null
       */
      chosenUser: null,
      /**
       * Id запроса на открытие модалки о выходе с меры поддержки на странице
       * @type {number | null}
       * @default null
       */
      modalAlertMeasureLeaveRouteRequestId: null,
    }
  },
  computed: {
    ...mapGetters([
      'getIsAuthorization',
      'getIsCompany',
      'getCompany',
      'getUser',
      'widthSite',
      'getPersonData'
    ]),
  },
  watch: {
    /**
     * Функция отслеживания пропса modalAlertMeasureLeaveRouteEmit (вызывается при изменении modalAlertMeasureLeaveRouteEmit)
     */
    modalAlertMeasureLeaveRouteEmit() {
      if(this.modalAlertMeasureLeaveRouteRequestId && this.modalAlertMeasureLeaveRouteEmit?.requestId === this.modalAlertMeasureLeaveRouteRequestId && this.modalAlertMeasureLeaveRouteEmit.value === 'modalClosedApply') {
        this.changeUser(this.chosenUser);
      }
    }
  },
  methods: {
    getMyCompany() {
      this.isLoading = true;
      this.axios
          .get(`/api/get/user/organizations`, {signal: this.controller.signal})
          .then(response => {
            this.company = response.data.organizations;
            this.redirectURL = response.data.lastPage ?? null;
          })
          .catch(error => {
            this.showError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    /**
     * Функция, которая вызавается при выборе пользователя
     * @param {object} user - данные пользователя
     */
    chooseUser(user) {
      this.chosenUser = user;
      if(this.modalAlertMeasureLeaveRouteEmit) {
        this.modalAlertMeasureLeaveRouteRequestId = Date.now();
        this.$emit("modalAlertMeasureLeaveRouteEmitAnswer", {requestId: this.modalAlertMeasureLeaveRouteRequestId, value: 'showModal', disableRedirect: true});
      } else {
        this.changeUser(user);
      }
    },
    /**
     * Функция смены пользователя
     * @param {object} user - данные пользователя
     */
    changeUser(user) {
      this.isLoading = true;
      let data = new FormData();
      //let getIsCompanyUser = this.getIsCompany;
      if (user) {
        data.append('id', user.sudirId)
      }
      this.restartLoader = true;
      this.axios
          .post('/api/post/user/change', data)
          .then((response) => {
            //рестарт страницы после смены пользователя
            if(this.$route.fullPath.includes('/lk/my-applications/item')){
              window.location.href = '/lk/my-applications';
            } else if((this.$route.fullPath.includes('/lk/trustedPersons') || this.$route.fullPath.includes('/lk/filmProjects')) && !user){
              window.location.href = '/lk';
            } else {
              this.$router.go(0);
            }
            //this.$store.dispatch('fetchUser');
            /*if (this.redirectURL) {
              this.$router.push(this.redirectURL);
            }*/
          })
          .catch((error) => {
            this.showError(error);
          })
          .finally(() => {
            this.isLoading = false;
            //this.restartLoader = false;
          });
    },
    /**
     * Функция, которая делает редирект на другую страницу в зависимости от необходимого типа (с перезагрузкой страницы или нет)
     * @param {boolean} isRedirectReload - состояние, которое показывает необходима ли перезагрузка
     * @param {string} link - ссылка на которую необходимо сделать редирект
     */
    customRedirect(isRedirectReload, link) {
      if(isRedirectReload) {
        window.location.href = link;
      } else {
        this.$router.push(link);
      }
    },
  },
  mounted() {
    if (this.getIsAuthorization) {
      this.getMyCompany();
    }
  },
  created() {
    this.controller = new AbortController();
  },
  beforeUnmount() {
    // отмена запросов
    this.controller.abort();
    this.controller = null;
  },
  emits: ['modalAlertMeasureLeaveRouteEmitAnswer'],
}
</script>

<style lang="scss">
.profileCompany {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .userItemSelected {
    cursor: default;
    padding: 16px;
    border-radius: 6px;
    background: rgba(23, 25, 28, 0.60);
    color: #FFFFFF;
    .header_logo {
      border: none;
      background-color: #ffffff;
      width: 64px;
      height: 64px;
    }
    &:hover {
      color: #FFFFFF;
    }
  }
  &.is-selected {
    display: none;
  }
}
.userItemSelected {
  cursor: pointer;
  color: #000000;
  display: flex;
  white-space: nowrap;
  width: 100%;
  padding: 12px 16px;
  align-items: center;
  transition: .2s linear;
  font-weight: 500;

  &:hover {
    color: rgba(0, 0, 0, .9);
  }

  &.is-selected {
    //background: rgba(237, 133, 10, 0.1);
    display: none;
  }

  &.add {
    background: rgba(237, 133, 10, 0.30);
    font-weight: 400;
  }

  &.exit {
    color: #5D636F;
    font-weight: 400;
  }

  .header_logo {
    border: 1px solid #C7CAD1;
  }

  .div_ico {
    margin-right: 8px;
    flex: none;
    width: 24px;

    img {
      width: 100%;
    }
  }

  .td_btn {
    padding-left: 10px;
    margin-left: auto;
  }

  @media (max-width: 991px) {
    max-width: none;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;

    .header_logo {
      margin-bottom: 16px;
    }

    .tit {
      margin-bottom: 16px;
    }

    .td_btn {
      width: 100%;
      padding-left: 0;

      .btn {
        width: 100%;
      }
    }
  }
}

.btn_add {
  @media (max-width: 767px) {
    width: 100%;
  }
}
</style>