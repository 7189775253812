<template>
  <side-info-with-img v-if='blockComponent == "sideInfoWithImg"' :dataInfo="dataInfo" :id="id"/>
  <famous-people-v2 v-if='blockComponent == "famousPeopleV2"' :dataInfo="dataInfo" :id="id"/>
  <famous-people-v3 v-if='blockComponent == "famousPeopleV3"' :dataInfo="dataInfo" :id="id"/>
  <smi-about-us-v2 v-if='blockComponent == "smiAboutUsV2"' :dataInfo="dataInfo" :id="id" class="smi_black"/>
  <smi-about-us-progress-bar v-if='blockComponent == "smiAboutUsProgressBar"' :dataInfo="dataInfo" :id="id" class="smi_black"/>
  <side-info-with-img-toggle v-if='blockComponent == "sideInfoWithImgToggle"' :dataInfo="dataInfo" :id="id"/>
  <gallery-buttons v-if='blockComponent == "galleryButtons"' :dataInfo="dataInfo" :id="id"/>
  <kinopark-rules v-if='blockComponent == "kinoparkRules"' :dataInfo="dataInfo" :id="id"/>
  <collapse-list v-if="blockComponent == 'questionsWithUnder'" :dataInfo="dataInfo" :id="id" class="questions_with_under sub_block_black sub_block_black_wrapper_m"/>
  <kinopark-map v-if="blockComponent == 'kinoparkMap'" :dataInfo="dataInfo" :id="id"/>
  <footer-quest-without-form v-if="blockComponent == 'footerQuestWithoutForm'" :dataInfo="dataInfo" :id="id"/>
  <footer-quest-without-form v-if="blockComponent == 'footerQuestWithoutFormV2'" :dataInfo="dataInfo" :id="id" class="footer_quest_v2"/>
  <slider-with-services-description v-if="blockComponent == 'sliderWithServicesDescription'" :dataInfo="dataInfo" :id="id"/>
  <cards-services v-if="blockComponent == 'cardsServices'" :dataInfo="dataInfo" :id="id"/>
  <banner-title-first-film-commission v-if="blockComponent == 'bannerTitleFirstFilmCommission'" :dataInfo="dataInfo" :id="id"/>
  <pavilony-list-types v-if="blockComponent == 'pavilonyListTypesV2'" :dataInfo="dataInfo" :id="id" class="pavilony_list_v2" :scroll-pagination="true" :slidesPerViewLg="'3'" :slides-per-view-md="'auto'" :slides-per-view="'auto'" :centered-slides="true" :centered-slides-md="true"/>
  <video-reviews-with-under v-if="blockComponent == 'videoReviewsWithUnder'" :dataInfo="dataInfo" :id="id"/>
  <movie-galleries-progress v-if='blockComponent == "movieGalleriesProgressV2"' :dataInfo="dataInfo" :id="id" class="list_movie_card_v2" :link-type="2"/>
  <documents-and-agreements-with-under v-if='blockComponent == "documentsAndAgreementsWithUnder"' :dataInfo="dataInfo" :id="id"/>
  <work-structure-list v-if='blockComponent == "workStructureList"' :dataInfo="dataInfo" :id="id"/>
  <block-list-main v-if='blockComponent == "blockListMainV2"' :dataInfo="dataInfo" :id="id" class="block-list-main-v2"/>
  <cinema-banners-mos v-if='blockComponent == "cinemaBannersMos"' :data-info="dataInfo" :id="id"/>
  <cinema-swiper-cards-mos v-if='blockComponent == "cinemaSwiperCardsMos"' :dataInfo="dataInfo" :id="id"/>
  <modal-payment v-if="blockComponent == 'modalPaymentFailure'" :dataInfo="dataInfo" :id="id" modal-type="failure"/>
  <modal-payment v-if="blockComponent == 'modalPaymentSuccess'" :dataInfo="dataInfo" :id="id" modal-type="success"/>
  <modal-survey v-if="blockComponent == 'modalSurvey'" :dataInfo="dataInfo" :id="id"/>
  <side-info-with-img-and-icon v-if='blockComponent == "sideInfoWithImgAndIcon"' :dataInfo="dataInfo" :id="id"/>
  <array-side-info-with-img-and-icon v-if='blockComponent == "arraySideInfoWithImgAndIcon"' :dataInfo="dataInfo" :id="id"/>
  <title-text-button v-if='blockComponent == "titleTextButton"' :dataInfo="dataInfo" :id="id"/>
  <img-map v-if='blockComponent == "imgMap"' :dataInfo="dataInfo" :id="id"/>
  <body-class v-if='blockComponent == "bodyClass"' :dataInfo="dataInfo" :id="id"/>
  <mouse-track-stars v-if='blockComponent == "mouseTrackStars"' :dataInfo="dataInfo" :id="id"/>
</template>

<script>
import SideInfoWithImg from "@/components/sideInfoWithImg.vue";
import FamousPeopleV2 from "@/components/famousPeopleV2.vue";
import SmiAboutUsV2 from "@/components/smiAboutUsV2.vue";
import SideInfoWithImgToggle from "@/components/sideInfoWithImgToggle.vue";
import GalleryButtons from "@/components/galleryButtons.vue";
import FamousPeopleV3 from "@/components/famousPeopleV3.vue";
import SmiAboutUsProgressBar from "@/components/smiAboutUsProgressBar.vue";
import KinoparkRules from "@/components/kinoparkRules.vue";
import CollapseList from "@/components/collapseList.vue";
import KinoparkMap from "@/components/kinoparkMap.vue";
import FooterQuestWithoutForm from "@/components/footerQuestWithoutForm.vue";
import SliderWithServicesDescription from "@/components/sliderWithServicesDescription.vue";
import CardsServices from "@/components/cardsServices.vue";
import BannerTitleFirst from "@/components/bannerTitleFirst.vue";
import PavilonyListTypes from "@/components/pavilonyListTypes.vue";
import ListMovieGalleries from "@/components/listMovieGalleries.vue";
import MovieGalleriesProgress from "@/components/movieGalleriesProgress.vue";
import DocumentsAndAgreementsWithUnder from "@/components/documentsAndAgreementsWithUnder.vue";
import VideoReviewsWithUnder from "@/components/videoReviewsWithUnder.vue";
import WorkStructureList from "@/components/workStructureList.vue";
import BlockListMain from "@/components/blockListMain.vue";
import CinemaBannersMos from "@/components/cinemaBannersMos.vue";
import CinemaSwiperCardsMos from "@/components/cinemaSwiperCardsMos.vue";
import ModalCookies from "@/components/modalCookies.vue";
import ModalPayment from "@/components/modalPayment.vue";
import ModalSurvey from "@/components/modalSurvey.vue";
import SideInfoWithImgAndIcon from "@/components/sideInfoWithImgAndIcon.vue";
import ArraySideInfoWithImgAndIcon from "@/components/arraySideInfoWithImgAndIcon.vue";
import TitleTextButton from "@/components/titleTextButton.vue";
import ImgMap from "@/components/imgMap.vue";
import BodyClass from "@/components/bodyClass.vue";
import BannerTitleFirstFilmCommission from "@/components/bannerTitleFirstFilmCommission.vue";
import MouseTrackStars from "@/components/mouseTrackStars.vue";

export default {
  name: "templateDynamic5",
  components: {
    MouseTrackStars,
    BannerTitleFirstFilmCommission,
    BodyClass,
    ImgMap,
    TitleTextButton,
    ArraySideInfoWithImgAndIcon,
    SideInfoWithImgAndIcon,
    ModalSurvey,
    ModalPayment,
    ModalCookies,
    CinemaBannersMos,
    CinemaSwiperCardsMos,
    BlockListMain,
    WorkStructureList,
    VideoReviewsWithUnder,
    DocumentsAndAgreementsWithUnder,
    MovieGalleriesProgress,
    ListMovieGalleries,
    PavilonyListTypes,
    BannerTitleFirst,
    CardsServices,
    SliderWithServicesDescription,
    FooterQuestWithoutForm,
    KinoparkMap,
    CollapseList,
    KinoparkRules,
    SmiAboutUsProgressBar,
    FamousPeopleV3, GalleryButtons, SideInfoWithImgToggle, SmiAboutUsV2, FamousPeopleV2, SideInfoWithImg},
  props: {
    dataInfo: {
      type: Object,
      require: true,
    },
    id: {
      type: String,
    },
    blockComponent: {
      type: String,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>