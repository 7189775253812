<template>
  <row class="row_mb">
    <transition-group name="fade-base2">
      <column
          v-for="event in applications"
          :key="event.id"
      >
        <card-support-measure @click="$router.push(`/lk/my-applications/item/${event.guid}`)" :event="event" :card-options="cardOptions" @openModalMeas="openModalCardSuppMeas" @openModalApplicationDelete="openModalApplicationDelete"/>
      </column>
    </transition-group>
  </row>
  <div v-if="data?.page < data?.pages" class="show_more">
    <loader
        v-if="isLoadingMore"
        :animation-duration="1500"
        :size="55"
    ></loader>
    <cl-button v-else @click="loadMore" type="light sm-w-100">Показать еще</cl-button>
  </div>

  <cl-modal v-model="formShow" class="modal_form" :closeFonActive="false">
    <h3 class="modal-header modal-header-custom" v-if="formTitle">
      {{ formTitle }}
    </h3>
    <MeasureSubRequestFormComponent
        :measureServiceGuid="measureServiceGuid"
        :subRequestGuid="subRequestGuid"
        :formParams="formParams"
        :successText="'Ваш ответ отправлен'"
        @measureBundleSuccessPostSendEvent="closeModalCardSuppMeas"
    />
  </cl-modal>
</template>

<script setup lang="ts">
import cardSupportMeasure from "@/components/CardSupportMeasure.vue";
import MeasureSubRequestFormComponent from "@/components/subrequestForm.vue";
import ClModal from "@/components/library/ClModal.vue";
import ClButton from "@/components/library/ClButton.vue";
import {ref, Ref} from "vue";

/**
 * Пропы
 */
const props = defineProps<{
  applications?: any,
  cardOptions?: any,
  data?: any,
  isLoadingMore?: boolean,
}>();

/**
 * Эмиты
 */
const emit = defineEmits<{
  (e: 'openModalApplicationDeleteEmit', guid?: string): void
  (e: 'loadMoreEmit'): void
}>();

/**
 * Гуид меры сервиса в модалке
 * @type {string | undefined | null}
 * @default null
 */
let measureServiceGuid: Ref<string | undefined | null> = ref(null);

/**
 * Гуид сабреквеста в модалке
 * @type {string | undefined | null}
 * @default null
 */
let subRequestGuid: Ref<string | undefined | null> = ref(null);

/**
 * Заголовок модалки с мерой
 * @type {string | undefined | null}
 * @default null
 */
let formTitle: Ref<string | undefined | null> = ref(null);

/**
 * Состояние, с помощью которого отображается/скрывается модалка с мерой
 * @type {boolean}
 * @default false
 */
let formShow: Ref<boolean> = ref(false);

/**
 * Параметры меры
 * @type {object}
 * @const
 * @default
 * {
 *   'appendCss': '/override/lk_applications.css',
 * }
 */
const formParams: {'appendCss': string} = {'appendCss': '/override/lk_applications.css'};

/**
 * Функция-событие, получет параметры меры, которую необходимо открыть в модалке
 * @param {object} value - параметры меры
 */
function openModalCardSuppMeas(value: {measureServiceGuid?: string, subRequestGuid?: string, formTitle?: string}): void {
  measureServiceGuid.value = value.measureServiceGuid;
  subRequestGuid.value = value.subRequestGuid;
  formTitle.value = value.formTitle;
  formShow.value = true;
}

/**
 * Функция-событие, которое закрывает модалку с мерой
 */
function closeModalCardSuppMeas(): void {
  formShow.value = false;
}

/**
 * Функция открытия модалки с предупреждением об удалении заявки
 * @param {string | undefined} guid - гуид заявки, которую необходимо удалить
 */
function openModalApplicationDelete(guid?: string): void {
  emit("openModalApplicationDeleteEmit", guid);
}

/**
 * Загрузить больше контента
 */
function loadMore(): void {
  emit("loadMoreEmit", true);
}

</script>

<style lang="scss" scoped>

</style>