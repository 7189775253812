<template>
  <div v-if="dataInfo && Object.keys(dataInfo).length > 0" class="title_text_button">
    <container>
      <div class="title_text_button__cont" :style="dataInfo.maxWidth && !isNaN(Number(dataInfo.maxWidth.value)) ? 'max-width: ' + dataInfo.maxWidth.value + 'rem' : ''">
        <div v-if="dataInfo.title || dataInfo.text" class="div_info">
          <h2 v-if="dataInfo.title" class="div_title" v-html="dataInfo.title.value"></h2>
          <div v-if="dataInfo.text" class="div_text" v-html="dataInfo.text.value"></div>
        </div>
        <div v-if="dataInfo.btnText && (dataInfo.btnLink || dataInfo.btnModalLink)" class="div_btn">
          <cl-button v-if="dataInfo.btnLink" link :href="dataInfo.btnLink.value" @click="v_ym(dataInfo.yandexMetrikaTarget?.value)" class="btn-middle">
            {{dataInfo.btnText.value}}
          </cl-button>
          <cl-button v-else-if="dataInfo.btnModalLink" @click="v_ym(dataInfo.yandexMetrikaTarget?.value); showModalIframe(dataInfo.btnModalLink.value);" class="btn-middle">
            {{dataInfo.btnText.value}}
          </cl-button>
        </div>
      </div>
    </container>

    <cl-modal modal-type="iframe-excursions" v-model="isShowModalIframe">
      <loader
          :animation-duration="3000"
          :size="60"
          :color="'#ED850A'"
          v-if="isShowModalIframeLoader"
      ></loader>
      <iframe v-show="!isShowModalIframeLoader" class="iframe-excursions" ref="iframeModal" :src="iframeModalLink"/>
    </cl-modal>
  </div>
</template>

<script setup lang="ts">
import Container from "@/components/library/Container.vue";
import {IDataInfoProps} from "@/interface/props/IDataInfoProps";
import ClButton from "@/components/library/ClButton.vue";
import {nextTick, ref, Ref} from "vue";
import Loader from "@/components/library/Loader.vue";
import ClModal from "@/components/library/ClModal.vue";

/**
 * Пропы
 */
const props = defineProps<IDataInfoProps>();

/**
 * Состояние открытой/закрытой модалки с iframe
 */
let isShowModalIframe: Ref<boolean> = ref(false);

/**
 * Состояние лоадера в модалке
 */
let isShowModalIframeLoader: Ref<boolean> = ref(true);

/**
 * Модалка с iframe
 */
const iframeModal: Ref<HTMLElement | null> = ref(null);

/**
 * Ссылка на виджет для iframe
 */
let iframeModalLink: Ref<string | undefined> = ref(undefined);

/**
 * Функция открытия модалки с iframe
 * @param {string} iframeLink - ссылка на виджет для iframe
 */
function showModalIframe(iframeLink?: string): void {
  iframeModalLink.value = iframeLink;
  isShowModalIframe.value = true;
  nextTick(()=>{
    if(iframeModal.value){
      iframeModal.value.addEventListener('load', () => {
        isShowModalIframeLoader.value = false;
      });
    } else {
      isShowModalIframeLoader.value = false;
    }
  });
}
</script>

<style lang="scss" scoped>
.title_text_button {
  padding: 6rem 0;

  &__cont {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem
  }

  .div_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
  }

  .div_title {
    font-size: 4.8rem;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.02em;
    text-align: center;
    margin-bottom: 0;
    text-transform: unset;
  }

  .div_text {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.02em;
    text-align: center;
    max-width: 68.9rem;
  }

  @media (max-width: 991px) {
    .div_title {
      font-size: 3.2rem;
      line-height: 1.5;
    }

    .div_text {
      font-size: 1.8rem;
      line-height: 1.33;
    }
  }
  
  @media (max-width: 600px){
    &__cont {
      max-width: none !important;
    }
  }

  @media (max-width: 767px) {
    .div_title {
      font-size: 2.4rem;
      line-height: 1.33;
    }
  }
}
</style>