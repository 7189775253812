<template>
  <div class="block_wrap" v-if="dataInfo.ListPartners && dataInfo.ListPartners.length > 0">
    <container>
      <h2 v-if="dataInfo.mainTitle?.value">{{ dataInfo.mainTitle.value }}</h2>
      <div :class="{'row': widthSite >= 1200 || widthSite <= 991}"
                  class="cardPartnerWrap row">
        <template  v-for="(item, index) in dataInfo.ListPartners"
                   :key="item"
        >
          <column class="col-lg-4" md="6">
            <div class="cardPartner" :class="{'cardPartnerSoon': !item?.linkButton, 'cardContactShow': item.contactShow}">
              <div class="div_mainInfo">
                <div class="div_img" v-if="item.logoPrtners">
                  <img :src="getImage(item.logoPrtners, 'fullHd')" alt="">
                </div>
                <div class="div_tit" v-if="item.title?.value">{{ item.title.value }}</div>
                <div class="div_description" v-if="item.description?.value">
                  <div class="text_holder">{{ item.description.value }}</div>
                </div>
              </div>
              <div v-show="item.contactShow" v-if="item.contacts?.length" class="div_contacs" :class="{'div_contacs_center': item.contacts?.length === 1}">
                <perfect-scrollbar :id="'perfectUpdate' + index"
                                   v-if="item.contacts.length > 1"
                >
                  <div class="cont_scroll_el">
                    <template
                        v-for="item of item.contacts"
                              key="item">
                      <cl-collapse class="collapse_schedule" :group-name="'collaps_groupCont' + index">
                        <template v-slot:name>
                          <template v-if="item.infoTitle"><span class="fs4">{{ item.infoTitle.value }}</span></template>
                        </template>
                        <template v-slot:card>
                          <div class="dt" v-if="item.info2">{{ item.info2.value }}</div>
                          <div class="dt" v-if="item.info1">
                            <a :href="'tel:' + item.info1.value">{{ item.info1.value}}</a>
                          </div>
                        </template>
                      </cl-collapse>
                    </template>
                  </div>
                </perfect-scrollbar>
                <template v-else>
                  <div class="div_contacs_tit fs3" v-if="item.title?.value">{{ item.title.value }}</div>
                  <div class="div_contacs_text" v-if="item.contacts[0].info1"><a :href="'tel:' + item.contacts[0].info1.value">{{ item.contacts[0].info1.value }}</a></div>
                  <div class="div_contacs_text" v-if="item.contacts[0].info2">{{ item.contacts[0].info2.value }}</div>
                </template>
              </div>
              <div class="div_btn btn_part_round" v-if="item.textButton?.value || item.textButton2?.value">
                <a v-if="item.linkButton?.value" :href="`${item.linkButton.value}`" target="_blank" class="btn btn-default" :class="{'btn-light': item.textButton?.value && item.textButton2?.value}">{{ item.textButton.value }}</a>
                <span v-else class="btn btn-default" :class="{'btn-light': item.textButton?.value && item.textButton2?.value}">{{ item.textButton.value }}</span>
                <cl-button v-if="item.textButton2?.value"
                           class="btn-light-active" :class="{'btn-light': item.textButton?.value && item.textButton2?.value}"
                           @click="contactShowFun(index)">{{ item.textButton2?.value }}</cl-button>
              </div>
            </div>
          </column>
        </template>
      </div>

<!--      <row>-->
<!--        <column lg="3" v-for="item in dataInfo.ListPartners">-->
<!--          <div class="cardPartner" :class="{'cardPartnerSoon': !item?.linkButton}">-->
<!--            <div class="div_mainInfo">-->
<!--              <div class="div_img" v-if="item.logoPrtners">-->
<!--                <img :src="getImage(item.logoPrtners, 'fullHd')" alt="">-->
<!--              </div>-->
<!--              <div class="div_tit" v-if="item.title?.value">{{ item.title.value }}</div>-->
<!--              <div class="div_description" v-if="item.description?.value">{{ item.description.value }}</div>-->
<!--            </div>-->
<!--            <div class="div_btn btn_part_round" v-if="item.textButton?.value">-->
<!--              <a v-if="item.linkButton?.value" :href="`/arenda_rekvizita/mebel?company=${item.linkButton.value}`" class="btn btn-default">{{ item.textButton.value }}</a>-->
<!--              <span v-else class="btn btn-default">{{ item.textButton.value }}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </column>-->
<!--      </row>-->
    </container>
  </div>
</template>

<script>
import Container from "@/components/library/Container";
import {mapGetters} from "vuex";
import Column from "@/components/library/Column.vue";
import ClButton from "@/components/library/ClButton.vue";
import ClCollapse from "@/components/library/ClCollapse.vue";
import PerfectScrollbar from "perfect-scrollbar";

export default {
  name: "partnersArend",
  components: {
    ClCollapse,
    ClButton,
    Column,
    Container
  },
  data() {
    return {
      contactShowIndex: null,
    }
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ]),
  },
  methods: {
    observerScrollbar(refs){
      const psEl = document.getElementById(refs);
      if(psEl){
        const ps = new PerfectScrollbar(psEl);
        const resizeObserver = new ResizeObserver(() => {
            if(ps) ps.update();
        });
        resizeObserver.observe(psEl.querySelector(".cont_scroll_el"))
      }

    },
    /**
     * показывать скрывать контакты
     * @param index index нубного обьекта
     */
    contactShowFun(index){
      if(this.contactShowIndex != undefined && this.contactShowIndex !== index){
        delete this.dataInfo.ListPartners[this.contactShowIndex].contactShow
      }
      this.contactShowIndex = index;
      this.dataInfo.ListPartners[index].contactShow = !this.dataInfo.ListPartners[index].contactShow;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.dataInfo?.ListPartners.forEach((key, index) => {
        this.observerScrollbar('perfectUpdate' + index);
      });
    });
  }

}
</script>

<style lang="scss" scoped>
.cardPartner{
  transition: .2s ease-in;
  //min-height: 40rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1.2rem;
  border: 1px solid  #6B7280;
  background: #0C0C0E;
  height: 38.4rem;
  .div_mainInfo{
    position: relative;
    transition: .2s ease-in;
    margin-top: 5.4rem;
    margin-bottom: auto;

    .div_img{
      margin: 0 auto;
      width: 20rem;
      height: 20rem;
      > img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .div_tit{
      text-align: center;
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 133.333%;
      letter-spacing: 0.048rem;
      text-transform: uppercase;
      padding: 0 1rem;
      @media (max-width: 1300px) {
        display: none;
      }
    }
    .div_description {
      transition: .2s ease-in;
      font-size: 1.8rem;
      line-height: 133.333%;
      letter-spacing: 0.027rem;
      opacity: 0;
      padding: 0 1rem 1rem 1rem;
      text-align: center;
      height: 0;
      .text_holder {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @media(max-width: 767px){
          margin: 1rem 1rem 0 1rem;
        }
      }
      //@media (max-width: 1300px) {
      //  display: none;
      //}
    }
  }
  .div_btn{
    transition: .2s ease-in;
    opacity: 0;
    min-height: 0;
    width: 100%;
     .btn{
       width: 100%;
       border-radius: 0;
     }
    &.btn_part_round {
      display: flex;
      .btn{
        border-radius: 0;
      }
      .btn-light:hover {
        border: 1px solid #ED870A;
        background: #ED870A;
        color: #ffffff;
        box-shadow: none;
      }
    }

  }
  &.cardContactShow {
    position: relative;
    border-color: #ED870A;
    .div_contacs_tit {
      text-align: center;
      margin-bottom: 2px;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .ps {
      height: 32rem;
      padding: 0;
      &.ps--active-y {
        padding: 0 24px 0 0;
        :deep(.ps__rail-y) {
          right: 5px !important;
        }
      }
    }
    :deep(.collapse_el){
      border-bottom: 1px solid #6B7280;
      &:last-child {
        border: none;
      }
      padding-bottom: 4px;
      .collapse_btn {
        padding-top: 4px;
        justify-content: space-between;
        &:after {
          border-color: #ED870A;
        }
      }
      .collapse_card {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        color: #ED870A;
        @media (max-width: 767px) {
          flex-direction: column;
        }
      }
    }
    .dt a {
      color: #ED850A;
      text-decoration: underline;
    }
    .div_contacs_text {
      color: #ED850A;
      text-align: center;
      a {
        color: #ED850A;
        text-decoration: underline;
      }
    }

    .div_contacs_center {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
    }
    .div_contacs {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 50px;
      background: #0c0c0e;
      padding: 5px 12px 12px 12px;
    }

    .btn-light-active {
      border: 1px solid #ED870A;
      background: #ED870A;
      color: #ffffff;
      box-shadow: none;
    }
  }


  &.cardPartnerSoon,
  &.cardContactShow,
  &:hover{
    transition: .2s ease-in;
    border: 1px solid  #ED850A;
    .div_mainInfo{
      transition: .2s ease-in;
      translate: 0 -5.2rem;
      .div_description{
        transition: .2s ease-in;
        opacity: 1;
      }
    }
    .div_btn{
      transition: .2s ease-in;
      opacity: 1;
      height: auto;
    }
  }
  &.cardPartnerSoon {
    pointer-events: none;
    border: 1px solid #2E3138;
    .div_mainInfo {
      translate: 0 0rem;
    }
    .btn {
      background: #2E3138;
      border-color: #2E3138;
      color: #000106;
    }
    .div_description {
      display: none;
    }
  }
  //@media(max-width: 991px){
  //  min-height: 23.8rem;
  //  .div_mainInfo{
  //    margin-top: 3.2rem;
  //
  //    .div_img{
  //      margin: 0 auto;
  //      width: 10rem;
  //      height: 10rem;
  //    }
  //    .div_tit{
  //      font-size: 1.8rem;
  //      letter-spacing: 0.036rem;
  //    }
  //    .div_description{
  //      height: 0;
  //      padding: 0 1.2rem;
  //      font-size: 1.4rem;
  //      line-height: 142.857%;
  //      letter-spacing: 0.021rem;
  //      text-align: center;
  //    }
  //  }
  //  .div_btn{
  //    .btn{
  //      min-height: 4rem;
  //    }
  //  }
    //&:hover{
    //  .div_mainInfo{
    //    .div_description{
    //      transition: .2s ease-in;
    //      height: 4rem;
    //    }
    //  }
    //  .div_btn{
    //    height: 4rem;
    //  }
    //  .div_mainInfo {
    //    translate: 0;
    //  }
    //}
    //.div_btn {
    //  display: none;
    //}
    //&.cardPartnerSoon {
    //  .div_mainInfo {
    //    translate: 0;
    //  }
    //  .div_btn {
    //    display: block;
    //  }
    //}
    //.div_mainInfo {
    //  flex-grow: 1;
    //  margin: 20px;
    //  .div_img {
    //    width: 100%;
    //    height: 100%;
    //  }
    //}
  //}
  @media(max-width: 767px){
    .div_mainInfo{
  //    margin-top: 4.2rem;
      .div_description{
        font-size: 1.4rem;
      }
    }
  //  &:hover{
  //    .div_mainInfo{
  //      margin-top: calc(0% + 2rem);
  //      .div_description{
  //        height: unset;
  //      }
  //    }
  //  }
  }
}

.cardPartnerWrap {
  :deep(.swiper-slide) {
    width: auto !important;
    @media(min-width: 992px) and (max-width: 1200px){
      > div {
        width: 448px;
        height: 100%;
      }
    }
  }
}
</style>