<template>
  <div class='block_wrap block_wh smi' v-if="dataInfo && dataInfo.listNews && dataInfo.listNews.length > 0">
    <container>
      <h2 v-if="dataInfo.title">{{ dataInfo.title?.value }}</h2>
      <swiper
          :spaceBetween="24"
          :threshold='10'
          :modules="modules"
          :navigation="true"
          :pagination="{
              type: 'fraction',
              formatFractionCurrent: num => { return (num > 9) ? num : '0' + num},
              formatFractionTotal: num => { return (num > 9) ? num : '0' + num}
            }"
          :loop="true"
          class="slide100h arrow_top_pag arrow_top_pag_h"
          :slidesPerView="'auto'"
          :breakpoints="{
            '992': {
              slidesPerView: 3
            },
            '768': {
              slidesPerView: 2
            }
          }"
      >
        <swiper-slide v-for='item in dataInfo.listNews' :key="item">
          <card card-type="smi">
            <div class="div_comp">
              <div v-if="item.logo" class='div_logo'>
                <img :src="getImage(item.logo, 'hd')">
              </div>
              <div v-if="item.name" class="div_name fs4">{{ item.name?.value }}</div>
            </div>
            <div v-if="item.title" class='div_tit fw500' v-html="item.title?.value"></div>
            <div v-if="item.text" class='div_text fs16' v-html="item.text?.value"></div>
            <div class="div_btn"><cl-button v-if="item.link" :link="true" type="light" :href="item.link?.value">Подробнее</cl-button></div>
          </card>
        </swiper-slide>
      </swiper>
    </container>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Pagination} from "swiper";
import {mapGetters} from "vuex";
export default {
  name: 'smiAboutUsV2',
  components: {SwiperSlide, Swiper},
  data() {
    return {}
  },
  props: {
    dataInfo: {
      type: Object,
      require: true
    },
  },
  computed: {
    ...mapGetters([
      'widthSite'
    ]),
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    }
  }
}
</script>

<style lang="scss" scoped>

@import '@/assets/scss/variables.scss';

.smi{
  padding: 6rem 0;

  h2 {
    position: relative;
    z-index: 2;
    margin-bottom: 2.4rem;

    font-family: Playfair Display;
    line-height: 1.16;
    letter-spacing: 0.02em;
    text-transform: unset;
    margin-right: 28rem;

  }

  &.smi_black{
    background-color: #000106;
    color: white;

    :deep(.card-smi){
      background: #000106;
      border-color: rgba(63, 63, 66, 1);
    }
    :deep(.div_text) {
      display: -webkit-box;
      -webkit-line-clamp: 4; // количество строк
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 5em;
    }
    :deep(.btn) {
      width: 100%;
    }
  }

  @media(max-width: 991px){
    h2{
      font-size: 3.2rem;
    }
  }

  @media(max-width: 767px){
    h2{
      font-size: 3.2rem;
      text-align: center;
      margin-right: 0;
    }
  }
}

</style>